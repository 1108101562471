import { map as createMap, Map as LMap, Marker, tileLayer, featureGroup, control, marker, version } from 'leaflet';
import 'leaflet.utm';
import * as L from 'leaflet';

import { html, PropertyValues, TemplateResult } from 'lit';
import { nameofFactory } from '../../helpers/nameof';
import { LitChangeCustomEvent } from '../../helpers/events';
import { BaseLitElement } from '../base-lit-element';
import { blackIcon, leafletIcons } from './leaflet-markers';
import { customElement, property, state } from 'lit/decorators.js';
import styles from './map-style.scss';
import '../info-box/info-box';
import '../icons/icon-pin';

type Dictionary<TypeValue> = {
  [key: string]: TypeValue
}

export type MapCenter = {
  lat: number,
  lan: number,
  zoom: number,
};

export type MapMarker = {
  id?: string,
  x: string,
  y: string,
  zone: string,
  title: string
}

export type MapMarkerLayer = {
  title: string,
  markers?: Array<MapMarker>
}

const nameof = nameofFactory<CustomMap>();

// keep in synk global.d.ts
@customElement('md-map')
export class CustomMap extends BaseLitElement {

  public static styles = styles;

  /** The default zoom when there are valid coordinates. */
  @property({ type: Number, attribute: true, reflect: true })
  defaultZoom = 15;

  /** The current zoom, if changed by the user. */
  @property({ type: Number, attribute: true, reflect: true })
  currentZoom: number | null = null;

  /** The max zoom allowed for the cart. */
  @property({ type: Number, attribute: true, reflect: true })
  maxZoom = 19;

  /** The default position (lat, lan, zoom) if no coordinates are provided. */
  @property({ type: Object, attribute: true, reflect: true })
  defaultCenter: MapCenter = {
    lat: 63.487164718,
    lan: 9.839663308,
    zoom: 5
  };

  @property({ type: String, attribute: true, reflect: true })
  x: string | null = null;

  @property({ type: String, attribute: true, reflect: true })
  y: string | null = null;

  @property({ type: String, attribute: true, reflect: true })
  zone: string | null = null;

  @property({ type: Array, attribute: true, reflect: true })
  markerLayers: MapMarkerLayer[] | null = null;

  @property({ type: Boolean, attribute: true, reflect: true })
  readOnly = false;

  @state()
  private errorMsg: string | null = null;

  @state()
  private _map: LMap | null = null;

  @state()
  private _mapMarker: Marker | null = null;

  @state()
  private _resizeObserver: ResizeObserver | null = null;

  @state()
  private mapClicked = 0;

  @state()
  private mapLayer: L.FeatureGroup | null = null;

  @state()
  private controlLayer: L.Control.Layers | null = null;

  @state()
  private controlGroups: Dictionary<L.FeatureGroup> | null = null;

  private _inManualZoom = false;

  firstUpdated(_changedProperties: PropertyValues): void {
    super.firstUpdated(_changedProperties);
    const mapEl = this.shadowRoot?.querySelector('#mapid') as HTMLElement;
    this._map = createMap(mapEl).setView([this.defaultCenter.lat, this.defaultCenter.lan], this.defaultCenter.zoom);
    this.mapLayer = featureGroup().addTo(this._map);
    this.controlLayer = control.layers(undefined, undefined, { collapsed: false }).addTo(this._map);

    const urlTemplate = 'http://{s}.tile.osm.org/{z}/{x}/{y}.png';

    this._map.addLayer(tileLayer(urlTemplate, {
      maxZoom: this.maxZoom,
    }));

    this._mountClickEventListener(this._map);
    this._mountZoomEventListener();
    this._mountObserver(mapEl);
  }

  updated(_changedProperties: PropertyValues): void {
    super.updated(_changedProperties);

    if (
      !_changedProperties.has(nameof('x')) &&
      !_changedProperties.has(nameof('y')) &&
      !_changedProperties.has(nameof('zone'))
    ) {
      return;
    }

    if (!(this.x && this.y && this.zone && this._map)) {
      this._removeMapMarker();
    } else {
      if (this.currentZoom === null) {
        this._map.setZoom(this.defaultZoom);
      }

      this._addMarker(+this.x, +this.y, +this.zone);
    }

    if (_changedProperties.has(nameof('markerLayers')) && this._map) {
      this._updateMarkerLayers(this.markerLayers);
    }
  }

  private _mountClickEventListener(map: L.Map) {
    this.mapClicked = 0;
    if (!this.readOnly) {
      map.on('click', (e: L.LeafletMouseEvent) => {
        e.originalEvent.stopPropagation();
        this.mapClicked += 1;
        setTimeout(() => {
          if (this.mapClicked === 1) {
            this._onClick(e, this);
            this.mapClicked = 0;
          }
        }, 300);
      });
    } else {
      this.addEventListener('dragend', (e: Event) => {
        e.stopPropagation();
      });
      this.addEventListener('click', (e: Event) => {
        e.stopPropagation();
      });
    }

    map.on('dblclick', (e: L.LeafletMouseEvent) => {
      e.originalEvent.stopPropagation();
      this.mapClicked = 0;
      this._map?.zoomIn();
    });
  }

  private _mountZoomEventListener() {
    if (!this._map) {
      return;
    }

    const elementZoomIn = this.shadowRoot?.querySelector('a.leaflet-control-zoom-in') as HTMLElement | undefined;
    const elementZoomOut = this.shadowRoot?.querySelector('a.leaflet-control-zoom-out') as HTMLElement | undefined;
    if (!elementZoomIn || !elementZoomOut) {
      return;
    }

    L.DomEvent.addListener(elementZoomIn, 'click', (_: any) => {
      this._inManualZoom = true;
    });

    L.DomEvent.addListener(elementZoomOut, 'click', (_: any) => {
      this._inManualZoom = true;
    });

    this._map.on('zoomend', (_e: L.LeafletEvent) => {
      if (!this._map) {
        return;
      }

      if (!this._inManualZoom) {
        if (this.hasAllMapData) {
          this._map?.setZoom(this.currentZoom || this.defaultZoom);
        }
        return;
      }

      const currZoom = this._map.getZoom();
      this.currentZoom = currZoom;

      this._inManualZoom = false;
    });
  }

  private _mountObserver(mapElement: HTMLElement) {
    this._resizeObserver = new ResizeObserver(() => {
      this._map?.invalidateSize();
      this._fitBounds();
      if (this.hasAllMapData) {
        this._map?.setZoom(this.currentZoom || this.defaultZoom);
      }
    });

    this._resizeObserver.observe(mapElement);
  }

  private get hasAllMapData(): boolean {
    return !!this.x && !!this.y && !!this.zone;
  }

  private _createMarkerGroups(markers: MapMarker[], index: number): L.FeatureGroup<any> {
    const layers = markers
      .filter(marker => !isNaN(+marker.x) && !isNaN(+marker.y) && !isNaN(+marker.zone))
      .map(layer => {
        const latLng = L.utm({ x: +layer.x, y: +layer.y, zone: +layer.zone, band: 'V', southHemi: false }).latLng();
        const iconIndex = index > leafletIcons.length ? leafletIcons.length : index;
        const opt = {
          icon: leafletIcons[iconIndex]
        };
        return marker(latLng, opt).bindPopup(layer.title);
      });
    const group = featureGroup(layers);
    return group;
  }

  private _createMarkerLayers(layers: MapMarkerLayer[]) {
    return layers.reduce((layerGroups, layer, index) => {
      if (layer.markers?.length && !layerGroups[layer.title]) {
        layerGroups[layer.title] = this._createMarkerGroups(layer.markers, index);
      }
      return layerGroups;
    }, {} as Dictionary<L.FeatureGroup<any>>);
  }

  private _updateMarkerLayers(layers: MapMarkerLayer[] | null): void {
    if (this.controlLayer && this.controlGroups && Object.keys(this.controlGroups).length) {
      Object.values(this.controlGroups).forEach(group => {
        group.clearLayers();
        this.controlLayer?.removeLayer(group);
      });
    }
    if (!layers || !layers.length) {
      this.controlGroups = {};
      return;
    }
    this.controlGroups = this._createMarkerLayers(layers);
    Object.entries(this.controlGroups).forEach(([key, group], index) => {
      this.controlLayer?.addOverlay(group, `<div style="display:inline-block;"><div style="display:flex;"><span>${key}</span> ${leafletIcons[index > leafletIcons.length ? leafletIcons.length : index].options.html}</div></div>`);
      group.addTo(this.mapLayer!);
    });
    this._fitBounds();
  }

  _removeMapMarker(): void {
    if (!this._map || !this._mapMarker || !this.mapLayer) {
      return;
    }

    this.mapLayer?.removeLayer(this._mapMarker);
  }

  _addMarker(x: number, y: number, zone: number): void {
    // Remove any existing center marker
    this._removeMapMarker();

    // Set a new one
    const item = L.utm({ x: x, y: y, zone: zone, band: 'V', southHemi: false });

    try {
      this._mapMarker = marker(item.latLng(), { icon: blackIcon })
        .bindPopup(`Øst: ${x}, Nord: ${y}, Sone: ${zone}`)
        .addTo(this.mapLayer!);

      this.errorMsg = null;

      const latLng = this._mapMarker.getLatLng();
      this._map!.setView(latLng, this.currentZoom || this.defaultZoom);
    } catch (e) {
      this.errorMsg = 'Feil: Ugyldige koordinater';
    }
  }

  _fitBounds(): void {
    if (!this._map) {
      return;
    }

    let bounds: L.LatLngBounds | null = null;
    if (this.mapLayer) {
      bounds = this.mapLayer.getBounds();
      if (bounds.isValid()) {
        // it looks like this could fail if the coordinates are not valids.
        // As example if you set a UTM North as '9999999' it crashes with:
        // Cannot read properties of null (reading 'lat') OBS. partial stacktrace
        //  > at Object.latLngToPoint
        //  > at i.latLngToLayerPoint
        this._map.fitBounds(bounds);
      }
    }
  }

  _onClick(e: L.LeafletMouseEvent, thisLit: this): void {
    // Remove any existing center marker
    e.originalEvent.preventDefault();
    e.originalEvent.stopPropagation();
    thisLit._removeMapMarker();
    const utmMarker = e.latlng.utm();
    this.zone = '' + utmMarker.zone;
    this.x = '' + utmMarker.x;
    this.y = '' + utmMarker.y;
    this.dispatchEvent(new LitChangeCustomEvent());
  }

  disconnectedCallback(): void {
    if (this._resizeObserver) {
      this._resizeObserver.disconnect();
    }
  }

  render(): TemplateResult {
    return html`
            <link rel="stylesheet" href="https://unpkg.com/leaflet@${version}/dist/leaflet.css" />
            <div class="map-leaflet" id="mapid"></div>
            ${this.errorMsg ? html`<md-info-box class="error" value="${this.errorMsg}"></md-info-box>` : null}`;
  }
}
