
import { LitElement, svg, TemplateResult } from 'lit';
import { customElement } from 'lit/decorators.js';
import { BaseMdElement } from '../base-lit-element';
import { iconClass } from '../../directives/icon-class.directive';
import { BaseIcon } from './base-icon.mixin';

// keep in synk global.d.ts
@customElement('md-icon-zoom-plus')
export class IconZoomPlus extends BaseIcon(BaseMdElement(LitElement)) {

  constructor() {
    super();

    this.smallSvgIcon = svg`
      <svg ${iconClass(this)} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
        <g>
          <path d="M16.8,15.2l-3.9-4-.63.62L11.1,10.62a4.93,4.93,0,0,0,1.26-3.3A4.86,4.86,0,0,0,7.58,2.39,4.86,4.86,0,0,0,2.8,7.32a4.86,4.86,0,0,0,4.78,4.92,4.62,4.62,0,0,0,2.79-.94l1.19,1.24.38-.37-1,1,3.9,4.06a1.35,1.35,0,0,0,1.9,0l0,0A1.44,1.44,0,0,0,16.8,15.2ZM3.8,7.32A3.86,3.86,0,0,1,7.58,3.39a3.86,3.86,0,0,1,3.78,3.93,3.85,3.85,0,0,1-3.78,3.92A3.86,3.86,0,0,1,3.8,7.32Z"/>
          <polygon points="8.08 5.12 7.08 5.12 7.08 6.84 5.37 6.84 5.37 7.84 7.08 7.84 7.08 9.56 8.08 9.56 8.08 7.84 9.8 7.84 9.8 6.84 8.08 6.84 8.08 5.12"/>
        </g>
      </svg>`;

    this.largeSvgIcon = svg`
      <svg ${iconClass(this)} xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64">
        <g>
          <path d="M53.41,48.31,40.93,35.56,38.45,38,34.28,33.7a15.26,15.26,0,0,0,4.25-10.62A14.86,14.86,0,0,0,23.92,8,14.86,14.86,0,0,0,9.31,23.08,14.87,14.87,0,0,0,23.92,38.16a14.3,14.3,0,0,0,9-3.23l4.22,4.39.65-.63-3,3.05,12.48,13a4.29,4.29,0,0,0,6.07.06l.1-.1A4.58,4.58,0,0,0,53.41,48.31ZM11.14,23.08A13,13,0,0,1,23.92,9.83,13,13,0,0,1,36.71,23.08,13,13,0,0,1,23.92,36.33,13,13,0,0,1,11.14,23.08Z"/>
          <polygon points="24.84 16.05 23.01 16.05 23.01 22.23 16.82 22.23 16.82 24.06 23.01 24.06 23.01 30.24 24.84 30.24 24.84 24.06 31.02 24.06 31.02 22.23 24.84 22.23 24.84 16.05"/>
        </g>
      </svg>`;
  }

  render(): TemplateResult {
    return this.renderIcon();
  }
}
