<md-page-title value="md-toggle | Toggle"></md-page-title>

<div class="flex direction-column gap-4 p-3">
  <h3>Form binding</h3>
  <md-toggle [checked]="!!selected.value" (click)="onChanged($event)"></md-toggle>
  Checked: {{selected.value | json}}

  <h3>Default <em>focus</em></h3>
  <md-toggle [appAutofocus]="true" (click)="handleClick($event)"></md-toggle>

  <h3>Default <em>unchecked</em></h3>
  <md-toggle (click)="handleClick($event)"></md-toggle>

  <h3>Default <em>checked</em></h3>
  <md-toggle checked (click)="handleClick($event)"></md-toggle>

  <h3>Slot | label to the left (default)</h3>
  <md-toggle>Label</md-toggle>

  <h3>Slot | html</h3>
  <md-toggle>A label with a <span><a href="vg.no">link</a> and <em>more</em></span></md-toggle>

  <h3>Slot | label to the right</h3>
  <md-toggle labelDirection="right">Label</md-toggle>

  <h3>Disabled & Unchecked</h3>
  <md-toggle disabled></md-toggle>

  <h3>Disabled & Checked</h3>
  <md-toggle disabled checked></md-toggle>
</div>
