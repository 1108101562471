import { html, TemplateResult } from 'lit';
import { customElement, property, query } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';
import { AvailableIcons } from '../icons/icons';
import { ILitMandatoryFields } from '../../helpers/lit-mandatory-fields';
import { nameofFactory } from '../../helpers/nameof';
import { PropertyRequiredError } from '../../helpers/property-required-error';
import { BaseLitElement } from '../base-lit-element';
import { LitChangeEvent, LitInputEvent } from '../../helpers/events';
import { ILitFocusable } from '../focusable';
import styles from './text-field-icon-style.scss';
import '../icons/icon';

type RenderParams = {
  value: string,
  icon: AvailableIcons,
  invalid?: string,
  placeholder?: string
}

const nameof = nameofFactory<TextFieldIcon>();

// keep in synk global.d.ts
@customElement('md-text-field-icon')
export class TextFieldIcon extends BaseLitElement implements ILitMandatoryFields, ILitFocusable {
  public static styles = styles;

  @query('input', true) _inputNode!: HTMLInputElement;

  @property({ type: String, attribute: true, reflect: true }) type: 'text' | 'number' = 'text';

  @property({ type: String, attribute: true, reflect: true }) invalid?: string;

  @property({ type: String, attribute: true, reflect: true }) placeholder = '';

  @property({ type: Boolean, attribute: true, reflect: true }) readOnly = false;

  @property({ type: Boolean, attribute: true, reflect: true }) noBorder = false;

  @property({ type: String, attribute: true, reflect: true }) value = '';

  @property({ type: String, attribute: true, reflect: true }) icon?: AvailableIcons;

  focus(options?: FocusOptions): void {
    this._inputNode.focus(options);
  }

  update(changedProperties: Map<string | number | symbol, unknown>): void {
    this.checkProperties();

    super.update(changedProperties);
  }

  checkProperties(): void {
    if (!(this.icon)) {
      throw new PropertyRequiredError(this, nameof('icon'), this.icon);
    }
  }

  _dispatchChange(): void {
    this.dispatchEvent(new LitInputEvent());
    this.dispatchEvent(new LitChangeEvent());
  }

  _onChange(event: Event): void {
    event.stopPropagation();
    this.value = (event.target as HTMLInputElement).value;
    if (this.type === 'number') {
      this.value = this.value.replace(/[A-Za-z!@#$%^&*()]/g, '');
    }
    this._dispatchChange();
  }

  renderReadOnly(value: string, icon: AvailableIcons) {
    return html`
      <div class="input-container">
        <md-icon icon="${icon}" class="size-m"></md-icon>
        <span title="${value}" class="read-only">${value}</span>
      </div>
        `;
  }

  renderComponent(params: RenderParams) {
    const { value, icon, invalid, placeholder } = params;
    return html`
      <div class="input-container ${classMap({ 'alert-border': !!this.invalid })}">
        <md-icon icon="${icon}" class="size-m"></md-icon>
        <input aria-label="icon-text-field" type="text" .value="${value}" placeholder="${placeholder ?? ''}"
          @input="${this._onChange}">
      </div>
      ${invalid && html`<span class="alert-text">${invalid}</span>`}
    `;
  }

  render(): TemplateResult | null {
    if (!this.icon) {
      return null;
    }
    if (this.readOnly) {
      return this.renderReadOnly(this.value, this.icon);
    }
    return this.renderComponent({
      value: this.value,
      icon: this.icon,
      invalid: this.invalid,
      placeholder: this.placeholder
    });
  }

}
