import { LitElement, svg, TemplateResult } from 'lit';
import { customElement } from 'lit/decorators.js';
import { BaseMdElement } from '../base-lit-element';
import { iconClass } from '../../directives/icon-class.directive';
import { BaseIcon } from './base-icon.mixin';

// keep in synk global.d.ts
@customElement('md-icon-pin')
export class IconPin extends BaseIcon(BaseMdElement(LitElement)) {

  constructor() {
    super();

    this.smallSvgIcon = svg`
      <svg ${iconClass(this)} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
        <g>
          <rect x="3.5" y="15.16" width="12.99" height="1"/>
          <path d="M6.89,10.06,10,14.56l3.12-4.45a3.34,3.34,0,0,0,.37-.53A3.8,3.8,0,0,0,14,7.71a3.91,3.91,0,0,0-3.9-3.87H10a3.91,3.91,0,0,0-3.9,3.82,3.88,3.88,0,0,0,.49,1.88A4.28,4.28,0,0,0,6.89,10.06ZM10,6.42A1.61,1.61,0,1,1,8.39,8,1.61,1.61,0,0,1,10,6.42Z"/>
        </g>
      </svg>`;

    this.largeSvgIcon = svg`
      <svg ${iconClass(this)} xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64">
        <g>
          <rect x="11.21" y="50.1" width="41.58" height="2"/>
          <path d="M32.17,11.9h-.09A12.6,12.6,0,0,0,19.29,24.23a12.12,12.12,0,0,0,1.56,6A12.82,12.82,0,0,0,22,31.94l9.84,14.52L41.89,32.11a12.63,12.63,0,0,0,1.19-1.7,11.93,11.93,0,0,0,1.63-6A12.58,12.58,0,0,0,32.17,11.9ZM32,30.57a5.16,5.16,0,1,1,5.15-5.16A5.15,5.15,0,0,1,32,30.57Z"/>
        </g>
      </svg>`;
  }

  render(): TemplateResult {
    return this.renderIcon();
  }
}
