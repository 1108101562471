<md-page-title value="md-tooltip | Tooltip"></md-page-title>

<div class="flex direction-column gap-4 p-3">
  <md-paragraph>
    {{exampleDesc}}
  </md-paragraph>

  <md-form-field label="Default">
    <md-icon-warning class="size-m success" id="defaultTooltip" aria-describedby="tooltip"></md-icon-warning>
    <md-tooltip anchor="#defaultTooltip"> {{testTooltip}}</md-tooltip>
  </md-form-field>

  <md-form-field label="Light">
    <md-icon-warning class="size-m success" id="lightTooltip" aria-describedby="tooltip"></md-icon-warning>
    <md-tooltip class="light" anchor="#lightTooltip"> {{testTooltip}}</md-tooltip>
  </md-form-field>

  <md-form-field label="Right">
    <md-icon-warning class="size-m success" id="rightTooltip" aria-describedby="tooltip"></md-icon-warning>
    <md-tooltip placement="right" anchor="#rightTooltip"> {{testTooltip}}</md-tooltip>
  </md-form-field>

  <div style="display: flex; flex-direction: row-reverse;">
    <md-form-field label="Left">
      <md-icon-warning class="size-m success" id="leftTooltip" aria-describedby="tooltip"></md-icon-warning>
      <md-tooltip placement="left" anchor="#leftTooltip"> {{testTooltip}}</md-tooltip>
    </md-form-field>
  </div>

  <md-form-field label="Left override">
    <md-icon-warning class="size-m success" id="leftOverrideTooltip" aria-describedby="tooltip"></md-icon-warning>
    <md-tooltip placement="auto" anchor="#leftOverrideTooltip"> {{testTooltip}}</md-tooltip>
  </md-form-field>

  <md-form-field label="Top">
    <md-icon-warning class="size-m success" id="topTooltip" aria-describedby="tooltip"></md-icon-warning>
    <md-tooltip placement="top" anchor="#topTooltip"> {{testTooltip}}</md-tooltip>
  </md-form-field>

  <md-form-field label="Auto">
    <md-icon-warning class="size-m success" id="autoTooltip" aria-describedby="tooltip"></md-icon-warning>
    <md-tooltip placement="auto" anchor="#autoTooltip"> {{testTooltip}}</md-tooltip>
  </md-form-field>

</div>
