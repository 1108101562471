<md-page-title value="md-upload | Upload"></md-page-title>

<div class="flex direction-column gap-4 p-3">
  <h3>Upload</h3>

  <md-form-field label="Vedlegg (Primary)">
    <md-upload #upload text="Legg ved fil (.pdf, .docx)" [uploadedBytes]="uploadedBytes" (change)="uploadChanged()"
      (abort)="uploadAborted()" accept=".pdf,.docx">
    </md-upload>
  </md-form-field>

  <md-download-file *ngFor="let file of files$ | async; index as i" [size]="file.size" [filename]="file.name"
    [value]="file.name" (click)="downloadClick($event)" (delete)="deleteClick($event)">
  </md-download-file>

  <h3>Vedlegg (disabled)</h3>
  <md-upload [text]="uploaderText" disabled>
  </md-upload>
</div>
