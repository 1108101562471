import { html, PropertyValues, TemplateResult } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { ILitMandatoryFields } from '../../helpers/lit-mandatory-fields';
import { BaseLitElement } from '../base-lit-element';
import { LitChangeEvent } from '../../helpers/events';
import styles from './radio-group-style.scss';
import { PropertyRequiredError } from '../../helpers/property-required-error';
import { nameofFactory } from '../../helpers/nameof';
import { RadioButton } from './radio-button';

const nameof = nameofFactory<RadioGroup>();

// keep in synk global.d.ts
@customElement('md-radio-button-group')
export class RadioGroup extends BaseLitElement implements ILitMandatoryFields {
  public static styles = styles;

  private _value = '';

  @property({ type: String, attribute: true, reflect: true }) invalid?: string;

  /** Input radio name. */
  @property({ type: String, attribute: true, reflect: true })
  name = '';

  /** Input radio value. */
  @property({ type: String, attribute: true, reflect: true })
  set value(value: string) {
    this._value = value;
    this.onDOMChange();
  }

  get value() {
    return this._value;
  }

  /** Set the shadow-root type, `open` if you want more control. For example you could use `open` when you want to use radio-buttons inside a table. */
  @property({ type: Boolean, attribute: true, reflect: true })
  shadowRootOpen = false;

  protected createRenderRoot() {
    return this.shadowRootOpen ? this : super.createRenderRoot();
  }

  update(changedProperties: Map<string | number | symbol, unknown>): void {
    this.checkProperties();

    super.update(changedProperties);
  }

  checkProperties(): void {
    if (!this.name) {
      throw new PropertyRequiredError(this, nameof('name'));
    }
  }

  firstUpdated(_changedProperties: PropertyValues): void {
    super.firstUpdated(_changedProperties);
    if (!this.hasAttribute('type')) {
      this.setAttribute('role', 'radiogroup');
    }

    this.addEventListener('radioChange', this._onClick);
    this.addEventListener('keydown', this._onKeyBoardEvent);
    this.onDOMChange();
  }

  onDOMChange(): void {
    const firstCheckedButton = this.checkedRadioButton;
    if (firstCheckedButton) {
      this._uncheckAll();
      this._checkNode(firstCheckedButton);
    }
  }

  get checkedRadioButton(): Element | null {
    const radios = this.querySelectorAll(`md-radio-button[name="${this.name}"]`);

    for (let i = 0; i < radios.length; i++) {
      const radio = radios[i] as RadioButton;
      if (radio.checked || radio.value === this.value) {
        return radio;
      }
    }

    return null;
  }

  get getLastRadioButton(): HTMLElement | null {
    const radios = this.querySelectorAll(`md-radio-button[name="${this.name}"]:not([disabled])`);
    const lastRadio = radios[radios.length - 1];
    if (!lastRadio) {
      return null;
    }
    return lastRadio as HTMLElement;
  }

  get getFirstRadioButton(): HTMLElement | null {
    const firstRadio = this.querySelectorAll(`md-radio-button[name="${this.name}"]:not([disabled])`)[0];
    if (!firstRadio) {
      return null;
    }
    return firstRadio as HTMLElement;
  }

  _setChecked(node: Element | null): void {
    if (!node) throw Error('Null node (_setChecked)');
    this._uncheckAll();
    this._checkNode(node);
  }

  _uncheckAll(): void {
    const radioButtons = this.querySelectorAll(`md-radio-button[name="${this.name}"]`);
    for (let i = 0; i < radioButtons.length; i++) {
      const btn = radioButtons[i] as HTMLElement;
      btn.removeAttribute('checked');
    }
  }

  _checkNode(node: Element): void {
    const nodeValue = (node as HTMLInputElement).value;
    node.toggleAttribute('checked');

    if (this.value !== nodeValue) {
      this.value = nodeValue;
      this.dispatchEvent(new CustomEvent(('input')));
      this.dispatchEvent(new LitChangeEvent());
    }
  }

  _onClick(e: Event): void {
    if (e.target && (e.target as RadioGroup).name === this.name) {
      this._setChecked(e.target as HTMLElement);
    }
  }

  _getSiblingRadio(radios: RadioButton[], current: RadioButton) {
    const enabledRadios = radios.filter(x => !x.disabled);
    for (let i = 0; i < enabledRadios.length; i++) {
      if (enabledRadios[i] === current) {
        if (!enabledRadios[i + 1]) {
          return null;
        }
        return enabledRadios[i + 1];
      }
    }
    return null;
  }

  _getNextRadio(current: HTMLElement) {
    const radios = Array.from(this.querySelectorAll(`md-radio-button[name="${this.name}"]`)) as RadioButton[];
    return this._getSiblingRadio(radios, current as RadioButton);
  }

  _getPreviousRadio(current: HTMLElement) {
    const radios = Array.from(this.querySelectorAll(`md-radio-button[name="${this.name}"]`)).reverse() as RadioButton[];
    return this._getSiblingRadio(radios, current as RadioButton);
  }

  _onKeyBoardEvent(ev: KeyboardEvent): void {
    switch (ev.key) {
      case 'Down': // IE/Edge specific value
      case 'ArrowDown':
      case 'ArrowRight': {
        ev.preventDefault();
        const next = this._getNextRadio(ev.target as HTMLElement);
        if (!next) {
          const first = this.getFirstRadioButton;
          first?.focus();
          return;
        }

        next.focus();
        break;
      }
      case 'Up': // IE/Edge specific value
      case 'ArrowUp':
      case 'ArrowLeft': {
        ev.preventDefault();
        const previous = this._getPreviousRadio(ev.target as HTMLElement);
        if (!previous) {
          const last = this.getLastRadioButton;
          last?.focus();
          return;
        }
        previous.focus();
        break;
      }

    }

  }

  render(): TemplateResult {
    return html`
    <div class="container">
      <slot @slotchange="${this.onDOMChange}"></slot>
    </div>
    ${this.invalid && html`<span class="alert-text">${this.invalid}</span>`}
    `;
  }
}
