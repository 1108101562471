import { Component } from '@angular/core';
import { DownloadFile } from 'miljodir-wc/dist/src/components/download-file/download-file';

@Component({
  selector: 'app-download-file',
  templateUrl: './download-file.component.html',
  styleUrls: ['./download-file.component.scss']
})
export class DownloadFileComponent {

  tooltipText = 'Tooltip text';
  downloaderText = 'Beskrivelse av håndtering av avfallet';

  downloadClick(e: Event) {
    const ctrl = e.currentTarget as DownloadFile;
    console.log('File downloaded', ctrl.value);
  }

  deleteClick(e: Event) {
    const ctrl = e.currentTarget as DownloadFile;
    console.log('File deleted', ctrl.value);
  }

}
