<md-page-title value="md-icon* | Icons"></md-page-title>

<div class="flex flex-wrap gap-4 p-3">

  <ng-container *ngFor="let path of paths">
    <div class="flex direction-column">
      <p>{{path}}</p>
      <img style="max-width: 250px; width: 100%;" [src]="path" />
    </div>
  </ng-container>
</div>
