import { Component } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';

@Component({
  selector: 'app-text-field',
  templateUrl: './text-field.component.html',
  styleUrls: ['./text-field.component.scss']
})
export class TextFieldComponent {

  searchText = '';

  form = new FormGroup({
    firstName: new FormControl('bob', [Validators.required, forbiddenNameValidator(/bob/i)]),
    lastName: new FormControl('', [Validators.required, forbiddenNameValidator(/bob/i)]),
  });

  textChanged(value: string) {
    console.log('changed to:', value);
    this.searchText = value;
  }

  onBlur() {
    console.log('blur');
  }
}

function forbiddenNameValidator(nameRe: RegExp): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const forbidden = nameRe.test(control.value);
    return forbidden ? { forbiddenName: { value: control.value } } : null;
  };
}
