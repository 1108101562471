<md-page-title value="md-form-group | Form group"></md-page-title>

<div class="flex direction-column gap-4 p-3">

  <div class="flex direction-column gap-1 p-2">
    <ng-container *ngFor="let number of [0,1]">
      <md-form-group title="Tittel/gruppenavn" (delete)="onDelete" [delete]="true">
        <ng-container *ngFor="let number of [0,1]">
          <md-form-field for="number" label="Custom tooltip">
            <md-text-field id="number" placeholder="Test tooltip" ngDefaultControl>
            </md-text-field>
          </md-form-field>
        </ng-container>
      </md-form-group>
    </ng-container>

    <md-button class="add-field fit-container">
      <md-icon-plus class="size-xs"></md-icon-plus> Legg til (gruppeid/navn)
    </md-button>
  </div>

  <md-paragraph>If <em>md-form-group</em> is used outside a defined web component, the css variable
    <em>--form-group-hover</em>
    needs to be set for expected hover effect on group and add button
  </md-paragraph>

  <div class="flex direction-column gap-1 p-2 css-variables">
    <md-form-group title="Tittel/gruppenavn" (delete)="onDelete" [delete]="false">
      <ng-container *ngFor="let number of [0,1]">
        <md-form-field for="number" label="Custom tooltip">
          <md-text-field id="number" placeholder="Test tooltip" ngDefaultControl>
          </md-text-field>
        </md-form-field>
      </ng-container>
    </md-form-group>
    <md-button class="add-field fit-container">
      <md-icon-plus class="size-xs"></md-icon-plus> Legg til (gruppeid/navn)
    </md-button>
  </div>

  <h3>Inside defined web components</h3>
  <md-button (click)="modalOpen = true">Open modal</md-button>

  <md-accordion-list [multiPanel]="false">
    <md-accordion-card header="Default" expanded>
      <div class="flex direction-column gap-1 p-2">
        <md-form-group title="Tittel/gruppenavn" (delete)="onDelete" [delete]="false">
          <ng-container *ngFor="let number of [0,1]">
            <md-form-field for="number" label="Custom tooltip">
              <md-text-field id="number" placeholder="Test tooltip" ngDefaultControl>
              </md-text-field>
            </md-form-field>
          </ng-container>
        </md-form-group>
        <md-button class="add-field fit-container">
          <md-icon-plus class="size-xs"></md-icon-plus> Legg til (gruppeid/navn)
        </md-button>
      </div>
    </md-accordion-card>

    <md-accordion-card header="Expanded" expanded status="done">
      <div class="flex direction-column gap-1 p-2">
        <md-form-group title="Tittel/gruppenavn" (delete)="onDelete" [delete]="false">
          <ng-container *ngFor="let number of [0,1]">
            <md-form-field for="number" label="Custom tooltip">
              <md-text-field id="number" placeholder="Test tooltip" ngDefaultControl>
              </md-text-field>
            </md-form-field>
          </ng-container>
        </md-form-group>
        <md-button class="add-field fit-container">
          <md-icon-plus class="size-xs"></md-icon-plus> Legg til (gruppeid/navn)
        </md-button>
      </div>
    </md-accordion-card>
  </md-accordion-list>

  <md-modal class="size-m secondary" (close)="modalOpen = false" [open]="modalOpen">
    <md-modal-title>Title</md-modal-title>
    <md-modal-content>
      <div class="flex direction-column gap-1 p-2">
        <md-form-group title="Tittel/gruppenavn" (delete)="onDelete" [delete]="false">
          <ng-container *ngFor="let number of [0,1]">
            <md-form-field for="number" label="Custom tooltip">
              <md-text-field id="number" placeholder="Test tooltip" ngDefaultControl>
              </md-text-field>
            </md-form-field>
          </ng-container>
        </md-form-group>
        <md-button class="add-field fit-container">
          <md-icon-plus class="size-xs"></md-icon-plus> Legg til (gruppeid/navn)
        </md-button>
      </div>
    </md-modal-content>
  </md-modal>

</div>
