import { html, TemplateResult } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import { BaseLitElement } from '../base-lit-element';
import { canFocus, focusableElements, ILitFocusable, standardFocusableElements } from '../focusable';
import { AccordionCard, AccordionCardEvent } from './accordion-card';
import styles from './accordion-list-style.scss';

/** 
 * Accordion list that follows w3c raccomandations about keyboard support:
 * https://www.w3.org/TR/wai-aria-practices-1.1/examples/accordion/accordion.html
 * 
 * Space or Enter:  When focus is on the accordion header of a collapsed section, expands the section.
 * 
 * Tab:             Moves focus to the next focusable element.
 *                  All focusable elements in the accordion are included in the page Tab sequence.
 * 
 * Shift + Tab:     Moves focus to the previous focusable element.
 *                  All focusable elements in the accordion are included in the page Tab sequence.
 * 
 * Down Arrow:      When focus is on an accordion header, moves focus to the next accordion header.
 *                  When focus is on last accordion header, moves focus to first accordion header.
 * 
 * Up Arrow:        When focus is on an accordion header, moves focus to the previous accordion header.
 *                  When focus is on first accordion header, moves focus to last accordion header.
 * 
 * Home:            When focus is on an accordion header, moves focus to the first accordion header.
 * 
 * End:             When focus is on an accordion header, moves focus to the last accordion header.
 */
// keep in synk global.d.ts
@customElement('md-accordion-list')
export class AccordionList extends BaseLitElement {

  public static styles = styles;

  /** If `multipanel true`, it allows multiple panels to be open at the same time. */
  @property({ type: Boolean, attribute: true, reflect: true }) multiPanel = false;

  @state()
  protected _accordionCards: AccordionCard[] = [];

  connectedCallback(): void {
    this._accordionCards = Array.from(this.querySelectorAll(':scope > md-accordion-card')) ?? [];

    this._listenToAccordionEvent('accordion-toggle-card', this.handleToggle);
    this._listenToAccordionEvent('accordion-next-card', this._onNextAccordionCard);
    this._listenToAccordionEvent('accordion-previous-card', this._onPreviousAccordionCard);
    this._listenToAccordionEvent('accordion-first-card', this._onFirstAccordionCard);
    this._listenToAccordionEvent('accordion-last-card', this._onLastAccordionCard);
    this._listenToAccordionEvent('tab-to-previous-page-element', this._onFocusPreviousPageElement);

    super.connectedCallback();
  }

  _listenToAccordionEvent(type: AccordionCardEvent, listener: EventListenerOrEventListenerObject) {
    this.addEventListener(type, listener);
  }

  onSlotChange(_: Event): void {
    this._accordionCards = Array.from(this.querySelectorAll('md-accordion-card')) || [];
  }

  toggleAccordionCard(target: AccordionCard): void {
    this._accordionCards.forEach(panel => {
      if (
        this.multiPanel === false &&
        panel.hasAttribute('expanded') &&
        panel !== target
      ) {
        panel.removeAttribute('expanded');
      }

      if (panel === target) {
        if (panel.hasAttribute('expanded')) {
          panel.removeAttribute('expanded');
        } else {
          panel.setAttribute('expanded', 'true');
        }
      }
    });
  }

  openAccordionCard(target: AccordionCard): void {
    this._accordionCards.forEach(panel => {
      if (
        this.multiPanel === false &&
        panel.hasAttribute('expanded') &&
        panel !== target
      ) {
        panel.removeAttribute('expanded');
      }

      if (panel === target && panel.hasAttribute('expanded') === false) {
        panel.setAttribute('expanded', 'true');
      }
    });
  }

  handleToggle(event: Event): void {
    event.stopPropagation();
    const { target } = event;
    this.toggleAccordionCard(target as AccordionCard);
  }

  _gotoAccordionCard(target: AccordionCard | null, type: 'next' | 'prev' | 'first' | 'last'): void {
    const numCards = this._accordionCards?.length || 0;
    if (!target || numCards <= 0) {
      return;
    }

    const currentIndex = this._accordionCards.findIndex(panel => panel === target);
    let nextIndex = 0;

    switch (type) {
      case 'next':
        nextIndex = currentIndex < 0 || currentIndex >= (numCards - 1) ? 0 : currentIndex + 1;
        break;

      case 'prev':
        nextIndex = currentIndex - 1 < 0 ? 0 : currentIndex - 1;
        break;

      case 'first':
        nextIndex = 0;
        break;

      case 'last':
        nextIndex = numCards > 0 ? numCards - 1 : 0;
        break;
    }

    const nextCard = this._accordionCards[nextIndex];
    this.openAccordionCard(nextCard);
    nextCard.focus();
  }

  _onNextAccordionCard(event: Event): void {
    event.stopPropagation();
    const target = event.target as AccordionCard | null;
    this._gotoAccordionCard(target, 'next');
  }

  _onPreviousAccordionCard(event: Event): void {
    event.stopPropagation();
    const target = event.target as AccordionCard | null;
    this._gotoAccordionCard(target, 'prev');
  }

  _onFirstAccordionCard(event: Event): void {
    event.stopPropagation();
    const target = event.target as AccordionCard | null;
    this._gotoAccordionCard(target, 'first');
  }

  _onLastAccordionCard(event: Event): void {
    event.stopPropagation();
    const target = event.target as AccordionCard | null;
    this._gotoAccordionCard(target, 'last');
  }

  /** Navigate back in the DOM page to find the first focusable element. Needed by `SHIFT + TAB`. */
  _onFocusPreviousPageElement(event: Event): void {
    event.stopPropagation();

    const currentElement = event.target as HTMLElement;
    const query = focusableElements.concat(standardFocusableElements).join(',');

    const parts = event.composedPath()
      .filter((_, index) => index > 0)
      .filter(el => (el as HTMLElement).nodeType === Node.ELEMENT_NODE);

    for (let i = 0; i < parts.length; i++) {
      const part = parts[i] as HTMLElement;
      const focusableNodes = Array.from(part.querySelectorAll(query));
      const currentIndex = focusableNodes.indexOf(currentElement);
      const nodes = focusableNodes
        .filter((_, index) => currentIndex < 0 || index < currentIndex)
        .filter(x => !currentElement.contains(x))
        .reverse();

      for (let ii = 0; ii < nodes.length; ii++) {
        const node = nodes[ii];
        const focusable = canFocus(node);
        if (!focusable) {
          continue;
        }

        (node as unknown as ILitFocusable).focus();
        return;
      }
    }

  }

  render(): TemplateResult {
    return html`
    <div id="container">
      <slot @slotchange="${this.onSlotChange}"></slot>
    </div>
        `;
  }
}
