
import { LitElement, svg, TemplateResult } from 'lit';
import { customElement } from 'lit/decorators.js';
import { BaseMdElement } from '../base-lit-element';
import { iconClass } from '../../directives/icon-class.directive';
import { BaseIcon } from './base-icon.mixin';

// keep in synk global.d.ts
@customElement('md-icon-sorting-none')
export class IconSortingNone extends BaseIcon(BaseMdElement(LitElement)) {

  constructor() {
    super();

    // src\assets\ikoner\20px\icon_20px_sorting.svg
    this.smallSvgIcon = svg`
      <svg ${iconClass(this)} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
        <g>
          <path d="M14,12.14l-3.79,4.07L6.09,12.14H14m.69-1H5.36a.7.7,0,0,0-.49,1.19l4.81,4.81a.7.7,0,0,0,.49.2.67.67,0,0,0,.51-.22l4.47-4.81a.69.69,0,0,0-.51-1.17Z"/>
          <path d="M9.84,3.79l4.07,4.07H6.05L9.84,3.79m0-1.13a.67.67,0,0,0-.51.22L4.85,7.69a.69.69,0,0,0,.51,1.17h9.28a.7.7,0,0,0,.49-1.19L10.32,2.86a.7.7,0,0,0-.49-.2Z"/>
        </g>
      </svg>`;

    // src\assets\ikoner\64px\64px_sorting.svg
    this.largeSvgIcon = svg`
      <svg ${iconClass(this)} xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64">
        <g>
          <path d="M46.84,37.63a.21.21,0,0,1,.22.14A.23.23,0,0,1,47,38L32.72,53.42a.23.23,0,0,1-.17.08.28.28,0,0,1-.17-.07L17,38a.2.2,0,0,1,0-.25.21.21,0,0,1,.22-.15H46.84m0-2H17.16a2.24,2.24,0,0,0-1.58,3.82L31,54.84a2.22,2.22,0,0,0,3.22-.06L48.48,39.39a2.24,2.24,0,0,0-1.64-3.76Z"/>
          <path d="M31.45,10.5a.28.28,0,0,1,.17.07L47,26a.2.2,0,0,1,.05.25.21.21,0,0,1-.22.15H17.16a.21.21,0,0,1-.22-.14A.23.23,0,0,1,17,26l14.3-15.39a.23.23,0,0,1,.17-.08m0-2a2.22,2.22,0,0,0-1.64.72L15.52,24.61a2.24,2.24,0,0,0,1.64,3.76H46.84a2.24,2.24,0,0,0,1.58-3.82L33,9.16a2.23,2.23,0,0,0-1.58-.66Z"/>
        </g>
      </svg>`;
  }

  render(): TemplateResult {
    return this.renderIcon();
  }
}
