import { LitElement, ElementPart, noChange } from 'lit';
import { Directive, directive, DirectiveParameters, PartInfo, PartType } from 'lit/directive.js';

/*
* Directive to propagate host classes to the child element
*/
class PropagateClassesDirective extends Directive {

  constructor(partInfo: PartInfo) {
    super(partInfo);
    if (
      partInfo.type !== PartType.ELEMENT
    ) {
      throw new Error(`The directive ${PropagateClassesDirective.name} must be used in an element.`);
    }
  }

  update(part: ElementPart, [hostElement, hostAdditionalClasses]: DirectiveParameters<this>) {
    const hostClasses = hostElement.attributes.getNamedItem('class')?.value?.split(' ') || [];

    (hostClasses.concat(hostAdditionalClasses)).forEach(c => {
      if (!part.element.classList.contains(c)) {
        part.element.classList.add(c);
      }
    });

    // we have manually manipulated the DOM, so no built-in render is needed
    return noChange;
  }

  render(hostElement: LitElement, additionalClasses: string[]) {
    return noChange;
  }
}

export const propagateClasses = directive(PropagateClassesDirective);
