import { html, TemplateResult } from 'lit';
import { live } from 'lit/directives/live.js';
import { customElement, property, query } from 'lit/decorators.js';
import { BaseLitElement } from '../base-lit-element';
import { LitChangeEvent, LitInputEvent } from '../../helpers/events';
import { ILitFocusable } from '../focusable';
import styles from './text-field-style.scss';

/** A input `type=text` generic element. 
 * If you need to bind to a numeric value you could found usefult the specialized component `md-numeric-field`.
*/
declare global {
  interface HTMLElementTagNameMap {
    'md-text-field': TextField;
  }
}

// keep in synk global.d.ts
@customElement('md-text-field')
export class TextField extends BaseLitElement implements ILitFocusable {
  static styles = [styles];

  @property({ type: String, attribute: true, reflect: true }) invalid?: string;
  @property({ type: String, attribute: true, reflect: true }) placeholder = '';
  @property({ type: Boolean, attribute: true, reflect: true }) readOnly = false;
  @property({ type: Boolean, attribute: true, reflect: true }) noBorder = false;

  @property({ type: String, attribute: true, reflect: true }) value = '';

  /** Same as the `input type` attribute. */
  @property({ type: String, attribute: true, reflect: true }) type = 'text';

  @query('input', true) _inputNode!: HTMLInputElement;

  focus(options?: FocusOptions): void {
    this._inputNode.focus(options);
  }

  _dispatchChange(): void {
    this.dispatchEvent(new LitInputEvent());
    this.dispatchEvent(new LitChangeEvent());
  }

  _onChange(event: Event): void {
    event.stopPropagation();
    this.value = (event.target as HTMLInputElement).value;
    this._dispatchChange();
  }

  renderComponent(): TemplateResult {
    return this.readOnly
      ? html`<span title="${this.value}" class="read-only">${this.value}</span>`
      : html`
        <input aria-label="md-text-field" .type="${this.type}" .value="${live(this.value)}"
          placeholder="${this.placeholder ?? ''}" @input="${this._onChange}" class="${this.invalid ? 'alert-border' : ''}">
      `;
  }

  render(): TemplateResult {
    return html`
        ${this.renderComponent()}
        ${this.invalid && html`<span class="alert-text">${this.invalid}</span>`}
      `;
  }

}
