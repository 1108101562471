import { LitElement, svg, TemplateResult } from 'lit';
import { customElement } from 'lit/decorators.js';
import { BaseMdElement } from '../base-lit-element';
import { iconClass } from '../../directives/icon-class.directive';
import { BaseIcon } from './base-icon.mixin';

// TODO: 64px version does not support to set the color

// keep in synk global.d.ts
@customElement('md-icon-print')
export class IconPrint extends BaseIcon(BaseMdElement(LitElement)) {

  constructor() {
    super();

    // src\assets\ikoner\20px\icon_20px_print.svg
    this.smallSvgIcon = svg`
      <svg ${iconClass(this)} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
        <defs>
        <style>
          .cls-1 {
            fill: none;
          }

          .cls-2 {
            fill: #222;
          }
        </style>
      </defs>
      <g>
        <g>
          <g>
            <polygon class="cls-1" points="15.5 10.25 15.5 12.75 15.99 12.75 16 12.74 16 7.76 4.01 7.75 4 12.74 4.5 12.74 4.5 10.25 15.5 10.25"/>
            <path class="cls-2" d="M16,6.75H4a1,1,0,0,0-1,1v5a1,1,0,0,0,1,1H4.5v3.5h11v-3.5H16a1,1,0,0,0,1-1v-5A1,1,0,0,0,16,6.75Zm-1.49,6v3.5h-9v-5h9Zm1.5,0h-.5v-2.5H4.5v2.49H4v-5H16Z"/>
          </g>
          <path class="cls-2" d="M14.42,2.75H5.58A.58.58,0,0,0,5,3.33V7.17a.58.58,0,0,0,.58.58h8.84A.58.58,0,0,0,15,7.17V3.33A.58.58,0,0,0,14.42,2.75Zm-.42,4H6v-3h8Z"/>
        </g>
        <rect class="cls-2" x="7.36" y="11.86" width="5.64" height="1"/>
        <rect class="cls-2" x="7.36" y="13.86" width="5.64" height="1"/>
      </g>
      </svg>`;

    this.largeSvgIcon = this.smallSvgIcon;
  }

  render(): TemplateResult {
    return this.renderIcon();
  }
}
