import { LitElement, ElementPart, noChange } from 'lit';
import { Directive, directive, DirectiveParameters, PartInfo, PartType } from 'lit/directive.js';

/*
* Directive to propagate host classes to the child element
*/
class PropagateAttributesDirective extends Directive {

  constructor(partInfo: PartInfo) {
    super(partInfo);
    if (
      partInfo.type !== PartType.ELEMENT
    ) {
      throw new Error(`The directive ${PropagateAttributesDirective.name} must be used in an element.`);
    }
  }

  update(part: ElementPart, [hostElement, hostExcludedAttributes]: DirectiveParameters<this>) {
    const excludedAttributes = [...hostExcludedAttributes || [], 'class'];
    const hostAttributes: Attr[] = [];
    for (let i = 0; i < hostElement.attributes.length; i++) {
      const attr = hostElement.attributes[i];
      if (excludedAttributes.includes(attr.name)) {
        continue;
      }
      hostAttributes.push(attr);
    }

    hostAttributes.forEach(hostAttr => {
      const attr = document.createAttribute(hostAttr.name);
      attr.value = hostAttr.value;
      part.element.attributes.setNamedItem(attr);
    });

    // we have manually manipulated the DOM, so no built-in render is needed
    return noChange;
  }

  render(hostElement: LitElement, excludedAttributes: string[]) {
    return noChange;
  }
}

export const propagateAttributes = directive(PropagateAttributesDirective);
