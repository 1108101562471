import { Component } from '@angular/core';
import { modalSize, ModalSize } from 'miljodir-wc/dist/src/components/modal/modal';
import { SelectItemsProp } from 'miljodir-wc/dist/src/index';

type ModalTypes = 'default' | 'default-noautofocus' | 'error' | 'uncloseable' | 'secondary'
type ModalSizeShow = {
  [key in ModalSize]: boolean;
};

const initItems: SelectItemsProp[] = [
  { key: '1', text: 'Peas' },
  { key: '2', text: 'Carrots' },
  { key: '3', text: 'Tomatoes' },
  { key: '4', text: 'Brussel sprouts' },
  { key: '5', text: 'Cabbages' },
  { key: '6', text: 'Cauliflower' },
  { key: '7', text: 'Cucumber' },
  { key: '8', text: 'Chili' },
  { key: '9', text: 'Garlic' },
  { key: '10', text: 'A pretty long and big Eggplant' },
  { key: '11', text: 'Leeks' },
  { key: '12', text: 'Onions' }
];

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent {

  modalSizes = modalSize;
  selectData = initItems;
  selectedData = '1';
  confirm = false;
  default = false;
  defaultNoAutofocus = false;
  error = false;
  secondary = false;
  uncloseable = false;

  modalSizeShow: ModalSizeShow = {
    'size-xs': false,
    'size-s': false,
    'size-m': false,
    'size-l': false,
    'size-xl': false,
    'size-xl-auto': false,
    'size-auto': false
  };

  onOpen(property: ModalTypes) {
    switch (property) {
      case 'default':
        this.default = true;
        break;

      case 'default-noautofocus':
        this.defaultNoAutofocus = true;
        break;

      case 'uncloseable':
        this.uncloseable = true;
        break;

      case 'error':
        this.error = true;
        break;

      case 'secondary':
        this.secondary = true;
        break;
    }
  }

  onClosed(property: ModalTypes) {
    switch (property) {
      case 'default':
        this.default = false;
        document.getElementById('default')?.focus();
        break;

      case 'default-noautofocus':
        this.defaultNoAutofocus = false;
        document.getElementById('default-noautofocus')?.focus();
        break;

      case 'uncloseable':
        this.uncloseable = false;
        this.confirm = false;
        document.getElementById('uncloseable')?.focus();
        break;

      case 'error':
        document.getElementById('error')?.focus();
        this.error = false;
        break;

      case 'secondary':
        document.getElementById('secondary')?.focus();
        this.secondary = false;
        break;
    }
  }

  onOpenSize(property: ModalSize) {
    this.modalSizeShow[property] = true;
  }

  onClosedSize(property: ModalSize) {
    this.modalSizeShow[property] = false;
  }
}
