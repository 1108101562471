import { Component } from '@angular/core';

@Component({
  selector: 'app-link-button',
  templateUrl: './link-button.component.html',
  styleUrls: ['./link-button.component.scss']
})
export class LinkButtonComponent {

  onClick(event: Event) {
    console.log('LinkButton click', event);
  }

}
