import { LitElement, TemplateResult } from 'lit';
import { property, state } from 'lit/decorators.js';
import styles from './icon-basic-style.scss';
import { IconSize } from './icons';

/*
* Base mixin to render the correct icon based on the element class.
*/
type Constructor<T = LitElement> = new (...args: any[]) => T;

export declare class BaseIconInterface {
  inactive: boolean;

  smallSvgIcon: TemplateResult;
  largeSvgIcon: TemplateResult;

  renderIcon(): TemplateResult;
}

export const BaseIcon = <T extends Constructor<LitElement>>(superClass: T) => {
  class BaseIconClass extends superClass {
    static styles = styles;

    static largeClasses: IconSize[] = ['size-l', 'size-xl', 'size-xxl'];

    @property({ type: Boolean, attribute: true, reflect: true })
    inactive = false;

    @state()
    smallSvgIcon?: TemplateResult;

    @state()
    largeSvgIcon?: TemplateResult;

    renderIcon() {
      if (!this.smallSvgIcon || !this.largeSvgIcon) {
        throw Error('Missing svg icons');
      }

      // NB: `keydown` fires multiple times when you hold keys down, while `keyup` fires only once
      this.addEventListener('keyup', (e: KeyboardEvent) => {
        if (e.key !== 'Enter') {
          return;
        }
        this.dispatchEvent(new Event('click'));
      });

      const parentClass = this.attributes.getNamedItem('class')
        ?.value
        ?.split(' ')
        ?.filter(c => c)
        || [];

      // NB. we want to render by default a small icon (20px)
      const useLarge = !!this.largeSvgIcon && parentClass?.some(c => BaseIconClass.largeClasses.map(x => String(x)).includes(c));

      const svg = useLarge ? this.largeSvgIcon : this.smallSvgIcon;
      return svg;
    }
  }

  return BaseIconClass as Constructor<BaseIconInterface> & T;
};
