import { html, TemplateResult } from 'lit';
import { BaseLitElement } from '../base-lit-element';
import { LitRadioChangeCustomEvent } from '../../helpers/events';
import { customElement, property, query } from 'lit/decorators.js';
import { ILitMandatoryFields } from '../../helpers/lit-mandatory-fields';
import { PropertyRequiredError } from '../../helpers/property-required-error';
import { nameofFactory } from '../../helpers/nameof';
import { ILitFocusable } from '../focusable';
import styles from './radio-button-style.scss';

const nameof = nameofFactory<RadioButton>();

// keep in synk global.d.ts
@customElement('md-radio-button')
export class RadioButton extends BaseLitElement implements ILitMandatoryFields, ILitFocusable {

  public static styles = styles;

  @property({ type: String, attribute: true, reflect: true }) name = '';
  @property({ type: Boolean, attribute: true, reflect: true }) checked = false;
  @property({ type: String, attribute: true, reflect: true }) value = '';
  @property({ type: Boolean, attribute: true, reflect: true }) disabled = false;

  @query('input', true) _input!: HTMLInputElement;

  update(changedProperties: Map<string | number | symbol, unknown>): void {
    this.checkProperties();

    super.update(changedProperties);

    if (this.disabled)
      this.tabIndex = -1;
  }

  checkProperties(): void {
    if (!this.name) {
      throw new PropertyRequiredError(this, nameof('name'));
    }
  }

  connectedCallback(): void {
    super.connectedCallback();
    this.addEventListener('keyup', (e: KeyboardEvent) => (e.key === 'Enter' || e.key === ' ') && this._onClick(e));
    this.addEventListener('click', (e: MouseEvent) => this._onClick(e));
  }

  _onClick(e: MouseEvent | KeyboardEvent): void {
    e.preventDefault();
    e.stopPropagation();
    if (!this.disabled)
      this.dispatchEvent(new LitRadioChangeCustomEvent());
  }

  focus(options?: FocusOptions): void {
    this._input.focus(options);
  }

  render(): TemplateResult {
    return html`
    <label class="b-contain">
      <input type="radio" role="radio" .checked="${this.checked}" .value="${this.value}" .name="${this.name}"
        .disabled="${this.disabled}">
      <span class="slot-content">
        <slot></slot>
      </span>
      <div class="b-input"></div>
    </label>
      `;
  }

}
