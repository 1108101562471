import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { AccordionComponent } from './accordion/accordion.component';
import { AlertMessageComponent } from './alert-message/alert-message.component';
import { ButtonComponent } from './button/button.component';
import { CheckboxComponent } from './checkbox/checkbox.component';
import { CheckboxGroupComponent } from './checkbox-group/checkbox-group.component';
import { ContextMenuComponent } from './context-menu/context-menu.component';
import { DocsComponent } from './docs/docs.component';
import { DownloadFileComponent } from './download-file/download-file.component';
import { FooterComponent } from './footer/footer.component';
import { FormComponent } from './form/form.component';
import { FormFieldComponent } from './form-field/form-field.component';
import { FormGroupComponent } from './form-group/form-group.component';
import { GridLayoutComponent } from './grid-layout/grid-layout.component';
import { IconsComponent } from './icons/icons.component';
import { InfoBoxComponent } from './info-box/info-box.component';
import { LeftMenuComponent } from './left-menu/left-menu.component';
import { LinkButtonComponent } from './link-button/link-button.component';
import { MapComponent } from './map/map.component';
import { ModalComponent } from './modal/modal.component';
import { NavigationHeaderComponent } from './navigation-header/navigation-header.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { PageTitleComponent } from './page-title/page-title.component';
import { ParagraphComponent } from './paragraph/paragraph.component';
import { RadioButtonGroupComponent } from './radio-button-group/radio-button-group.component';
import { ScssVariablesComponent } from './scss-variables/scss-variables.component';
import { SectionBarComponent } from './section-bar/section-bar.component';
import { SelectMultipleComponent } from './select-multiple/select-multiple.component';
import { SelectSingleComponent } from './select-single/select-single.component';
import { TableComponent } from './table/table.component';
import { TabsComponent } from './tabs/tabs.component';
import { TextAreaComponent } from './text-area/text-area.component';
import { TextFieldComponent } from './text-field/text-field.component';
import { TextFieldIconComponent } from './text-field-icon/text-field-icon.component';
import { TileButtonComponent } from './tile-button/tile-button.component';
import { ToggleComponent } from './toggle/toggle.component';
import { TooltipComponent } from './tooltip/tooltip.component';
import { UploadComponent } from './upload/upload.component';
import { HomeComponent } from './home/home.component';
import { NumericFieldComponent } from './numeric-field/numeric-field.component';
import { ImagesComponent } from './images/images.component';
import { TemplateComponent } from './template/template.component';

export const Routes = {
  home: '',

  accordion: 'accordion',
  alertMessage: 'alert-message',
  button: 'button',
  checkbox: 'checkbox',
  checkboxGroup: 'checkbox-group',
  contextMenu: 'context-menu',
  docs: 'docs',
  downloadFile: 'download-file',
  form: 'form',
  formField: 'form-field',
  formGroup: 'form-group',
  footer: 'footer',
  gridLayout: 'data-grid-layout',
  images: 'images',
  icons: 'icons',
  infoBox: 'info-box',
  leftMenu: 'left-menu',
  linkButton: 'link-button',
  map: 'map',
  modal: 'modal',
  navigationHeader: 'navigation-header',
  numericField: 'numeric-field',
  pageNotFound: 'page-not-found',
  pageTitle: 'page-title',
  paragraph: 'paragraph',
  radioButtonGroup: 'radio-button-group',
  scss: 'scss',
  sectionBar: 'section-bar',
  selectMultiple: 'selectMultiple',
  selectSingle: 'selectSingle',
  table: 'table',
  tabs: 'tabs',
  tabsChildren: {
    first: 'first',
    second: 'second',
    third: 'third',
  },
  template: 'template',
  textArea: 'text-area',
  textField: 'text-field',
  textFieldIcon: 'text-field-icon',
  tileButton: 'tile-button',
  toggle: 'toggle',
  tooltip: 'tooltip',
  upload: 'upload',
};

const routes = [
  { path: Routes.home, component: HomeComponent },

  // Global docs
  { path: Routes.docs, component: DocsComponent },
  // components
  { path: Routes.accordion, component: AccordionComponent },
  { path: Routes.alertMessage, component: AlertMessageComponent },
  { path: Routes.button, component: ButtonComponent },
  { path: Routes.checkbox, component: CheckboxComponent },
  { path: Routes.checkboxGroup, component: CheckboxGroupComponent },
  { path: Routes.contextMenu, component: ContextMenuComponent },
  { path: Routes.downloadFile, component: DownloadFileComponent },
  { path: Routes.footer, component: FooterComponent },
  { path: Routes.form, component: FormComponent },
  { path: Routes.formField, component: FormFieldComponent },
  { path: Routes.formGroup, component: FormGroupComponent },
  { path: Routes.gridLayout, component: GridLayoutComponent },
  { path: Routes.images, component: ImagesComponent },
  { path: Routes.icons, component: IconsComponent },
  { path: Routes.infoBox, component: InfoBoxComponent },
  { path: Routes.leftMenu, component: LeftMenuComponent },
  { path: Routes.linkButton, component: LinkButtonComponent },
  { path: Routes.map, component: MapComponent },
  { path: Routes.modal, component: ModalComponent },
  { path: Routes.navigationHeader, component: NavigationHeaderComponent },
  { path: Routes.numericField, component: NumericFieldComponent },
  { path: Routes.pageNotFound, component: PageNotFoundComponent },
  { path: Routes.pageTitle, component: PageTitleComponent },
  { path: Routes.paragraph, component: ParagraphComponent },
  { path: Routes.radioButtonGroup, component: RadioButtonGroupComponent },
  { path: Routes.scss, component: ScssVariablesComponent },
  { path: Routes.sectionBar, component: SectionBarComponent },
  { path: Routes.selectMultiple, component: SelectMultipleComponent },
  { path: Routes.selectSingle, component: SelectSingleComponent },
  { path: Routes.table, component: TableComponent },
  {
    path: Routes.tabs, component: TabsComponent, children: [
      { path: Routes.tabsChildren.first, component: TabsComponent },
      { path: Routes.tabsChildren.second, component: TabsComponent },
      { path: Routes.tabsChildren.third, component: TabsComponent },
      { path: '**', redirectTo: Routes.tabsChildren.first, }
    ]
  },
  { path: Routes.template, component: TemplateComponent },
  { path: Routes.textArea, component: TextAreaComponent },
  { path: Routes.textField, component: TextFieldComponent },
  { path: Routes.textFieldIcon, component: TextFieldIconComponent },
  { path: Routes.tileButton, component: TileButtonComponent },
  { path: Routes.toggle, component: ToggleComponent },
  { path: Routes.tooltip, component: TooltipComponent },
  { path: Routes.upload, component: UploadComponent },

  // 404
  { path: '**', component: PageNotFoundComponent },  // 404
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'top' })],
  exports: [RouterModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppRoutingModule { }
