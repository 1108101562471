
import { LitElement, svg, TemplateResult } from 'lit';
import { customElement } from 'lit/decorators.js';
import { BaseMdElement } from '../base-lit-element';
import { iconClass } from '../../directives/icon-class.directive';
import { BaseIcon } from './base-icon.mixin';

// keep in synk global.d.ts
@customElement('md-icon-edit')
export class IconEdit extends BaseIcon(BaseMdElement(LitElement)) {

  constructor() {
    super();

    this.smallSvgIcon = svg`
      <svg ${iconClass(this)} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
        <path d="M16.7,4.25a2.23,2.23,0,0,0-.88-1.49,2,2,0,0,0-2.74,0l-.9,1.05v0L5.39,12l.07.06L3.29,17.78l5.35-3.26,0,0,6.85-8.12h0l.84-1A1.6,1.6,0,0,0,16.7,4.25ZM5.25,15.41l1-2.74L7.8,13.86Zm3.3-2.23L6.82,11.81l5.5-6.59L14.1,6.59Z"/>
      </svg>`;

    this.largeSvgIcon = svg`
      <svg ${iconClass(this)} xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64">
        <path d="M52.4,13.77a6.7,6.7,0,0,0-2.56-4.44c-2.63-2.13-6.21-2.11-8,.05l-3.33,4,0,0L17.9,38.81l.08.07h0L11.57,56.26l15.75-9.83,0,0,20.8-25.29,0,0,3.28-4A4.56,4.56,0,0,0,52.4,13.77ZM17.07,46.59,19.79,49l-4.66,2.91ZM21.4,48l-3.65-3.22,1.72-4.67,6.35,5.13Zm5.69-4.08-6.61-5.34L38.91,15.9l6.78,5.37Z"/>
      </svg>`;
  }

  render(): TemplateResult {
    return this.renderIcon();
  }
}
