import { Component } from '@angular/core';
import { AbstractControl, FormControl, FormGroup } from '@angular/forms';
import { Toggle } from 'miljodir-wc/dist/src';
import { nameofFactory } from '../shared/helpers/nameof';

const nameof = nameofFactory<ToggleComponent>();

@Component({
  selector: 'app-toggle',
  templateUrl: './toggle.component.html',
  styleUrls: ['./toggle.component.scss']
})
export class ToggleComponent {

  form = new FormGroup({
    selected: new FormControl(true),
  });

  get selected() { return this.form.get(nameof('selected')) as AbstractControl; }

  onChanged($event: Event) {
    const ctrl = ($event.target as Toggle);
    console.log($event, ctrl.checked);
    this.selected.setValue(ctrl.checked);
  }

  handleClick(ev: Event) {
    console.log('Debug angular click event', (ev.currentTarget as Toggle)?.checked);
  }

}
