import { html, TemplateResult } from 'lit';
import { ifDefined } from 'lit/directives/if-defined.js';
import { unsafeHTML } from 'lit/directives/unsafe-html.js';
import { customElement, property, queryAssignedNodes } from 'lit/decorators.js';
import { ILitMandatoryFields } from '../../helpers/lit-mandatory-fields';
import { LitTooltipToggleEvent } from '../../helpers/events';
import { BaseLitElement } from '../base-lit-element';
import styles from './form-field-style.scss';
import '../tooltip/tooltip';
import '../icons/icon-help';

// keep in synk global.d.ts
@customElement('md-form-field')
export class FormField extends BaseLitElement implements ILitMandatoryFields {

  public static styles = styles;

  @property({ type: String }) for: string | null = null;

  @property({ type: String }) tooltip?: string;

  @property({ type: String, attribute: true, reflect: true })
  label = '';

  @property({ type: Boolean, attribute: true, reflect: true }) required = false;

  @property({ type: Boolean, attribute: true, reflect: true }) customTooltip = false;

  @queryAssignedNodes()
  _defaultSlotNodes?: HTMLElement[];

  update(changedProperties: Map<string | number | symbol, unknown>): void {
    this.checkProperties();

    super.update(changedProperties);
  }

  checkProperties(): void {
    // if (!this.label) {
    //   throw new PropertyRequiredError(this, nameof('label'));
    // }
  }

  _handleClick(ev: Event): void {
    ev.stopPropagation();
    if (this.for && this._defaultSlotNodes) {
      this._defaultSlotNodes.forEach(element => {
        if (element.id === this.for) {
          element.focus();
          return;
        }
      });
    }
  }

  _onTooltipToggle(ev: PointerEvent | KeyboardEvent): void {
    ev.stopPropagation();

    this.dispatchEvent(new LitTooltipToggleEvent());
  }

  render(): TemplateResult {
    return html`
      ${(this.label || this.tooltip) && html` <div class="label-wrapper">${this.label && html`<label
          @click="${this._handleClick}" for=${ifDefined(this.for === null ? undefined :
            this.for)}>${unsafeHTML(this.label)}</label>`}
        ${this.tooltip && !this.customTooltip ? html`
        <div>
          <md-icon-help class="size-m primary" id="questionIcon" aria-describedby="tooltip"></md-icon-help>
          <md-tooltip placement="auto" anchor="#questionIcon">${unsafeHTML(this.tooltip)}</md-tooltip>
        </div>` : null}
        ${this.customTooltip ? html`
        <md-icon-help tabindex="0" @click="${this._onTooltipToggle}"
           class="size-m primary pointer"
          aria-describedby="tooltip"></md-icon-help>
        ` : null}
      </div>`}

      <slot></slot>
    `;
  }
}
