import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MapMarkerLayer } from 'miljodir-wc/dist/src/index';

const initLayers: MapMarkerLayer[] = [
  {
    title: 'Anlegg',
    markers: [{
      x: '583165.23',
      y: '6630923.71',
      zone: '32',
      title: 'Marker 1'
    }]
  },
  {
    title: 'Utslippspunkt',
    markers: [{
      x: '583265.23',
      y: '6631923.71',
      zone: '32',
      title: 'Marker 1'
    },
    {
      x: '583220.59',
      y: '6631844.84',
      zone: '32',
      title: 'Marker 2'
    },
    {
      x: '583329.18',
      y: '6631949.10',
      zone: '32',
      title: 'Marker 3'
    }]
  },

];

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss']
})
export class MapComponent {

  form = new FormGroup({
    x: new FormControl('296921.38058783626', [Validators.required]),
    y: new FormControl('6698343.239375896', [Validators.required]),
    zone: new FormControl('32', [Validators.required]),
  });

  formNoInput = new FormGroup({
    x: new FormControl('', [Validators.required]),
    y: new FormControl('', [Validators.required]),
    zone: new FormControl('', [Validators.required]),
  });
  layers: MapMarkerLayer[] = initLayers;

  clearLayers() {
    this.layers = [];
  }

  addLayers() {
    if (!this.layers.length) {
      this.layers = initLayers;
    }
  }

  onChangeNoInput(event: Event) {
    console.log('Map change', event, (event.target as any).zone);
    this.formNoInput.controls['zone'].setValue((event.target as any).zone);
    this.formNoInput.controls['y'].setValue((event.target as any).y);
    this.formNoInput.controls['x'].setValue((event.target as any).x);
  }

  onChange(event: Event) {
    console.log('Map change', event, (event.target as any).zone);
    this.form.controls['zone'].setValue((event.target as any).zone);
    this.form.controls['y'].setValue((event.target as any).y);
    this.form.controls['x'].setValue((event.target as any).x);
  }

}
