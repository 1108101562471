
import { LitElement, svg, TemplateResult } from 'lit';
import { customElement } from 'lit/decorators.js';
import { BaseMdElement } from '../base-lit-element';
import { iconClass } from '../../directives/icon-class.directive';
import { BaseIcon } from './base-icon.mixin';

// keep in synk global.d.ts
@customElement('md-icon-minus')
export class IconMinus extends BaseIcon(BaseMdElement(LitElement)) {

  constructor() {
    super();

    this.smallSvgIcon = svg`
      <svg ${iconClass(this)} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
        <rect x="4.81" y="9.5" width="10.37" height="1"/>
      </svg>`;

    this.largeSvgIcon = svg`
      <svg ${iconClass(this)} xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64">
        <rect x="15.4" y="31" width="33.2" height="2"/>
      </svg>`;
  }

  render(): TemplateResult {
    return this.renderIcon();
  }
}
