
import '../icons/icon-logo';
import { html, TemplateResult } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { ifDefined } from 'lit/directives/if-defined.js';
import { BaseLitElement } from '../base-lit-element';
import { ILitMandatoryFields } from '../../helpers/lit-mandatory-fields';
import { nameofFactory } from '../../helpers/nameof';
import { PropertyRequiredError } from '../../helpers/property-required-error';
import styles from './footer.style.scss';

const nameof = nameofFactory<Footer>();

export type MdFooterLinks = {
  link: string,
  text: string
};

// keep in synk global.d.ts
@customElement('md-footer')
export class Footer extends BaseLitElement implements ILitMandatoryFields {
  public static styles = styles;

  @property({ type: Array, attribute: true, reflect: true })
  links: MdFooterLinks[] = [];

  @property({ type: String, attribute: true, reflect: true })
  logoLink: string | undefined;

  update(changedProperties: Map<string | number | symbol, unknown>): void {
    this.checkProperties();

    super.update(changedProperties);
  }

  checkProperties(): void {
    if (!this.logoLink) {
      throw new PropertyRequiredError(this, nameof('logoLink'), this.logoLink);
    }
  }

  renderLinks(links: MdFooterLinks[]): TemplateResult {
    return html`
    ${links.map((link, index) => {
      return html`<a tabindex="0" href="${link.link}" data-testid="link-${index}">${link.text}</a>`;
    })}
    `;
  }

  render(): TemplateResult {
    return html`
    <div class="grid wrapper">
      <div class="container">
        <a id="logoLink" tabindex="0" href="${ifDefined(this.logoLink)}" aria-label="footer-logo">
          <md-icon-logo></md-icon-logo>
        </a>
        <div class="container-links">
          ${this.renderLinks(this.links)}
        </div>
      </div>
    </div>
    `;
  }
}
