import { LitElement } from 'lit';
import { property } from 'lit/decorators.js';

export abstract class BaseLitElement extends LitElement {
  @property({ type: Boolean, attribute: true, reflect: true }) mdwebcomponents = true;
}

/*
* BaseLitElement as mixin
*/
type Constructor<T = LitElement> = new (...args: any[]) => T;

export declare class BaseMdElementInterface {
  mdwebcomponents: boolean;
}

export const BaseMdElement = <T extends Constructor<LitElement>>(superClass: T) => {
  class BaseMdElementClass extends superClass {
    @property({ type: Boolean, attribute: true, reflect: true })
    mdwebcomponents = true;
  }

  return BaseMdElementClass as Constructor<BaseMdElementInterface> & T;
};
