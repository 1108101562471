<md-page-title value="md-form-field | Form field"></md-page-title>

<div class="flex direction-column gap-4 p-3" [formGroup]="form">

  <md-form-field label="Empty | standalone"></md-form-field>

  <md-form-field for="customTooltip" label="Custom tooltip" (tooltipToggle)="onTooltipToggle($event)"
    [customTooltip]="true">
    <md-paragraph *ngIf="showTooltip" class="help" value="tooltip"></md-paragraph>
    <md-text-field formControlName="customTooltip" placeholder="|form content|" ngDefaultControl>
    </md-text-field>
  </md-form-field>

  <md-form-field for="firstName" [label]="labelUnsafe">
    |form content|
  </md-form-field>

  <md-form-field for="lastName" label="Tooltip" [tooltip]="tooltipText">
    |form content|
  </md-form-field>

  <md-form-field label="Required" required>
    |form content|
  </md-form-field>

  <md-form-field label="row-direction" class="row-direction">
    <md-text-field placeholder="|row-direction|"> </md-text-field>
  </md-form-field>
</div>
