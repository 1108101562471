import { html, TemplateResult } from 'lit';
import { customElement, property, query } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';
import { when } from 'lit/directives/when.js';
import { BaseLitElement } from '../base-lit-element';
import { ILitFocusable, canFocus } from '../focusable';
import { flattenElements } from '../../helpers/dom-query';
import styles from './accordion-card-style.scss';
import '../link-button/link-button';
import '../icons/icon-checkmark';
import '../icons/icon-minus';
import '../icons/icon-warning';
import '../icons/icon-plus';
import '../icons/icon-minus';

export type AccordionCardEvent = 'accordion-toggle-card' |
  'accordion-next-card' | 'accordion-previous-card' |
  'accordion-first-card' | 'accordion-last-card' |
  'tab-to-previous-page-element';

export class LitAccordionCardEvent extends CustomEvent<void> {
  public constructor(event: AccordionCardEvent) {
    super(event, { bubbles: true, composed: true });
  }
}

export type AccordionStatus = 'done' | 'error' | 'warning' | 'default'

type ElementFocusable = Element & {
  focus: (_options?: FocusOptions) => void
};

// keep in synk global.d.ts
@customElement('md-accordion-card')
export class AccordionCard extends BaseLitElement implements ILitFocusable {

  public static styles = styles;

  @property({ type: String, attribute: true, reflect: true }) header = '';
  @property({ type: Boolean, attribute: true, reflect: true }) expanded = false;
  @property({ type: String, attribute: true, reflect: true }) status: AccordionStatus = 'default';
  @property({ type: Boolean, attribute: true, reflect: true }) static = false;
  @property({ type: Number, attribute: true }) tabIndex = -1;

  // TODO: does destroyOnHidden attribute and functionality really gives any meaning?
  @property({ type: Boolean, attribute: true, reflect: true }) destroyOnHidden = false;

  @query('#header') _header!: HTMLElement;
  @query('#btn-close') _btnClose!: HTMLElement;

  /* I have removed this `focus` logic because I did not understand what was meant for,
   * but it has introduced a problem: an infinite loop inside an accordion-list that broke the UX.
   * Now it is possible to TAB and focus throught all the accordion elements and also go to the next card.
   * So if you need to re-introduce this event handler, test it carefully.
  */
  // connectedCallback(): void {
  //   super.connectedCallback();
  //   this.addEventListener('focus', this._forwardFocus);
  // }

  // _forwardFocus(event: FocusEvent): void {
  //   event.stopPropagation();
  //   event.preventDefault();
  //   this._header.focus();
  // }

  focus(options?: FocusOptions): void {
    this._header.focus(options);
  }

  _onToggle = (e: PointerEvent | KeyboardEvent): void => {
    e.stopPropagation();
    e.preventDefault();
    this.dispatchEvent(new LitAccordionCardEvent('accordion-toggle-card'));
  };

  _onClose = (e: PointerEvent | KeyboardEvent): void => {
    this._onToggle(e);

    // after the card has been closed, we want the focus to be on the title header, otherwise it "disappears".
    this._header.focus();
  };

  _onNextAccordionCard(e: KeyboardEvent): void {
    e.stopPropagation();
    e.preventDefault();
    this.dispatchEvent(new LitAccordionCardEvent('accordion-next-card'));
  }

  _onPreviousAccordionCard(e: KeyboardEvent): void {
    e.stopPropagation();
    e.preventDefault();
    this.dispatchEvent(new LitAccordionCardEvent('accordion-previous-card'));
  }

  _onFirstAccordionCard(e: KeyboardEvent): void {
    e.stopPropagation();
    e.preventDefault();
    this.dispatchEvent(new LitAccordionCardEvent('accordion-first-card'));
  }

  _onLastAccordionCard(e: KeyboardEvent): void {
    e.stopPropagation();
    e.preventDefault();
    this.dispatchEvent(new LitAccordionCardEvent('accordion-last-card'));
  }

  get _focusableItems(): ElementFocusable[] {
    const slot = this.shadowRoot?.querySelector('slot');
    const slottedElems = slot?.assignedElements({ flatten: false }) || [];

    const elems = flattenElements(slottedElems)
      .filter(node => canFocus(node))
      .map(x => x as ElementFocusable);
    return elems;
  }

  _onFocusPreviousPageElement(e: KeyboardEvent): void {
    e.stopPropagation();
    e.preventDefault();

    this.dispatchEvent(new LitAccordionCardEvent('tab-to-previous-page-element'));
  }

  _onFocusFirstElement(e: KeyboardEvent): void {
    e.stopPropagation();
    e.preventDefault();

    // make sure the accordion is expanded
    this.expanded = true;

    // find an element that can get focus
    const hasFocusableElements = (this._focusableItems?.length || 0) > 0;
    if (hasFocusableElements) {
      this._focusableItems[0].focus();
      return;
    }

    // otherwise skip to the close button
    if (this._btnClose) {
      this._btnClose?.focus();
      return;
    }

    // otherwise skip to the next card
    this.dispatchEvent(new LitAccordionCardEvent('accordion-next-card'));
  }

  _onKeyDown(e: KeyboardEvent): void {
    switch (e.key) {
      case 'ArrowDown':
        this._onNextAccordionCard(e);
        break;

      case 'ArrowUp':
        this._onPreviousAccordionCard(e);
        break;

      case 'Home':
        this._onFirstAccordionCard(e);
        break;

      case 'End':
        this._onLastAccordionCard(e);
        break;

      case 'Tab':
        if (e.shiftKey) {
          this._onFocusPreviousPageElement(e);
        } else {
          this._onFocusFirstElement(e);
        }
        break;

      case 'Enter':
        this._onToggle(e);
        break;

      default:
      // nop
    }
  }

  render(): TemplateResult {
    return html`
    <div id="container" class="wrapper ${classMap({ expanded: this.expanded, done: this.status === 'done' })}">
      <div class="acc-header-wrapper">
        <div id="header" class="acc-header ${classMap({ static: this.static, expanded: this.expanded })}" type="button"
          tabindex="${!this.static ? 0 : -1}" @click="${this._onToggle}" @keydown="${this._onKeyDown}">
          ${when(this.header, () => html`
          <md-icon-plus class="size-m"></md-icon-plus>
          <md-icon-minus class="size-m"></md-icon-minus>
          <h3>
            ${this.header}
          </h3>`)}
        </div>
    
        ${when(this.status === 'done', () => html`
        <div class="status-content"><span>Fullført</span>
          <md-icon-checkmark class="size-m"></md-icon-checkmark>
        </div>`)}
    
        ${when(this.status === 'warning' || this.status === 'error', () => html`
        <md-icon-warning class="size-m ${classMap({ warning: this.status === 'warning', error: this.status === 'error' })}">
        </md-icon-warning>`)}
      </div>
    
      ${when(!this.destroyOnHidden || this.static || this.expanded, () => html`
      <div .hidden="${!this.static && !this.expanded}">
        <div class="acc-content">
          <slot></slot>
          ${when(!this.static, () => html`
          <md-link-button id="btn-close" tabindex="0" @click="${this._onClose}"
            @keydown="${(e: KeyboardEvent) => e.key === 'Enter' && this._onClose(e)}">
            <md-icon-minus class="primary size-s"></md-icon-minus>
            <span>Lukk</span>
          </md-link-button>`)}
        </div>
      </div>`)}
    </div>`;
  }
}
