import { Component } from '@angular/core';

@Component({
  selector: 'app-navigation-header',
  templateUrl: './navigation-header.component.html',
  styleUrls: ['./navigation-header.component.scss']
})
export class NavigationHeaderComponent {

  constructor() { }

  explanation = `Since different applications have different layout, the developer decides the width of the container. Often the
  applikasjonen use some sort of grid layout.
  Below is an example for grid layout, and another one where you simply override the left and right padding of the md-navigation-header
  (<em>--nav-padding-left</em> and <em>--nav-padding-right</em>)`;

  gridExplanation = `Since different applications have different layout, the developer decides the width of the container. Often the
  applikasjonen use some sort of grid layout.
  Below is an example for grid layout, and another one where you simply override the left and right padding of the md-navigation-header
  (<em>--nav-padding-left</em> and <em>--nav-padding-right</em>)

  For the responsive example, one note is to set the wrapping background and the md-navigation-header background
  (<em>--nav-background-color</em>) to the same value.`;

  codeExample = `.nav-bar-background {
    background: $color-primary-10;
    --nav-background-color: $color-primary-10;
    border-top: 1px solid $color-border-variant;
    --nav-padding-right: 0;
    --nav-padding-left: 0;
  }`;

  codeBadExample = `.nav-bar-background {
    background: $color-orange-dark;
    border-top: 1px solid $color-border-variant;
    --nav-padding-right: 0;
    --nav-padding-left: 0;
  }`;

  codeExamplePadding = `{
  --nav-padding-right: 150px;
  --nav-padding-left: 150px;
  }`;
}
