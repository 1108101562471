import { LitElement, svg, TemplateResult } from 'lit';
import { customElement } from 'lit/decorators.js';
import { BaseMdElement } from '../base-lit-element';
import { iconClass } from '../../directives/icon-class.directive';
import { BaseIcon } from './base-icon.mixin';

// keep in synk global.d.ts
@customElement('md-icon-cancel')
export class IconCancel extends BaseIcon(BaseMdElement(LitElement)) {

  constructor() {
    super();

    // src\assets\ikoner\20px\icon_20px_close_circle.svg
    this.smallSvgIcon = svg`
      <svg ${iconClass(this)} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
        <g>
          <polygon points="12.92 6.37 10 9.29 7.08 6.37 6.37 7.08 9.29 10 6.37 12.92 7.08 13.63 10 10.71 12.92 13.63 13.63 12.92 10.71 10 13.63 7.08 12.92 6.37"/>
          <path d="M10,2.16A7.84,7.84,0,1,0,17.84,10,7.85,7.85,0,0,0,10,2.16Zm0,14.68A6.84,6.84,0,1,1,16.84,10,6.85,6.85,0,0,1,10,16.84Z"/>
        </g>
      </svg>`;

    // src\assets\ikoner\64px\64px_cancel.svg
    this.largeSvgIcon = svg`
      <svg ${iconClass(this)} xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64">
        <g>
          <polygon points="41.76 20.82 32 30.59 22.24 20.82 20.82 22.24 30.59 32 20.82 41.76 22.24 43.18 32 33.41 41.76 43.18 43.18 41.76 33.41 32 43.18 22.24 41.76 20.82"/>
          <path d="M32,7.53A24.47,24.47,0,1,0,56.47,32,24.49,24.49,0,0,0,32,7.53Zm0,46.94A22.47,22.47,0,1,1,54.47,32,22.5,22.5,0,0,1,32,54.47Z"/>
        </g>
      </svg>`;
  }

  render(): TemplateResult {
    return this.renderIcon();
  }
}
