import { LitElement, svg, TemplateResult } from 'lit';
import { customElement } from 'lit/decorators.js';
import { BaseMdElement } from '../base-lit-element';
import { iconClass } from '../../directives/icon-class.directive';
import { BaseIcon } from './base-icon.mixin';

// keep in synk global.d.ts
@customElement('md-icon-sub-menu')
export class IconSubMenu extends BaseIcon(BaseMdElement(LitElement)) {

  constructor() {
    super();

    this.smallSvgIcon = svg`
      <svg ${iconClass(this)} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
        <defs>
          <style>
            .cls-1 {
              fill: none;
              stroke: #000;
              stroke-miterlimit: 10;
            }
          </style>
        </defs>
        <g>
          <circle id="Ellipse_77" data-name="Ellipse 77" class="cls-1" cx="10" cy="4.9" r="1.7"/>
          <circle id="Ellipse_78" data-name="Ellipse 78" class="cls-1" cx="10" cy="10" r="1.7"/>
          <circle id="Ellipse_79" data-name="Ellipse 79" class="cls-1" cx="10" cy="15.1" r="1.7"/>
        </g>
      </svg>`;

    this.largeSvgIcon = svg`
      <svg ${iconClass(this)} xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64">
        <g>
          <path d="M32,22.12a6.44,6.44,0,1,1,6.44-6.44A6.45,6.45,0,0,1,32,22.12Zm0-10.88a4.44,4.44,0,1,0,4.44,4.44A4.44,4.44,0,0,0,32,11.24Z"/>
          <path d="M32,38.44A6.44,6.44,0,1,1,38.44,32,6.45,6.45,0,0,1,32,38.44Zm0-10.88A4.44,4.44,0,1,0,36.44,32,4.44,4.44,0,0,0,32,27.56Z"/>
          <path d="M32,54.76a6.44,6.44,0,1,1,6.44-6.44A6.45,6.45,0,0,1,32,54.76Zm0-10.88a4.44,4.44,0,1,0,4.44,4.44A4.44,4.44,0,0,0,32,43.88Z"/>
        </g>
      </svg>`;
  }

  render(): TemplateResult {
    return this.renderIcon();
  }
}
