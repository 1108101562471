import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ActivationEnd, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Tab } from 'miljodir-wc/dist/src';
import { Observable } from 'rxjs';
import { filter, map, startWith, tap } from 'rxjs/operators';
import { Routes } from '../app-routing.module';

@UntilDestroy()
@Component({
  selector: 'app-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss']
})
export class TabsComponent implements OnInit {

  tabs: Tab[] = [
    { title: 'First', route: Routes.tabsChildren.first, active: false },
    { title: 'Second', route: Routes.tabsChildren.second, active: false },
    { title: 'Third', route: Routes.tabsChildren.third, active: false },
  ];

  textContent$ = new Observable<number>();

  constructor(
    private router: Router,
    private route: ActivatedRoute
  ) {
  }

  ngOnInit(): void {
    // Example of changing tab content, common use is also https://angular.io/guide/router#nesting-routes with use of nested <router-outlets></router-outlets>
    this.textContent$ = this.router.events.pipe(
      filter(e => e instanceof ActivationEnd),
      map(e => e as ActivationEnd),
      filter(e => {
        const path = e.snapshot?.routeConfig?.path;
        if (!path) {
          return false;
        }
        const hasRoute = Object.keys(Routes.tabsChildren).includes(path);
        return hasRoute;
      }),
      map(e => e.snapshot.routeConfig?.path),
      startWith(Routes.tabsChildren.first),
      map((path) => {
        switch (path) {
          case Routes.tabsChildren.first:
            return 1;
          case Routes.tabsChildren.second:
            return 2;
          case Routes.tabsChildren.third:
            return 3;
        }
        return 0;
      }),
      tap(_ => {
        let currentPath = this.route.snapshot.firstChild?.routeConfig?.path;
        this.tabs = this.tabs.map(tab => {
          const active = tab.route === currentPath;
          return { ...tab, active };
        });
      }),
      untilDestroyed(this),
    );
  }

  handleClick(ev: Event) {
    const route = (ev.target as HTMLInputElement).value;
    console.log('Debug angular click event', ev);
    this.router.navigate([route], { relativeTo: this.route });
  }

}
