import { LitElement, svg, TemplateResult } from 'lit';
import { customElement } from 'lit/decorators.js';
import { BaseMdElement } from '../base-lit-element';
import { iconClass } from '../../directives/icon-class.directive';
import { BaseIcon } from './base-icon.mixin';

// keep in synk global.d.ts
@customElement('md-icon-pan')
export class IconPan extends BaseIcon(BaseMdElement(LitElement)) {

  constructor() {
    super();

    this.smallSvgIcon = svg`
      <svg ${iconClass(this)} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
        <path d="M13.47,5.22a1.5,1.5,0,0,0-.37,0V5.05a1.4,1.4,0,0,0-1.35-1.43,1.26,1.26,0,0,0-.51.11A1.34,1.34,0,0,0,10,2.94a1.37,1.37,0,0,0-1.26.91,1.18,1.18,0,0,0-.46-.09A1.39,1.39,0,0,0,7,5.19v2.6a1.24,1.24,0,0,0-1,0,1.31,1.31,0,0,0-.68,1.72l2.15,5.38a3.89,3.89,0,0,0,3.45,2.22A4,4,0,0,0,14.78,13l0-6.31A1.39,1.39,0,0,0,13.47,5.22Zm.31,7.72a3,3,0,0,1-2.89,3.12,2.89,2.89,0,0,1-2.54-1.63L6.22,9.1a.32.32,0,0,1,.12-.43h.08c.12,0,.25.1.33.3l.56.95h0L8.4,11.77h.29a1.52,1.52,0,0,1,1,.4,2,2,0,0,1,.47,1.43h1a2.88,2.88,0,0,0-.78-2.15A2.48,2.48,0,0,0,9,10.79l-.8-1.38L8,9V5.19a.39.39,0,0,1,.34-.43.4.4,0,0,1,.35.43V9.13l0,.5h1V4.37A.4.4,0,0,1,10,3.94a.39.39,0,0,1,.34.43V9.13l0,.5h1V5.05a.39.39,0,0,1,.34-.43.4.4,0,0,1,.35.43v4.1l0,.65h1V6.65a.4.4,0,0,1,.35-.43.39.39,0,0,1,.34.43Z"/>
      </svg>`;

    this.largeSvgIcon = svg`
      <svg ${iconClass(this)} xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64">
        <g>
          <path d="M35,43.52H33a6.78,6.78,0,0,0-1.68-5,5.63,5.63,0,0,0-3.52-1.46h-.58l-.3-.5-5-8.62,1.72-1,4.78,8.16a7.28,7.28,0,0,1,4.32,2A8.62,8.62,0,0,1,35,43.52Z"/>
          <path d="M34.84,54a11.84,11.84,0,0,1-10.49-6.76L17.47,30.06a3.81,3.81,0,0,1,0-2.76,3.34,3.34,0,0,1,1.9-2,3.46,3.46,0,0,1,3.59.71V16.61a3.85,3.85,0,0,1,3.7-4,3.54,3.54,0,0,1,1.86.54,3.66,3.66,0,0,1,7.17-.4,3.44,3.44,0,0,1,1.94-.59,3.85,3.85,0,0,1,3.72,4v1.64a3.45,3.45,0,0,1,1.77-.48,3.85,3.85,0,0,1,3.72,4L46.7,41.46C46.48,48.5,41.27,54,34.84,54ZM20.54,27.11a1.27,1.27,0,0,0-.47.09,1.38,1.38,0,0,0-.77.8,1.88,1.88,0,0,0,0,1.32l6.84,17.09A9.84,9.84,0,0,0,34.84,52c5.35,0,9.68-4.64,9.86-10.57l.11-20.15a1.73,1.73,0,1,0-3.42,0V31.33l-2,0-.06-2.1V16.15a1.73,1.73,0,1,0-3.42,0V30.22h-2l-.06-1V14a1.73,1.73,0,1,0-3.42,0V30.22h-2l-.06-1V16.61a1.86,1.86,0,0,0-1.71-2,1.85,1.85,0,0,0-1.7,2l0,12.38.76,1.47-1.75,1-1.85-3.18A1.72,1.72,0,0,0,20.54,27.11Z"/>
        </g>
      </svg>`;
  }

  render(): TemplateResult {
    return this.renderIcon();
  }
}
