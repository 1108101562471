import { html, TemplateResult } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { LitChangeEvent, LitInputEvent } from '../../helpers/events';
import { BaseLitElement } from '../base-lit-element';
import './checkbox';
import styles from './checkbox-group-style.scss';

export interface CheckBoxItemsProp {
  key: string;
  text: string;
  disabled?: boolean;
}

// keep in synk global.d.ts
@customElement('md-checkbox-group')
export class CheckboxGroup extends BaseLitElement {

  public static styles = styles;

  @property({ type: Array, attribute: true, reflect: true })
  items: Array<CheckBoxItemsProp> = [];

  @property({ type: Array, attribute: true, reflect: true }) value: string[] = [];

  @property({ type: String, attribute: true, reflect: true }) invalid?: string;

  _onChange(ev: Event): void {
    const dataValue = (ev.target as HTMLElement).getAttribute('data-value');
    if ((ev.target as HTMLInputElement).value && dataValue) {
      this.value = [...this.value.filter(x => x !== dataValue), dataValue];
    } else {
      this.value = this.value.filter(x => x !== dataValue);
    }

    this.dispatchEvent(new LitChangeEvent());
    this.dispatchEvent(new LitInputEvent());
  }

  render(): TemplateResult {
    return html`
      ${this.items.map(item => html`<md-checkbox class="fit-content" .value="${this.value.includes(item.key)}"
        @change="${this._onChange}" data-value="${item.key}" ?disabled="${item.disabled}">${item.text}</md-checkbox> `)}
      ${this.invalid && html`<span class="alert-text">${this.invalid}</span>`}
      `;
  }
}
