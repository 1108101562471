import { Component } from '@angular/core';

@Component({
  selector: 'app-form-group',
  templateUrl: './form-group.component.html',
  styleUrls: ['./form-group.component.scss']
})
export class FormGroupComponent {

  constructor() { }

  modalOpen = false;

  onDelete(ev: Event) {
    console.log('Delete called', ev);
  }

}
