import { html, TemplateResult } from 'lit';
import { BaseLitElement } from '../base-lit-element';
import { LitChangeEvent, LitInputEvent } from '../../helpers/events';
import { customElement, property, query, state } from 'lit/decorators.js';
import { ILitFocusable } from '../focusable';
import styles from './checkbox-style.scss';

// keep in synk global.d.ts
@customElement('md-checkbox')
export class Checkbox extends BaseLitElement implements ILitFocusable {

  public static styles = styles;

  @property({ type: String }) label?: string;
  // TODO: CheckProperty for value, if other than boolean throw error?
  @property({ type: Boolean, attribute: true, reflect: true }) value = false;
  @property({ type: Boolean, attribute: true, reflect: true }) hideBox = false;
  @property({ type: Boolean, attribute: true, reflect: true }) disabled = false;

  @state()
  _initTabIndex = 0;

  @query('input', true)
  _input!: HTMLElement;

  connectedCallback(): void {
    super.connectedCallback();
    this._initTabIndex = this.tabIndex;
    this.addEventListener('click', this._handleClick);
  }

  update(changedProperties: Map<string | number | symbol, unknown>): void {
    super.update(changedProperties);

    if (this.disabled) {
      this.tabIndex = -1;
    }
  }

  focus(options?: FocusOptions): void {
    this.renderRoot?.querySelector('input')?.focus(options);
  }

  _onChange(event: Event): void {
    this.value = (event.target as HTMLInputElement).checked;
    event.preventDefault();
    event.stopPropagation();

    this.dispatchEvent(new LitInputEvent());
    this.dispatchEvent(new LitChangeEvent());
  }

  handleKeyup(event: KeyboardEvent): void {
    const { code } = event;
    switch (code) {
      case 'Enter':
      case 'Space':
        event.preventDefault();
        (event.target as HTMLInputElement).checked = !(event.target as HTMLInputElement).checked;
        this._onChange(event);
        break;
      default:
        break;
    }
  }

  _handleClick(ev: Event) {
    ev.preventDefault();
    if (this.disabled) {
      return;
    }
    (this._input as HTMLInputElement).checked = !(this._input as HTMLInputElement).checked;
    this._input.dispatchEvent(new Event('input', {
      bubbles: true,
      cancelable: true,
    }));
  }

  render(): TemplateResult {
    return html`
    <label class="b-contain" ?hideBox="${this.hideBox}">
      <input type="checkbox" .checked="${this.value}" @input="${this._onChange}" @keyup="${this.handleKeyup}"
        .disabled="${this.disabled}">
      <slot></slot>
      <div class="b-input" ?hidden="${this.hideBox}"></div>
    </label>
      `;
  }
}
