import { html, TemplateResult } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';
import { ILitMandatoryFields } from '../../helpers/lit-mandatory-fields';
import { nameofFactory } from '../../helpers/nameof';
import { PropertyRequiredError } from '../../helpers/property-required-error';
import { BaseLitElement } from '../base-lit-element';
import styles from './left-menu-item-style.scss';
import { MenuItem, MenuLink, SubMenuItem } from './left-menu';
import { LitChangeCustomEvent } from '../../helpers/events';
import '../icons/icon';

const nameof = nameofFactory<LeftMenuItem>();

/**
 * A component rendering a left menu item. For internal use only (`LeftMenu`).
 *
 * @fires LeftMenuItem#change
 */
// keep in synk global.d.ts
@customElement('md-left-menu-item')
export class LeftMenuItem extends BaseLitElement implements ILitMandatoryFields {
  private _collapsed = false;

  public static styles = styles;

  /**
   * A menu item of type {MenuItem}.
   * Can have secondary items (children).
   * */
  @property({ type: Object, attribute: true, reflect: true })
  item?: MenuItem;

  /** {boolean} Indicate if the menu item is collapsed. */
  @property({ type: Boolean, attribute: true, reflect: true })
  get collapsed(): boolean {
    return this._collapsed;
  }

  set collapsed(value: boolean) {
    const oldValue = this._collapsed;
    this._collapsed = value;

    this.requestUpdate(nameof('collapsed'), oldValue);
  }

  @property({ type: Boolean, attribute: true, reflect: true })
  showChildren = false;

  update(changedProperties: Map<string | number | symbol, unknown>): void {
    this.checkProperties();

    super.update(changedProperties);
  }

  checkProperties(): void {
    if (!(this.item)) {
      throw new PropertyRequiredError(this, nameof('item'), this.item);
    }
  }

  private onItemClick(ev: MouseEvent, item: MenuItem) {
    ev.preventDefault();
    ev.stopPropagation();

    if (!item) {
      return;
    }

    if (!item.url && (item.subItems || []).length > 0) {
      // item has no url, then it's a toggle
      this.toggleShowChildren(ev);
      return;
    }

    this.onChange(item);
  }

  private onSubItemClick(ev: MouseEvent, item: SubMenuItem) {
    ev.preventDefault();
    ev.stopPropagation();

    if (!item || !item.url) {
      return;
    }

    this.onChange(item);
  }

  private onChange(item: MenuLink) {
    this.dispatchEvent(new LitChangeCustomEvent<string>(item.url));
  }

  private toggleShowChildren(ev: MouseEvent) {
    ev.preventDefault();
    ev.stopPropagation();

    this.showChildren = !this.showChildren;
    this.requestUpdate();
  }

  render(): TemplateResult {
    if (!this.item) {
      return html``;
    }

    const toggleChildren = (this.item.subItems?.length || 0) > 0
      ? html`
      <span class="toggle-children">
        <md-icon icon="${this.showChildren ? 'md-icon-minus' : 'md-icon-plus'}" class="size-s pointer"
          @click="${(e: MouseEvent) => this.toggleShowChildren(e)}"></md-icon>
      </span>`
      : undefined;

    const subItems = this.showChildren
      ? this.item.subItems?.map(subItem => html`
          <li class="subitem ${classMap({ 'collapsed': this._collapsed, 'active': subItem.active || false })}">
            <a href="${subItem.url || ''}" ?disabled="${subItem.disabled}"
              @click="${(e: MouseEvent) => this.onSubItemClick(e, subItem)}">
              <span>
                ${subItem.text}
              </span>
            </a>
          </li>
          `)
      : undefined;

    return html`
        <div
          class="item ${classMap({ 'collapsed': this._collapsed, 'active': this.item.active || false, 'children': this.showChildren })}">
          <div ?disabled="${this.item.disabled}">
            <a class="item-content" href="${this.item.url || ''}"
              @click="${(e: MouseEvent) => this.item && this.onItemClick(e, this.item)}">
              <md-icon icon="${this.item.icon}" class="size-s"></md-icon>
              <span class="text">
                ${this.item.text}
              </span>
        
              ${toggleChildren}
            </a>
        
            ${!subItems ? '' : html`
            <ul class="subitems">
              ${subItems}
            </ul>
            `}
          </div>
        </div>
    `;
  }
}
