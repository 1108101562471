import { Component } from '@angular/core';
import { allAvailableIcons } from 'miljodir-wc/dist/src/components/icons/icons';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent {
  allAvailableIcons = allAvailableIcons;
}
