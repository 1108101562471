import { html, TemplateResult } from 'lit';
import styles from './download-file-style.scss';
import { LitClickEvent, LitDeleteEvent } from '../../helpers/events';
import { prettyBytes } from '../../helpers/pretty-bytes';
import { classMap } from 'lit/directives/class-map.js';
import { ILitMandatoryFields } from '../../helpers/lit-mandatory-fields';
import { PropertyRequiredError } from '../../helpers/property-required-error';
import { nameofFactory } from '../../helpers/nameof';
import { customElement, property } from 'lit/decorators.js';
import { BaseLitElement } from '../base-lit-element';
import { ILitFocusable } from '../focusable';
import '../icons/icon-download';
import '../icons/icon-doc';
import '../icons/icon-trash-bin';

const nameof = nameofFactory<DownloadFile>();

// keep in synk global.d.ts
@customElement('md-download-file')
export class DownloadFile extends BaseLitElement implements ILitMandatoryFields, ILitFocusable {
  public static styles = [styles];

  @property({ type: String, attribute: true, reflect: true }) invalid?: string;
  @property({ type: Boolean, attribute: true, reflect: true }) readOnly = false;
  @property({ type: Boolean, attribute: true, reflect: true }) disabled = false;
  @property({ type: String, attribute: true, reflect: true }) value = ''; // (*) required
  @property({ type: Number, attribute: true, reflect: true }) size: number | null = null;
  @property({ type: String, attribute: true, reflect: true }) filename: string | null = null;

  update(changedProperties: Map<string | number | symbol, unknown>): void {
    this.checkProperties();

    super.update(changedProperties);
  }

  checkProperties(): void {
    if (!this.value) {
      throw new PropertyRequiredError(this, nameof('value'), this.value);
    }
  }

  focus(options?: FocusOptions): void {
    this.renderRoot?.querySelector('div')?.focus(options);
  }

  _onClick(e: PointerEvent | KeyboardEvent): void {
    // `click` is a bubbling event, so we re-throw a new event manually.
    e.stopPropagation();

    if (this.disabled) {
      return;
    }

    this.dispatchEvent(new LitClickEvent());
  }

  onRemoveFile(e: PointerEvent | KeyboardEvent): void {
    // `click` is a bubbling event, so we re-throw a new event manually.
    e.stopPropagation();

    if (this.readOnly || this.disabled) {
      return;
    }

    this.dispatchEvent(new LitDeleteEvent());
  }

  filesizeFormat = { minimumFractionDigits: 1, maximumFractionDigits: 1 };

  render(): TemplateResult {
    return html`
    <div role='button' id='container' tabindex='0' @click="${(e: PointerEvent) => this._onClick(e)}"
      ?disabled="${this.disabled}" @keydown="${(e: KeyboardEvent) => e.key === 'Enter' && this._onClick(e)}"
      ?readOnly="${this.readOnly}">
      <div id='doc-icon'>
        <md-icon-doc class="size-l "></md-icon-doc>
      </div>
      <div id='info'>
        <p id='text'>${this.filename || this.value}</p>
        <p id='size' class=${classMap({ 'hidden': !this.size })}>${prettyBytes(this.size || 0, this.filesizeFormat)}</p>
      </div>
      <div id='action'>
        <md-icon-trash-bin id="delete-icon" class="size-m success pointer"
          @click="${(e: PointerEvent) => this.onRemoveFile(e)}"
          @keydown="${(e: KeyboardEvent) => e.key === 'Enter' && this.onRemoveFile(e)}">
        </md-icon-trash-bin>
        <md-icon-download id="download-icon" class="size-m success pointer"
          @click="${(e: PointerEvent) => this._onClick(e)}"
          @keydown="${(e: KeyboardEvent) => e.key === 'Enter' && this._onClick(e)}">
        </md-icon-download>
      </div>
    </div>
    ${this.invalid && html`<span class="alert-text">${this.invalid}</span>`}
      `;
  }
}
