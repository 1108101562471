<md-page-title value="md-tabs | Tabs"></md-page-title>

<div class="flex direction-column gap-4 p-3">
  <md-tabs *ngIf="tabs.length" [tabs]="tabs" (click)="handleClick($event)"></md-tabs>

  <span class="tabcontent" *ngIf="(textContent$ | async) === 1">
    First tab content text
  </span>

  <span class="tabcontent" *ngIf="(textContent$ | async) === 2">
    Second tab content text
  </span>

  <span class="tabcontent" *ngIf="(textContent$ | async) === 3">
    Third tab content text
  </span>

</div>
