import { LitElement } from 'lit';

/**
 * Error thrown for a invalid property.
 */
export class PropertyInvalidError extends Error {
  public constructor(scope: LitElement, fieldName: string, value?: unknown) {
    const message = `[${scope.tagName}] Element with DOM id "${scope.id || '<undefined>'}" has property "${fieldName}" invalid (${JSON.stringify(value)} is invalid)`;
    super(message);
  }
}

export class PropertyMissmatchError extends Error {
  public constructor(scope: LitElement, target: string, source: string, targetValue?: unknown, sourceValue?: unknown) {
    const message = `[${scope.tagName}] Element with DOM id "${scope.id || '<undefined>'}" has property "${source}" (${JSON.stringify(sourceValue)}) mismatch against property "${target}" (${JSON.stringify(targetValue)})`;
    super(message);
  }
}
