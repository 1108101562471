<md-page-title value="md-section-bar | Section bar"></md-page-title>

<div class="flex direction-column gap-4 p-3" [formGroup]="form">
  <ul>
    <li>supports comment icon on section bar item</li>
    <li>supports disabled state globally, or for single section bar item</li>
  </ul>

  <ng-container *ngIf="items.length > 0">
    <md-section-bar id="section-bar-1" formControlName="currentPage" ngDefaultControl [items]="items"
      (input)="handleClick($event)">
    </md-section-bar>

    {{ form.value | json }}

    <h3>With message icon</h3>
    <md-section-bar id="section-bar-2" formControlName="currentPage" ngDefaultControl [items]="withMessage"
      (input)="handleClick($event)">
    </md-section-bar>

    <h3>With message icon, nr. messages, event handler</h3>
    <md-section-bar id="section-bar-2" formControlName="currentPage" ngDefaultControl [items]="withMessages"
      (input)="handleClick($event)" (messageClick)="onMessageClick($event)">
    </md-section-bar>

    <h3>Disabled</h3>
    <md-section-bar id="section-bar-3" [items]="items" (input)="handleClick($event)" disabled="true">
    </md-section-bar>

  </ng-container>


</div>
