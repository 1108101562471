import { Component } from '@angular/core';

interface TableVariant {
  name: string;
  classes: string;
}

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss']
})
export class TableComponent {

  variants: TableVariant[] = [
    { name: 'Table default (small, primary)', classes: 'table' },
    { name: 'Table medium (primary)', classes: 'table medium' },
    { name: 'Table large (primary)', classes: 'table large' },

    { name: 'Table secondary (small)', classes: 'table secondary' },
    { name: 'Table gray (small)', classes: 'table gray' },
  ];

}
