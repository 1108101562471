
import '../icons/icon-close-no-border'; import { html, TemplateResult } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { LitDeleteCustomEvent } from '../../helpers/events';
import { BaseLitElement } from '../base-lit-element';
import styles from './chips-style.scss';
import { classMap } from 'lit/directives/class-map.js';

export interface ChipItemProp {
  key: string;
  text: string;
  disabled?: boolean;
}

interface IProps {
  items: ChipItemProp[]
}

// keep in synk global.d.ts
@customElement('md-chips')
export class Chips extends BaseLitElement implements IProps {

  public static styles = styles;

  @property({ type: Array })
  items: ChipItemProp[] = [];

  _onDelete(item: ChipItemProp): void {
    if (item.disabled) {
      return;
    }
    this.dispatchEvent(new LitDeleteCustomEvent(item.key));
  }

  render(): TemplateResult {
    return html`
        ${this.items.map(item => html`
        <div class="chip ${classMap({ 'disabled': !!item.disabled })}"><span>${item.text}</span>
          <div class="delete-icon" tabIndex="${item.disabled ? -1 : 0}" @click="${() => this._onDelete(item)}"
            @keydown="${(e: KeyboardEvent) => e.key === 'Enter' && this._onDelete(item)}">
            <md-icon-close-no-border class="size-xs"></md-icon-close-no-border>
          </div>
        </div>
        `)}`;
  }
}
