import { Component } from '@angular/core';
import { TileButtonSize } from 'miljodir-wc/dist/src/components/tile-button/tile-button';

@Component({
  selector: 'app-tile-button',
  templateUrl: './tile-button.component.html',
  styleUrls: ['./tile-button.component.scss']
})
export class TileButtonComponent {

  sizes: TileButtonSize[] = ['small', 'medium', 'large'];

  onClick(e: Event) {
    console.log('Tile button clicked', e);
  }
}
