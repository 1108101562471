<md-page-title value="Scss/css Template"></md-page-title>

<h3>Text</h3>

<ul>
  <li>
    <p>Default font as <em>OpenSans-Regular</em>, color <em>#222222</em>, size <em>24px</em></p>
  </li>
  <li>
    <a [appAutofocus]="true" href="vg.no">Focused elements have primary color outline</a>
  </li>
</ul>

<h3>Input element</h3>
<p>Placeholder as <em>italic</em>, no <em>margin</em>, <em>OpenSans-Regular</em> font.</p>
<input type="text" placeholder="placeholder" />
<br><br>

<h3>Links & languages</h3>

<p>
  Base on UU rules, we need to emphasize when a link will open in a new tab/page.<br>
  The language is detected by the
  <em>:lang()</em> css selector (<a href="https://developer.mozilla.org/en-US/docs/Web/CSS/:lang"
    target="_blank">ref</a>).
</p>
<ul>
  <li>
    <a [appAutofocus]="true" href="vg.no">A link that opens in the same tab</a>
  </li>
  <li>
    <a href="vg.no" target="_blank" lang="no">A link that opens in a new tab | lang: no</a>
  </li>
  <li>
    <a href="vg.no" target="_blank" lang="en">A link that opens in a new tab | lang: en</a>
  </li>
</ul>

<h3>H1..5 headers</h3>
<p>No margin, <em>Sofia Pro font</em>, line height.</p>

<h1>H1</h1>
<h2>H2</h2>
<h3>H3</h3>
<h4>H4</h4>
<h5>H5</h5>
