import { LitElement, svg, TemplateResult } from 'lit';
import { customElement } from 'lit/decorators.js';
import { BaseMdElement } from '../base-lit-element';
import { iconClass } from '../../directives/icon-class.directive';
import { BaseIcon } from './base-icon.mixin';

// keep in synk global.d.ts
@customElement('md-icon-comment')
export class IconComment extends BaseIcon(BaseMdElement(LitElement)) {

  constructor() {
    super();

    this.smallSvgIcon = svg`
      <svg ${iconClass(this)} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
        <g>
          <rect x="9.04" y="7.94" width="1.49" height="1.55"/>
          <rect x="6.06" y="7.94" width="1.49" height="1.55"/>
          <rect x="12.01" y="7.94" width="1.49" height="1.55"/>
          <path d="M17.48,4.58a2.38,2.38,0,0,0-.65-1.65,2.28,2.28,0,0,0-1.64-.7H4.81a2.32,2.32,0,0,0-2.3,2.35v8.13a2.32,2.32,0,0,0,2.3,2.35h8.66l2.29,2.41a1,1,0,0,0,.73.3,1,1,0,0,0,.37-.07,1,1,0,0,0,.63-.93Zm-3.59,9.48H4.81a1.32,1.32,0,0,1-1.3-1.35h0V4.58a1.32,1.32,0,0,1,1.3-1.35H15.19a1.32,1.32,0,0,1,1.29,1.35h0V16.77Z"/>
        </g>
      </svg>`;

    this.largeSvgIcon = svg`
      <svg ${iconClass(this)} xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64">
        <g>
          <path d="M23.84,24.13H18.89a1,1,0,0,0-1,1V30.3a1,1,0,0,0,1,1h4.95a1,1,0,0,0,1-1V25.13A1,1,0,0,0,23.84,24.13Zm-1,5.17H19.89V26.13h2.95Z"/>
          <path d="M33.73,24.13H28.79a1,1,0,0,0-1,1V30.3a1,1,0,0,0,1,1h4.94a1,1,0,0,0,1-1V25.13A1,1,0,0,0,33.73,24.13Zm-1,5.17H29.79V26.13h2.94Z"/>
          <path d="M54.58,13.93a5.4,5.4,0,0,0-5.3-5.49H14.71A5.42,5.42,0,0,0,9.4,14V41a5.6,5.6,0,0,0,1.54,3.88,5.21,5.21,0,0,0,3.78,1.63H44.53l8.35,8.71a1,1,0,0,0,.72.31,1,1,0,0,0,1-1Zm-8.9,30.91a1,1,0,0,0-.72-.3H14.72a3.17,3.17,0,0,1-2.33-1,3.57,3.57,0,0,1-1-2.5V14a3.42,3.42,0,0,1,3.31-3.51H49.28A3.41,3.41,0,0,1,52.58,14l0,38.12Z"/>
          <path d="M43.63,24.13h-5a1,1,0,0,0-1,1V30.3a1,1,0,0,0,1,1h5a1,1,0,0,0,1-1V25.13A1,1,0,0,0,43.63,24.13Zm-1,5.17h-3V26.13h3Z"/>
        </g>
      </svg>`;
  }

  render(): TemplateResult {
    return this.renderIcon();
  }
}
