
import { html, LitElement, TemplateResult } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { unsafeHTML } from 'lit/directives/unsafe-html.js';
import { PropertyMissmatchError } from '../../helpers/property-invalid-error';
import { ILitMandatoryFields } from '../../helpers/lit-mandatory-fields';
import { nameofFactory } from '../../helpers/nameof';
import { PropertyRequiredError } from '../../helpers/property-required-error';
import { allAvailableIcons, AvailableIcons } from './icons';
import { BaseMdElement } from '../base-lit-element';
import { attributesAsString } from '../../helpers/attributes.helper';
import { BaseIcon } from './base-icon.mixin';

const nameof = nameofFactory<Icon>();

// keep in synk global.d.ts
@customElement('md-icon')
export class Icon extends BaseIcon(BaseMdElement(LitElement)) implements ILitMandatoryFields {

  public static styles = [];

  @property({ type: String, attribute: true, reflect: true })
  icon?: AvailableIcons;

  update(changedProperties: Map<string | number | symbol, unknown>): void {
    this.checkProperties();

    super.update(changedProperties);
  }

  checkProperties(): void {
    if (!this.icon) {
      throw new PropertyRequiredError(this, nameof('icon'), this.icon);
    }

    const invalidIcon = !allAvailableIcons.includes(this.icon);
    if (invalidIcon) {
      throw new PropertyMissmatchError(this, 'icons', nameof('icon'), this.icon, allAvailableIcons);
    }
  }

  render(): TemplateResult {
    const childAttributes = attributesAsString(this.attributes, [nameof('icon'), 'class']);

    const parentClass = this.attributes.getNamedItem('class');
    const childClass = parentClass?.value ? `class="${parentClass.value}"` : '';

    const tag = `<${this.icon} ${childClass} ${childAttributes} ></${this.icon}>`;

    return html`${unsafeHTML(tag)}`;
  }
}
