
import { html, TemplateResult } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { AvailableIcons } from '../icons/icons';
import { LitClickEvent } from '../../helpers/events';
import { BaseLitElement } from '../base-lit-element';
import { when } from 'lit/directives/when.js';
import { ifDefined } from 'lit/directives/if-defined.js';
import { PropertyRequiredError } from '../../helpers/property-required-error';
import { nameofFactory } from '../../helpers/nameof';
import { ILitMandatoryFields } from '../../helpers/lit-mandatory-fields';
import styles from './context-menu-item-style.scss';
import '../icons/icon';

const nameof = nameofFactory<ContextMenuItem>();

// keep in synk global.d.ts
@customElement('md-context-menu-item')
export class ContextMenuItem extends BaseLitElement implements ILitMandatoryFields {
  static styles = styles;

  @property({ type: Boolean, attribute: true, reflect: true }) disabled = false;
  @property({ type: String, attribute: true, reflect: true }) icon: AvailableIcons | undefined;
  @property({ type: String, attribute: true, reflect: true }) text: string | undefined;

  update(changedProperties: Map<string | number | symbol, unknown>): void {
    this.checkProperties();

    super.update(changedProperties);
  }

  checkProperties(): void {
    if (!this.text && !this.icon) {
      throw new PropertyRequiredError(this, `${nameof('text')} or ${nameof('icon')}`, { text: this.text, icon: this.icon });
    }
  }

  _handleClick(ev: Event): void {
    ev.preventDefault();
    ev.stopPropagation();

    if (this.disabled) {
      return;
    }

    console.log('clicked item', ev);
    this.dispatchEvent(new LitClickEvent());
  }

  renderIcon(icon: AvailableIcons | undefined): TemplateResult {
    return html`
          <div class="icon-container">
            ${when(icon, () => html`<md-icon icon="${ifDefined(icon)}" class="size-s"></md-icon>`, () => null)}
          </div>
    `;
  }

  renderText(text: string | undefined): TemplateResult {
    return html`
          <div class="text-container">
            ${when(text, () => html`<span>${text}</span>`, () => null)}
          </div>
    `;
  }

  render(): TemplateResult {
    return html`
    <div tabindex="1" @click="${this._handleClick}" class="container" ?disabled="${this.disabled}">
      ${this.renderIcon(this.icon)}
      ${this.renderText(this.text)}
    </div>`;
  }
}
