import { LitElement, svg, TemplateResult } from 'lit';
import { customElement } from 'lit/decorators.js';
import { BaseMdElement } from '../base-lit-element';
import { iconClass } from '../../directives/icon-class.directive';
import { BaseIcon } from './base-icon.mixin';

// keep in synk global.d.ts
@customElement('md-icon-sorting-active')
export class IconSortingActive extends BaseIcon(BaseMdElement(LitElement)) {

  constructor() {
    super();

    // src\assets\ikoner\20px\icon_20px_sorting_active.svg
    this.smallSvgIcon = svg`
      <svg ${iconClass(this)} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
        <g>
          <path d="M14.15,12.14l-3.79,4.07L6.29,12.14h7.86m.69-1H5.56a.7.7,0,0,0-.49,1.19l4.81,4.81a.7.7,0,0,0,.49.2.67.67,0,0,0,.51-.22l4.47-4.81a.69.69,0,0,0-.51-1.17Z"/>
          <g>
            <path d="M5.05,7.69,9.52,2.88a.69.69,0,0,1,1,0l4.81,4.81a.7.7,0,0,1-.49,1.19H5.56A.69.69,0,0,1,5.05,7.69Z"/>
            <path d="M10,3.79l4.07,4.07H6.25L10,3.79m0-1.13a.67.67,0,0,0-.51.22L5.05,7.69a.69.69,0,0,0,.51,1.17h9.28a.7.7,0,0,0,.49-1.19L10.52,2.86a.7.7,0,0,0-.49-.2Z"/>
          </g>
        </g>
      </svg>`;

    // Ser at sorting 64px ikke er med - MEN ser heller ingen grunn til å lage det - det BØR ikke forekomme noen bruk av så store storting icons i tabeller
    this.largeSvgIcon = this.smallSvgIcon;
  }

  render(): TemplateResult {
    return this.renderIcon();
  }
}
