
import { LitElement, svg, TemplateResult } from 'lit';
import { customElement } from 'lit/decorators.js';
import { BaseMdElement } from '../base-lit-element';
import { iconClass } from '../../directives/icon-class.directive';
import { BaseIcon } from './base-icon.mixin';

// keep in synk global.d.ts
@customElement('md-icon-home')
export class IconHome extends BaseIcon(BaseMdElement(LitElement)) {

  constructor() {
    super();

    this.smallSvgIcon = svg`
      <svg ${iconClass(this)} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
        <defs>
          <style>
            .cls-1 {
              fill: none;
              stroke: #000;
              stroke-linecap: round;
              stroke-linejoin: round;
            }
          </style>
        </defs>
        <g id="Home">
          <path id="Path_1947" data-name="Path 1947" class="cls-1" d="M10,2.85l-5.83,6v8.28h4V11.92h3.72v5.23h4V8.87Z"/>
        </g>
      </svg>`;

    this.largeSvgIcon = svg`
      <svg ${iconClass(this)} xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64">
        <g id="Home">
          <path d="M50.67,55.88H38a1,1,0,0,1-1-1V39.15H27V54.88a1,1,0,0,1-1,1H13.33a1,1,0,0,1-1-1V28.38a1,1,0,0,1,.28-.69L31.28,8.43a1,1,0,0,1,1.44,0L51.39,27.68a1,1,0,0,1,.28.7v26.5A1,1,0,0,1,50.67,55.88ZM39,53.88H49.67V28.78L32,10.56,14.33,28.79V53.88H25V38.15a1,1,0,0,1,1-1H38a1,1,0,0,1,1,1Z"/>
        </g>
      </svg>`;
  }

  render(): TemplateResult {
    return this.renderIcon();
  }
}
