<md-page-title value="md-download-file | Download file"></md-page-title>

<div class="flex direction-column gap-4 p-3">

  <h3>Download</h3>

  <md-form-field label="Vedlegg" [tooltip]="tooltipText">
    <md-download-file class="primary" [text]="downloaderText"
      value="https://www.miljodirektoratet.no/Assets/logo/new/logo-primary.svg" filename="logo-primary.svg" size="1024"
      (click)="downloadClick($event)" (delete)="deleteClick($event)"></md-download-file>
  </md-form-field>

  <md-download-file text="Vedlegg (secondary)" value="C:\\abc.pdf" filename="abc.pdf" (click)="downloadClick($event)"
    (delete)="deleteClick($event)">
  </md-download-file>

  <md-download-file text="Vedlegg (secondary)" value="C:\\abc.pdf" (click)="downloadClick($event)"
    (delete)="deleteClick($event)">
  </md-download-file>

  <h3>Disabled</h3>
  <md-download-file text="Vedlegg (disabled)" filename="abc.pdf" value="C:\\abc.pdf" size="102400" [disabled]="true"
    (click)="downloadClick($event)" (delete)="deleteClick($event)">
  </md-download-file>

  <h3>Invalid</h3>
  <md-download-file text="Vedlegg (disabled)" filename="abc.pdf" value="C:\\abc.pdf" size="102400" invalid="Error">
  </md-download-file>

  <h3>Read only</h3>
  <md-download-file [text]="downloaderText" filename="logo-primary.svg"
    value="https://www.miljodirektoratet.no/Assets/logo/new/logo-primary.svg" size="102400" [readOnly]="true"
    (click)="downloadClick($event)" (delete)="deleteClick($event)">
  </md-download-file>

</div>
