
import { html, TemplateResult } from 'lit';
import { customElement, property, query, state } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';
import { styleMap } from 'lit/directives/style-map.js';
import { Toggle } from '../toggle/toggle';
import { prettyBytes } from '../../helpers/pretty-bytes';
import { LitChangeEvent, LitAbortEvent } from '../../helpers/events';
import { nameofFactory } from '../../helpers/nameof';
import { BaseLitElement } from '../base-lit-element';
import { ILitFocusable } from '../focusable';
import styles  from './upload-style.scss';
import '../modal/modal';
import '../modal/modal-buttons';
import '../modal/modal-content';
import '../modal/modal-title';
import '../icons/icon-doc';
import '../icons/icon-upload';
import '../icons/icon-trash-bin';
import '../button/button';
import '../toggle/toggle';

const nameof = nameofFactory<Upload>();

// keep in synk global.d.ts
@customElement('md-upload')
export class Upload extends BaseLitElement implements ILitFocusable {

  public static styles = [styles];

  private _files: File[] = [];
  private _uploadedBytes = 0;

  @property({ type: String, attribute: true }) text = '';

  /** empty string or a list of file extensions, example `.jpg,.jpeg,.pdf` */
  @property({ type: String, attribute: true, reflect: true }) accept = '';

  @property({ type: Boolean, attribute: true, reflect: true }) disabled = false;

  @property({ type: Array })
  get files(): File[] {
    return this._files;
  }

  @property({ type: Number, attribute: true, reflect: true })
  set uploadedBytes(uploadedBytes: number) {
    const oldValue = this._uploadedBytes;
    this._uploadedBytes = uploadedBytes;

    if (uploadedBytes > 0 && uploadedBytes >= this.getFilesTotalSize()) {
      this.uploadDone();
    }
    
    this.requestUpdate(nameof('uploadedBytes'), oldValue);
  }

  get uploadedBytes(): number {
    return this._uploadedBytes;
  }

  @query('#inputfile', true)
  _input!: HTMLInputElement;

  @state()
  showModal = false;

  @state()
  showProgress = false;

  @state()
  authorized = false;

  @state()
  activeDrop = false;

  focus(options?: FocusOptions): void {
    this.renderRoot?.querySelector('div')?.focus(options);
  }

  onOpenModal(): void {
    if(this.disabled) {
      return;
    }

    this.showModal = true;
  }

  onCloseModal(): void {
    this.showModal = false;
    this.authorized = false;
    this._files = [];
    this.uploadedBytes = 0;
  }

  onAbortProgress(): void {
    this.showProgress = false;
    this.dispatchEvent(new LitAbortEvent());
  }

  authorize(authorized: boolean): void {
    this.authorized = authorized;
  }

  openFileDialog(): void {
    if (this.disabled) {
      return;
    }

    this._input.value = '';
    this._input.click();
  }

  // Drag & Drop implementation from
  // https://developer.mozilla.org/en-US/docs/Web/API/HTML_Drag_and_Drop_API/File_drag_and_drop
  fileDropHandler(ev: DragEvent): void {
    // Prevent default behavior (Prevent file from being opened)
    ev.preventDefault();

    if (!ev.dataTransfer) {
      return;
    }

    if (ev.dataTransfer.items) {
      // Use DataTransferItemList interface to access the file(s)
      for (let i = 0; i < ev.dataTransfer.items.length; i++) {
        // If dropped items aren't files, reject them
        if (ev.dataTransfer.items[i].kind === 'file') {
          const file = ev.dataTransfer.items[i].getAsFile();
          if (!file) {
            continue;
          }
          // console.log('... file[' + i + '].name = ' + file.name);
          this.tryAppendFile(file);
        }
      }
    } else {
      // Use DataTransfer interface to access the file(s)
      for (let i = 0; i < ev.dataTransfer.files.length; i++) {
        const file = ev.dataTransfer.files[i];
        // console.log('... file[' + i + '].name = ' + file.name);
        this.tryAppendFile(file);
      }
    }

    this.activeDrop = false;
  }

  fileDragOverHandler(ev: DragEvent): void {
    // Prevent default behavior (Prevent file from being opened)
    ev.preventDefault();
    this.activeDrop = true;
  }

  fileDragLeaveHandler(ev: DragEvent): void {
    // Prevent default behavior (Prevent file from being opened)
    ev.preventDefault();
    this.activeDrop = false;
  }

  tryAppendFile(file: File): void {
    if(this.accept === '') {
      this._files.push(file);
      return;
    }

    const ext = file.name.split('.').pop();
    const isValid = this.accept
    .split(',')
    .map(e => e.split('.').pop())
    .some(e => e === ext);
    if(!isValid) {
      // file skipped because the extension doesn't match `accept`
      return;
    }
    this._files.push(file);
  }

  onRemoveFile(file: File): void {
    this._files = this._files.filter(x => x.name !== file.name && x.size !== file.size);
    this.requestUpdate();
  }

  processFiles(): void {
    if (!this._files?.length) {
      return;
    }

    this.showProgress = true;
    this.dispatchEvent(new LitChangeEvent());
  }

  async onFileAdded(): Promise<void> {
    const fileList = this._input.files;
    if (!fileList?.length) {
      return;
    }

    for (let i = 0; i < fileList.length; i++) {
      const file = fileList[i];
      this.tryAppendFile(file);
    }

    await this.requestUpdate();
  }

  uploadDone(): void {
    this._files = [];
    this.uploadedBytes = 0;
    this.showProgress = false;
    this.showModal = false;
    this.authorized = false;
  }

  getFilesTotalSize = (): number => this._files.reduce((prev, curr) => prev + curr.size, 0);

  filesizeFormat = { minimumFractionDigits: 1, maximumFractionDigits: 1 };
  
  getProgressPercentage = (): number => Math.round(this.uploadedBytes / this.getFilesTotalSize() * 100);

  render(): TemplateResult {
    return html`
    <input type='file' id='inputfile' name='filename' .accept="${this.accept}" hidden multiple
      @change="${() => this.onFileAdded()}" />

    <div role='button' id='container' tabindex='0' @click="${() => this.onOpenModal()}"
      @keydown="${(e: KeyboardEvent) => e.key === 'Enter' && this.onOpenModal()}" ?disabled="${this.disabled}" aria-label="Velg filer">
      <span id='action-text'>${this.text}</span>
      <md-icon-upload class="size-m"></md-icon-upload>
    </div>

    <md-modal id="progress" class="size-m secondary" @close="${() => this.onAbortProgress()}" ?hidden="${!this.showProgress}" .open="${this.showProgress}">
      <md-modal-content>
        <div id="progress-files">
          <h4>Laster opp ${this._files.length} filer</h4>
          <p>${prettyBytes(this.getFilesTotalSize(), this.filesizeFormat)}</p>

          <div id="progress-bar">
            <div id="progress-value" style=${styleMap({ width: this.getProgressPercentage() + '%'})}>
            </div>
          </div>

        </div>
      </md-modal-content>
    </md-modal>

    <md-modal id="selection" class="size-m secondary" @close="${() => this.onCloseModal()}" ?hidden="${!this.showModal || this.showProgress}" .open="${this.showModal && !this.showProgress}">
      <md-modal-title>Last opp vedlegg</md-modal-title>

      <md-modal-content>
        <div id="files" class=${classMap({ 'active-drop' : this.activeDrop })} @drop="${this.fileDropHandler}"
            @dragover="${this.fileDragOverHandler}" @dragleave="${this.fileDragLeaveHandler}">
          <div id="drop-files" >
            <md-icon-upload class="size-m"></md-icon-upload>
            <p>Drop en fil eller <a role="button" tabindex="0" 
              @click="${() => this.openFileDialog()}" 
              @keydown="${(e: KeyboardEvent) => e.key === 'Enter' && this.openFileDialog()}"
              >velg fra denne maskinen</a>
            </p>
          </div>

          <div id="list" class=${classMap({ 'visible': this._files.length > 0 })}>
            ${this._files.map(file => html`<div class="added-file">
              <md-icon-doc class="size-m"></md-icon-doc>
              <span>${file.name}</span>
              <span class="file-size">
                ${prettyBytes(file.size, this.filesizeFormat)}</span>
                <div class="delete-wrapper">
                  <md-icon-trash-bin class="size-m success pointer" @click="${() => this.onRemoveFile(file)}">
                  </md-icon-trash-bin>
                </div>
            </div>`)}
          </div>
        </div>

        <div id="terms">
          <h4>Dokumenter som lastes opp i søknaden er tilgjengelig for offentligheten</h4>
          <p>Offentleglova inneholder bestemmelser om retten til å få se (innsyn) i dokumenter i offentlig forvaltning.
            Hovedregelen er
            at alle kan kreve innsyn i saksdokumenter, journaler og andre lignende registre.</p>
          <p><a href="https://lovdata.no/dokument/NL/lov/2006-05-19-16" target="_blank">Lenke til lovdata</a></p>
        </div>

        <p id="authorize">
           <md-toggle id="authorize-toggle" ?checked="${this.authorized}" @click="${(e: Event) => this.authorize((e.target as Toggle).checked)}">Jeg har forstått at dokumenter som lastes opp er offentlige</md-toggle>
        </p>
      </md-modal-content>

      <md-modal-buttons>
        <md-button id="cancel-button" class="secondary" @click="${() => this.onCloseModal()}">Avbryt</md-button>
        <md-button id="upload-button" ?disabled="${!this.authorized || this._files.length === 0}" @click="${() => this.processFiles()}">Legg ved
        </md-button>
      </md-modal-buttons>
    </md-modal>
      `;
  }
}
