export const queryParentRoots = <T>(element: HTMLElement, query: string): T[] => {
  // If a shadow root doesn't exist we don't continue the traversal
  if (element.shadowRoot == null) {
    return [];
  }
  // Grab the rood node and query it
  const $root = (<any>element.shadowRoot.host).getRootNode();
  const matches = $root.querySelectorAll(query);
  if (matches.length > 0) {
    return <T[]>Array.from(matches);
  }

  // We continue the traversal if there was not matches
  return queryParentRoots($root, query);
};

export const getAnchor = (query: string, element: HTMLElement): HTMLElement | undefined => {
  const matches = queryParentRoots<HTMLElement>(element, <string>query);
  return matches.length > 0 ? matches[0] : undefined;
};

/** Flatten a hierarchy of `Element`. */
export const flattenElements = (elements: Element[]): Element[] => {
  let flatten: Element[] = [];
  for (let i = 0; i < elements.length; i++) {
    const element = elements[i];
    const child = element.querySelectorAll('*');
    const descendants = Array.from(child);
    flatten = flatten.concat(element).concat(descendants);
  }
  return flatten;
};
