import { Component } from '@angular/core';

const testParagraphPlain = `Here we have an example of a plain paragraph. 

It supports new lines, but hey! It does not support anchor or other richtext elements.  <a href="http://www.google.com">doesn't work</a>, <b>nope it does not</b>`;

const testParagraphUnsafe = `Here we have an example of a rich paragraph. 

It supports new lines, and hey! It supports anchor and other richtext elements. <a href="http://www.google.com">This google link works</a>, <b>and bold text too</b>`;

@Component({
  selector: 'app-paragraph',
  templateUrl: './paragraph.component.html',
  styleUrls: ['./paragraph.component.scss']
})
export class ParagraphComponent {

  paragraphContentPlain = testParagraphPlain;
  paragraphContentUnsafe = testParagraphUnsafe;
  showTooltip = false;

}
