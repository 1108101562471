import { html, TemplateResult } from 'lit';
import { customElement, property, query, queryAssignedNodes, state } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';
import { when } from 'lit/directives/when.js';
import { BaseLitElement } from '../base-lit-element';
import { LitClickEvent } from '../../helpers/events';
import { ILitFocusable } from '../focusable';
import styles from './toggle-style.scss';

// keep in synk global.d.ts
@customElement('md-toggle')
export class Toggle extends BaseLitElement implements ILitFocusable {

  public static styles = styles;

  @property({ type: Boolean, attribute: true, reflect: true }) checked = false;

  @property({ type: Boolean, attribute: true, reflect: true }) disabled = false;
  @property({ type: String, attribute: true, reflect: true }) labelDirection: 'right' | 'left' = 'left';

  @query('#container', true)
  _el!: HTMLInputElement;

  @state()
  private hasSlottedContent = false;

  @queryAssignedNodes()
  _slottedNodes?: Node[];

  click(): void {
    if (this.disabled) {
      return;
    }

    this.checked = !this.checked;
    this.dispatchEvent(new LitClickEvent());
  }

  _onClick(ev: PointerEvent | KeyboardEvent): void {
    ev.preventDefault();
    ev.stopPropagation();

    this.click();
  }

  _slotChanged(_: Event) {
    this.hasSlottedContent = (this._slottedNodes?.length || 0) > 0;
  }

  focus(options?: FocusOptions): void {
    this._el.focus(options);
  }

  render(): TemplateResult {
    const hasSlottedContent = this.hasSlottedContent;
    const labelLeft = this.labelDirection !== 'right';
    const labelRight = this.labelDirection === 'right';

    return html`
    <label tabindex="${this.disabled ? -1 : 0}" id="container" @click="${this._onClick}"
      @keydown="${(e: KeyboardEvent) => e.key === 'Enter' && this._onClick(e)}" aria-label="toggle"
      class="${classMap({ 'slotted-content': hasSlottedContent, 'label-left': labelLeft, 'label-right': labelRight })}">
    
      ${when(labelLeft, () => html`<slot @slotchange=${this._slotChanged}></slot>`)}
    
      <div class="switch">
        <input tabindex="-1" type="checkbox" ?checked="${this.checked}" ?disabled="${this.disabled}"
          aria-labelledby="container">
        <span class="slider round"></span>
      </div>
    
      ${when(labelRight, () => html`<slot @slotchange=${this._slotChanged}></slot>`)}
    </label>
    `;
  }
}
