import { html, PropertyValues, TemplateResult } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { unsafeHTML } from 'lit/directives/unsafe-html.js';
import { BaseLitElement } from '../base-lit-element';
import { ILitMandatoryFields } from '../../helpers/lit-mandatory-fields';
import { nameofFactory } from '../../helpers/nameof';
import { PropertyRequiredError } from '../../helpers/property-required-error';
import styles from './info-box-style.scss';
import '../icons/icon-checkmark';
import '../icons/icon-info';
import '../icons/icon-warning';

const nameof = nameofFactory<InfoBox>();

// keep in synk global.d.ts
@customElement('md-info-box')
export class InfoBox extends BaseLitElement implements ILitMandatoryFields {

  public static styles = styles;

  @property({ type: String, attribute: true, reflect: true }) value = '';

  update(changedProperties: PropertyValues): void {
    this.checkProperties();

    super.update(changedProperties);
  }

  checkProperties(): void {
    if (!this.value) {
      throw new PropertyRequiredError(this, nameof('value'), this.value);
    }
  }

  render(): TemplateResult {
    return html`
    <md-icon-info id="infoIcon" class="icon size-m"></md-icon-info>
    <div>
      ${unsafeHTML(this.value)}
    </div>
    `;
  }
}
