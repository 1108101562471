
import { LitElement, svg, TemplateResult } from 'lit';
import { customElement } from 'lit/decorators.js';
import { BaseMdElement } from '../base-lit-element';
import { iconClass } from '../../directives/icon-class.directive';
import { BaseIcon } from './base-icon.mixin';

// keep in synk global.d.ts
@customElement('md-icon-cal')
export class IconCal extends BaseIcon(BaseMdElement(LitElement)) {

  constructor() {
    super();

    // src\assets\ikoner\20px\icon_20px_cal.svg
    this.smallSvgIcon = svg`
      <svg ${iconClass(this)} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
        <g>
          <g>
            <rect x="11.44" y="8.92" width="3.12" height="1.03"/>
            <rect x="11.44" y="12.38" width="3.12" height="1.03"/>
            <rect x="5.44" y="8.92" width="3.12" height="1.03"/>
            <rect x="5.44" y="12.38" width="3.12" height="1.03"/>
          </g>
          <path d="M17.54,4.31H15.42V3.45h-1v.86H5.58V3.45h-1v.86H2.41V16.55H17.59V4.31ZM3.41,15.55V7.33H16.59v8.22Z"/>
        </g>
      </svg>`;

    // src\assets\ikoner\64px\64px_cal.svg
    this.largeSvgIcon = svg`
      <svg ${iconClass(this)} xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64">
        <g>
          <rect x="16.26" y="29.5" width="7.56" height="2"/>
          <rect x="28.22" y="29.5" width="7.56" height="2"/>
          <rect x="40.18" y="29.5" width="7.56" height="2"/>
          <rect x="16.26" y="37.15" width="7.56" height="2"/>
          <rect x="28.22" y="37.15" width="7.56" height="2"/>
          <rect x="40.18" y="37.15" width="7.56" height="2"/>
          <rect x="16.26" y="44.81" width="7.56" height="2"/>
          <rect x="28.22" y="44.81" width="7.56" height="2"/>
          <rect x="40.18" y="44.81" width="7.56" height="2"/>
          <path d="M48.74,14.74V11.38h-2v3.36H17.26V11.38h-2v3.36H8.32v38H55.68v-38Zm-38.42,36V23.2H53.68V50.71Z"/>
        </g>
      </svg>`;
  }

  render(): TemplateResult {
    return this.renderIcon();
  }
}
