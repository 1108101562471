
import { LitElement, svg, TemplateResult } from 'lit';
import { customElement } from 'lit/decorators.js';
import { BaseMdElement } from '../base-lit-element';
import { iconClass } from '../../directives/icon-class.directive';
import { BaseIcon } from './base-icon.mixin';

// keep in synk global.d.ts
@customElement('md-icon-checkmark-success')
export class IconCheckmarkSuccess extends BaseIcon(BaseMdElement(LitElement)) {

  constructor() {
    super();

    this.smallSvgIcon = svg`
      <svg ${iconClass(this)} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
        <polygon points="13.95 7.29 13.17 6.66 8.7 12.17 6.86 9.69 6.05 10.28 8.66 13.8 13.95 7.29"/>
        <path d="M2.17,2.16V17.81H17.83V2.16ZM16.83,16.81H3.17V3.16H16.83Z"/>
      </svg>`;

    this.largeSvgIcon = svg`
      <svg ${iconClass(this)} xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64">
        <g>
          <polygon points="28.68 43.25 20.79 32.59 22.4 31.41 28.75 39.99 43.53 21.75 45.09 23.01 28.68 43.25"/>
          <path d="M56.44,56.44H7.56V7.56H56.44Zm-46.88-2H54.44V9.56H9.56Z"/>
        </g>
      </svg>`;
  }

  render(): TemplateResult {
    return this.renderIcon();
  }
}
