import { LitElement, svg, TemplateResult } from 'lit';
import { customElement } from 'lit/decorators.js';
import { BaseMdElement } from '../base-lit-element';
import { iconClass } from '../../directives/icon-class.directive';
import { BaseIcon } from './base-icon.mixin';

// keep in synk global.d.ts
@customElement('md-icon-close-no-border')
export class IconCloseNoBorder extends BaseIcon(BaseMdElement(LitElement)) {

  constructor() {
    super();

    this.smallSvgIcon = svg`
      <svg ${iconClass(this)} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
        <polygon points="17.49 3.21 16.78 2.51 10 9.29 3.22 2.51 2.51 3.21 9.29 10 2.51 16.79 3.22 17.49 10 10.71 16.78 17.49 17.49 16.79 10.71 10 17.49 3.21"/>
      </svg>`;

    this.largeSvgIcon = svg`
      <svg ${iconClass(this)} xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64">
        <polygon points="56.01 8.53 54.57 7.14 32 30.56 9.43 7.14 7.99 8.53 30.61 32 7.99 55.47 9.43 56.85 32 33.44 54.57 56.85 56.01 55.47 33.39 32 56.01 8.53"/>
      </svg>`;
  }

  render(): TemplateResult {
    return this.renderIcon();
  }
}
