
import { LitElement, svg, TemplateResult } from 'lit';
import { customElement } from 'lit/decorators.js';
import { BaseMdElement } from '../base-lit-element';
import { iconClass } from '../../directives/icon-class.directive';
import { BaseIcon } from './base-icon.mixin';

// keep in synk global.d.ts
@customElement('md-icon-help')
export class IconHelp extends BaseIcon(BaseMdElement(LitElement)) {

  constructor() {
    super();

    this.smallSvgIcon = svg`
      <svg ${iconClass(this)} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
        <g>
          <path d="M10,2.15A7.84,7.84,0,1,0,17.84,10,7.84,7.84,0,0,0,10,2.15Zm0,14.67A6.84,6.84,0,1,1,16.84,10,6.85,6.85,0,0,1,10,16.82Z"/>
          <g>
            <path d="M10.06,5.56A2.62,2.62,0,0,0,7.4,8.24H8.47a1.62,1.62,0,0,1,1.59-1.63,1.35,1.35,0,0,1,1.41,1.46A1.55,1.55,0,0,1,10.4,9.51L10,9.66a1.72,1.72,0,0,0-1.29,1.88v.52h1.1v-.52c0-.62.39-.8.93-1a2.62,2.62,0,0,0,1.88-2.5A2.43,2.43,0,0,0,10.06,5.56Z"/>
            <path d="M9.22,13.17a.79.79,0,1,0,.83.79A.8.8,0,0,0,9.22,13.17Z"/>
          </g>
        </g>
      </svg>`;

    this.largeSvgIcon = svg`
      <svg ${iconClass(this)} xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64">
        <g>
          <path d="M32,7.53A24.47,24.47,0,1,0,56.47,32,24.49,24.49,0,0,0,32,7.53Zm0,46.94A22.47,22.47,0,1,1,54.47,32,22.5,22.5,0,0,1,32,54.47Z"/>
          <path d="M29.52,42.2a2.52,2.52,0,1,0,2.65,2.52A2.56,2.56,0,0,0,29.52,42.2Z"/>
          <path d="M32.21,17.86a8.39,8.39,0,0,0-8.53,8.57h3.44a5.15,5.15,0,0,1,5.09-5.22,4.33,4.33,0,0,1,4.51,4.68,5,5,0,0,1-3.44,4.59l-1.4.5c-2.45.91-4.22,2.28-4.14,6l0,1.66H31.3l0-1.66c0-2,1.24-2.56,3-3.1a8.4,8.4,0,0,0,6-8A7.78,7.78,0,0,0,32.21,17.86Z"/>
        </g>
      </svg>`;
  }

  render(): TemplateResult {
    return this.renderIcon();
  }
}
