import { Component } from '@angular/core';

@Component({
  selector: 'app-info-box',
  templateUrl: './info-box.component.html',
  styleUrls: ['./info-box.component.scss']
})
export class InfoBoxComponent {
  longText = `Here we have an example of a rich infobox.
  It supports new lines, and hey! It supports anchor and other richtext elements. <a href="http://www.google.com">This google link works</a>, <b>and bold text too</b>`;
}
