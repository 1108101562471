import { LitElement, svg, TemplateResult } from 'lit';
import { customElement } from 'lit/decorators.js';
import { BaseMdElement } from '../base-lit-element';
import { iconClass } from '../../directives/icon-class.directive';
import { BaseIcon } from './base-icon.mixin';

// keep in synk global.d.ts
@customElement('md-icon-person')
export class IconPerson extends BaseIcon(BaseMdElement(LitElement)) {

  constructor() {
    super();

    // src\assets\ikoner\20px\icon_20px_people.svg
    this.smallSvgIcon = svg`
      <svg ${iconClass(this)} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
        <g>
          <path d="M10,8.24a2.89,2.89,0,1,1,2.89-2.89A2.9,2.9,0,0,1,10,8.24Zm0-4.78a1.89,1.89,0,1,0,1.89,1.89A1.89,1.89,0,0,0,10,3.46Z"/>
          <path d="M14.54,17.54H5.46L5.5,13A4.45,4.45,0,0,1,10,8.64,4.46,4.46,0,0,1,14.54,13Zm-8.07-1h7.07V13A3.47,3.47,0,0,0,10,9.64,3.45,3.45,0,0,0,6.5,13Z"/>
        </g>
      </svg>`;

    // src\assets\ikoner\64px\64px_person.svg
    this.largeSvgIcon = svg`
      <svg ${iconClass(this)} xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64">
        <g>
          <path d="M32,25.77a8.65,8.65,0,1,1,8.65-8.65A8.66,8.66,0,0,1,32,25.77Zm0-15.29a6.65,6.65,0,1,0,6.65,6.64A6.65,6.65,0,0,0,32,10.48Z"/>
          <path d="M45.91,55.52H18.09l.11-13.85A13.64,13.64,0,0,1,32,28.25,13.68,13.68,0,0,1,45.91,41.67Zm-25.81-2H43.91V41.67A11.68,11.68,0,0,0,32,30.25,11.64,11.64,0,0,0,20.2,41.67Z"/>
        </g>
      </svg>`;
  }

  render(): TemplateResult {
    return this.renderIcon();
  }
}
