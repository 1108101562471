import { Component } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss']
})
export class CheckboxComponent {

  form = new FormGroup({
    checkInitialTrue: new FormControl(true),
    checkInitialFalse: new FormControl(false),
    checkDisabledTrue: new FormControl({ value: true, disabled: true }),
    checkDisabledFalse: new FormControl({ value: false, disabled: true }),
    checkString: new FormControl('test'),
    checkNull: new FormControl(),
  });

}
