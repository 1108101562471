import { LitElement, svg, TemplateResult } from 'lit';
import { customElement } from 'lit/decorators.js';
import { BaseMdElement } from '../base-lit-element';
import { iconClass } from '../../directives/icon-class.directive';
import { BaseIcon } from './base-icon.mixin';

// keep in synk global.d.ts
@customElement('md-icon-close')
export class IconClose extends BaseIcon(BaseMdElement(LitElement)) {

  constructor() {
    super();

    this.smallSvgIcon = svg`
      <svg ${iconClass(this)} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
        <polygon points="12.91 6.36 10 9.28 7.09 6.36 6.38 7.07 9.29 9.98 6.38 12.9 7.09 13.6 10 10.69 12.91 13.6 13.62 12.9 10.71 9.98 13.62 7.07 12.91 6.36"/>
        <path d="M2.17,17.81H17.83V2.16H2.17Zm1-14.65H16.83V16.81H3.17Z"/>
      </svg>`;

    this.largeSvgIcon = svg`
      <svg ${iconClass(this)} xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64">
        <g>
          <polygon points="22.25 43.16 32 33.41 41.75 43.16 43.16 41.75 33.41 32 43.16 22.25 41.75 20.84 32 30.59 22.25 20.84 20.84 22.25 30.59 32 20.84 41.75 22.25 43.16"/>
          <path d="M7.56,7.56V56.44H56.44V7.56ZM54.44,54.44H9.56V9.56H54.44Z"/>
        </g>
      </svg>`;
  }

  render(): TemplateResult {
    return this.renderIcon();
  }
}
