
import { html, TemplateResult } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';
import { AvailableIcons, IconSize } from '../icons/icons';
import { ILitMandatoryFields } from '../../helpers/lit-mandatory-fields';
import { PropertyRequiredError } from '../../helpers/property-required-error';
import { LitClickEvent } from '../../helpers/events';
import { nameofFactory } from '../../helpers/nameof';
import { BaseLitElement } from '../base-lit-element';
import { ILitFocusable } from '../focusable';
import styles from './tile-button-style.scss';
import '../icons/icon-chevron';
import '../icons/icon';

// was it easier to have two components, `vertical` and `horizontal` tile ?

const nameof = nameofFactory<TileButton>();

export type TileButtonSize = 'small' | 'medium' | 'large';
export type TileType = 'vertical' | 'horizontal';
export type TileIconSize = 'xl' | 'xxl';

// keep in synk global.d.ts
@customElement('md-tile-button')
export class TileButton extends BaseLitElement implements ILitMandatoryFields, ILitFocusable {

  public static styles = styles;

  /** Tile type. Default `vertical`. */
  @property({ type: String, attribute: true, reflect: true }) type: TileType = 'vertical';

  /** Vertical tile size. Ignored for the horizontal version. */
  @property({ type: String, attribute: true, reflect: true }) size: TileButtonSize = 'large';

  @property({ type: String, attribute: true, reflect: true }) title = '';

  @property({ type: String, attribute: true, reflect: true }) text = '';

  @property({ type: Boolean, attribute: true, reflect: true }) disabled = false;

  @property({ type: String, attribute: true, reflect: true }) icon: AvailableIcons | null = null;

  /** Vertical icon tile size. Ignored for the horizontal version. Default `xxl`. */
  @property({ type: String, attribute: true, reflect: true }) iconSize: TileIconSize = 'xxl';

  update(changedProperties: Map<string | number | symbol, unknown>): void {
    this.checkProperties();

    super.update(changedProperties);
  }

  checkProperties(): void {
    if (!this.title) {
      throw new PropertyRequiredError(this, nameof('title'));
    }
  }

  focus(options?: FocusOptions): void {
    this.renderRoot?.querySelector('button')?.focus(options);
  }

  _onClick(ev: PointerEvent | KeyboardEvent): void {
    ev.preventDefault();
    ev.stopPropagation();

    if (this.disabled) {
      return;
    }

    this.dispatchEvent(new LitClickEvent());
  }

  render(): TemplateResult {
    let iconSize: IconSize = 'size-xl';
    if (this.type === 'vertical' && this.iconSize === 'xxl') {
iconSize = 'size-xxl';
    } 

    return html`
    <button @click="${this._onClick}" ?disabled="${this.disabled}" class="${this.size} ${this.type}">
      <div id="icon" @click="${this._onClick}">
        ${this.icon 
      ? html`<md-icon icon="${this.icon}" class="${iconSize}"></md-icon>` 
      : html`<div class="${iconSize}"><slot name="icon"></slot></div>`
          }
      </div>

      <div id="content" @click="${this._onClick}">
        <h4 class="${classMap({'has-text': this.text})}">${this.title}</h4>
        ${this.text ? html`<span id="textContent">${this.text}</span>` : html``}
      </div>
      
      <div id="chevronIcon" @click="${this._onClick}">
        <md-icon-chevron class="size-l"></md-icon-chevron>
      </div>
    </button>
    `;
  }
}
