
import { LitElement, svg, TemplateResult } from 'lit';
import { customElement } from 'lit/decorators.js';
import { BaseMdElement } from '../base-lit-element';
import { iconClass } from '../../directives/icon-class.directive';
import { BaseIcon } from './base-icon.mixin';

// TODO: there is no svg icon named arrow-down

// keep in synk global.d.ts
@customElement('md-icon-dropdown-arrow')
export class IconDropDownArrow extends BaseIcon(BaseMdElement(LitElement)) {

  constructor() {
    super();

    // icon_20px_dropdow_narrow.svg
    this.smallSvgIcon = svg`
      <svg ${iconClass(this)} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
        <polygon points="17.08 5.75 10 12.83 2.92 5.75 2.21 6.46 10 14.25 17.79 6.46 17.08 5.75"/>
      </svg>`;

    // 64px_dropwdown_arrow.svg
    this.largeSvgIcon = svg`
      <svg ${iconClass(this)} xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64">
        <polygon points="32 44.95 7.51 20.46 8.92 19.05 32 42.12 55.08 19.05 56.49 20.46 32 44.95"/>
      </svg>`;
  }

  render(): TemplateResult {
    return this.renderIcon();
  }
}
