<md-page-title value="md-paragraph | Paragraph"></md-page-title>

<div class="flex direction-column gap-4 p-3">
  <h3>md-paragraph with slot value</h3>
  <md-paragraph>{{paragraphContentPlain}}</md-paragraph>

  <h3>md-paragraph with attribute value</h3>
  <md-paragraph [value]="paragraphContentUnsafe"></md-paragraph>
  <md-paragraph></md-paragraph>

  <h3>Help text</h3>
  <div class="flex direction-column gap-2">
    <md-paragraph class="help" [value]="paragraphContentUnsafe"></md-paragraph>

    <h4>Example with form</h4>
    <md-form-field for="customTooltip" label="Custom tooltip" style="width: 500px"
      (tooltipToggle)="showTooltip = !showTooltip" [customTooltip]="true">

      <md-paragraph *ngIf="showTooltip" class="help" [value]="paragraphContentUnsafe"></md-paragraph>

      <md-numeric-field readonly id="customTooltip" value="Readonly" ngDefaultControl>
      </md-numeric-field>
    </md-form-field>
  </div>
</div>
