<md-page-title value="md-navigation-header | Navigation header"></md-page-title>

<div class="flex direction-column gap-4 p-3">

  <md-navigation-header header="Tilde"></md-navigation-header>
  <md-navigation-header header="Tilde" status="auth" name="Mikael Holmberg" org="Inmeta AS"></md-navigation-header>

  <h3>Style wrapping layout</h3>

  <md-paragraph>The application developer decides the surrounding layout. Important to set
    <em>--nav-background-color</em> in css if the same background shall be used.

    There are also two variables for padding that can be overriden in the component, <em>--nav-padding-right</em> and
    <em>--nav-padding-left</em>.
  </md-paragraph>

  <h5>Example in grid responsive layout</h5>
  <div class="nav-background">
    <div class="grid-responsive grid-responsive-p screen-wrapper ">
      <div class="col-s-start-0 col-m-start-1 col-start-1 col-s-4 col-m-6 col-10">
        <md-navigation-header header="Tilde" status="auth" name="Mikael Holmberg" org="Inmeta AS">
        </md-navigation-header>
      </div>
    </div>
  </div>
</div>

<div class="flex direction-column gap-6 p-3">
  <h3>Navigation header</h3>

  <md-navigation-header header="Tilde"></md-navigation-header>
  <md-navigation-header header="Tilde" status="auth" name="Mikael Holmberg" org="Inmeta AS"></md-navigation-header>

  <h3>Style layout and component</h3>

  <md-paragraph [value]="explanation"> </md-paragraph>

  <div class="flex direction-column gap-2">
    <h3>Grid responsive layout</h3>

    <md-paragraph [value]="gridExplanation"> </md-paragraph>
    <pre><code>{{codeExample}}</code></pre>

    <h5>Example:</h5>
    <div class="nav-background">
      <div class="grid-responsive grid-responsive-p screen-wrapper ">
        <div class="col-s-start-0 col-m-start-1 col-start-1 col-s-4 col-m-6 col-10">
          <md-navigation-header header="Tilde" status="auth" name="Mikael Holmberg" org="Inmeta AS">
          </md-navigation-header>
        </div>
      </div>
    </div>
  </div>

  <div class="flex direction-column gap-2">
    <h3>Grid responsive layout (bad example)</h3>

    <pre><code>{{codeBadExample}}</code></pre>

    <h5>Example:</h5>
    <div class="nav-background-bad-example">
      <div class="grid-responsive grid-responsive-p screen-wrapper ">
        <div class="col-s-start-0 col-m-start-1 col-start-1 col-s-4 col-m-6 col-10">
          <md-navigation-header header="Tilde" status="auth" name="Mikael Holmberg" org="Inmeta AS">
          </md-navigation-header>
        </div>
      </div>
    </div>
  </div>

  <div class="flex direction-column gap-2">
    <h3>Example change <em>--nav-bar-padding-left</em> and <em>--nav-bar-padding-right</em></h3>
    <pre><code>{{codeExamplePadding}}</code></pre>

    <h5>Example:</h5>
    <md-navigation-header class="nav-bar-padding" header="Tilde" status="auth" name="Mikael Holmberg" org="Inmeta AS">
    </md-navigation-header>
  </div>
</div>
