
import { html, PropertyValues, TemplateResult } from 'lit';
import { customElement, property, query, state } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';
import { LitSearchCustomEvent, LitInputEvent, LitChangeCustomEvent } from '../../helpers/events';
import { ILitMandatoryFields } from '../../helpers/lit-mandatory-fields';
import { nameofFactory } from '../../helpers/nameof';
import { SelectItemsProp } from './select.model';
import { PropertyMissmatchError } from '../../helpers/property-invalid-error';
import { BaseLitElement } from '../base-lit-element';
import { ILitFocusable } from '../focusable';
import '@a11y/focus-trap';
import styles from './select-style.scss';
import '../checkbox/checkbox';
import '../chips/chips';
import '../icons/icon-dropdown-arrow';
import '../icons/icon-close-no-border';
import '../icons/icon-spinner';

const nameof = nameofFactory<SelectSingle>();

// keep in synk global.d.ts
@customElement('md-select-single')
export class SelectSingle extends BaseLitElement implements ILitMandatoryFields, ILitFocusable {
  static styles = [styles];

  @property({ type: String, attribute: true, reflect: true }) invalid?: string;
  @property({ type: String, attribute: true, reflect: true }) placeholder = '';
  @property({ type: Boolean, attribute: true, reflect: true }) readOnly = false;
  @property({ type: Boolean, attribute: true, reflect: true }) noBorder = false;

  @property({ type: Array, attribute: true, reflect: true })
  items: Array<SelectItemsProp> = [];

  private _value: string | null = null;

  @property({ type: String, attribute: true, reflect: true })
  get value(): string | null {
    return this._value;
  }

  set value(value: string | null) {
    const oldValue = this._value;
    this._value = value;
    this.searchInput = this.keyToText(this.value) ?? '';
    this.requestUpdate('value', oldValue);
  }

  @property({ type: Boolean, attribute: true, reflect: true }) active = false;
  @property({ type: Boolean, attribute: true, reflect: true }) customSearch = false;
  @property({ type: Boolean, attribute: true, reflect: true }) disabled = false;
  @property({ type: Boolean, attribute: true, reflect: true }) searchable = false;
  @property({ type: Boolean, attribute: true, reflect: true }) singleLine = false;

  /** Prevents the user from unselect once a value is selected. */
  @property({ type: Boolean, attribute: true, reflect: true }) requireSelection = false;
  @property({ type: String, attribute: true, reflect: true }) status: 'Idle' | 'Loading' | 'Error' = 'Idle';

  @query('input', true)
  _input?: HTMLElement;

  @state()
  searchInput = '';

  @state()
  filtered: SelectItemsProp[] = [];

  private searchInputInitialised = false;

  update(_changedProperties: Map<string | number | symbol, unknown>): void {
    super.update(_changedProperties);
    this.checkProperties();
    if (!this.searchInputInitialised && this.items.length && this.searchable) {
      this.searchInput = this.keyToText(this.value) ?? '';
      this.searchInputInitialised = true;
    }
  }

  checkProperties(): void {
    if (this.status === 'Idle' && this.value && !this.items.find(x => x.key === this.value)) {
      throw new PropertyMissmatchError(this, nameof('items'), nameof('value'), this.items, this.value);
    }
  }

  get displayItems(): SelectItemsProp[] {
    return this.customSearch || !this.searchable || this.searchInput === ''
      ? this.items
      : this.filtered;
  }

  firstUpdated(_changedProperties: PropertyValues): void {
    super.firstUpdated(_changedProperties);
    document.addEventListener('click', ev => this._onDocumentClick(ev));
    this.addEventListener('blur', this._onBlur);
  }

  disconnectedCallback(): void {
    super.disconnectedCallback();
    document.removeEventListener('click', ev => this._onDocumentClick(ev));
  }

  _dispatchChangeEvent<T>(value: SelectItemsProp<T> | undefined): void {
    this.dispatchEvent(new LitInputEvent());
    this.dispatchEvent(new LitChangeCustomEvent(value));
  }

  _onDocumentClick(ev: Event): void {
    if (this.active && !this.contains(ev.target as HTMLElement)) {
      this._deactivate();
    }
  }

  _onSelect(item: SelectItemsProp): void {
    if (this.readOnly || this.disabled) {
      return;
    }
    this.value = item.key;
    this.searchInput = item.text;
    this._deactivate();
    this._dispatchChangeEvent(item);
    this.requestUpdate();
  }

  _setFilteredItems(): void {
    if (this.searchInput !== '') {
      this.filtered = this.items.filter(x =>
        x.text
          .trim()
          .toLowerCase()
          .includes(this.searchInput.trim().toLowerCase())
      );
    } else {
      this.filtered = [];
    }
  }

  _onSearchChange(event: Event): void {
    this.searchInput = (event.target as HTMLInputElement).value;
    if (this.customSearch) {
      this.dispatchEvent(
        new LitSearchCustomEvent(this.searchInput)
      );
    } else {
      this._setFilteredItems();
      this.requestUpdate();
    }
  }

  _onClearSearch(): void {
    this.searchInput = '';
    if (this.customSearch) {
      this.dispatchEvent(
        new LitSearchCustomEvent(this.searchInput)
      );
    } else {
      if (this.searchInput !== '') {
        this.filtered = this.items.filter(x =>
          x.text
            .trim()
            .toLowerCase()
            .includes(this.searchInput.trim().toLowerCase())
        );
      } else {
        this.filtered = [];
      }
      this.requestUpdate();
    }
  }

  _onClearSelection(ev: Event): void {
    ev.stopPropagation();
    if (!this.active && !this.requireSelection) {
      this.value = null;
    }

    if (this.searchInput && this.searchable) {
      this._onClearSearch();
    }
    this._dispatchChangeEvent(undefined);
    this.requestUpdate();
  }

  _resultContainerKeyboardEvent(ev: KeyboardEvent): void {
    switch (ev.key) {
      case 'End':
        ev.preventDefault();
        this.lastResultItem?.focus();
        break;
      case 'Home':
        ev.preventDefault();
        this.firstResultItem?.focus();
        break;
    }
  }

  get lastResultItem(): HTMLElement | null {
    const items = Array.from(this.shadowRoot?.querySelectorAll(`.result-item:not([disabled])`) ?? []);
    const lastItem = items[items.length - 1];

    if (!lastItem) {
      return null;
    }
    return lastItem as HTMLElement;
  }

  get firstResultItem(): HTMLElement | null {
    const firstItem = Array.from(this.shadowRoot?.querySelectorAll(`.result-item:not([disabled])`) ?? [])[0];
    if (!firstItem) {
      return null;
    }
    return firstItem as HTMLElement;
  }

  _getSiblingItem(radios: HTMLElement[], current: HTMLElement) {
    const enabledRadios = radios;
    for (let i = 0; i < enabledRadios.length; i++) {
      if (enabledRadios[i] === current) {
        if (!enabledRadios[i + 1]) {
          return null;
        }
        return enabledRadios[i + 1];
      }
    }
    return null;
  }

  _getNextResultItem(current: HTMLElement) {
    const items = Array.from(this.shadowRoot?.querySelectorAll(`.result-item`) ?? []) as HTMLElement[];
    return this._getSiblingItem(items, current);
  }

  _getPreviousResultItem(current: HTMLElement) {
    const items = Array.from(this.shadowRoot?.querySelectorAll(`.result-item`) ?? []).reverse() as HTMLElement[];
    return this._getSiblingItem(items, current);
  }

  _onSearchKeyBoardEvent(ev: KeyboardEvent): void {
    switch (ev.key) {
      case 'Down': // IE/Edge specific value
      case 'ArrowDown': {
        ev.preventDefault();
        this.firstResultItem?.focus();
        return;
      }
      case 'Up': // IE/Edge specific value
      case 'ArrowUp': {
        ev.preventDefault();
        this.lastResultItem?.focus();
        return;
      }
    }
  }

  _onKeyBoardEvent(ev: KeyboardEvent, item: SelectItemsProp): void {
    switch (ev.key) {
      case 'Down': // IE/Edge specific value
      case 'ArrowDown': {
        ev.preventDefault();
        const next = this._getNextResultItem(ev.target as HTMLElement);
        if (!next) {
          if (this.searchable) {
            this._input?.focus();
            return;
          }
          const first = this.firstResultItem;
          first?.focus();
          return;
        }

        next.focus();
        break;
      }
      case 'Up': // IE/Edge specific value
      case 'ArrowUp': {
        ev.preventDefault();
        const previous = this._getPreviousResultItem(ev.target as HTMLElement);
        if (!previous) {
          if (this.searchable) {
            this._input?.focus();
            return;
          }
          const last = this.lastResultItem;
          last?.focus();
          return;
        }
        previous.focus();
        break;
      }
      case 'Enter':
      case ' ': {
        ev.preventDefault();
        this._onSelect(item);
        break;
      }
    }
  }

  _focusFirstSelectItem() {
    setTimeout(() => {
      const firstSelected = this.shadowRoot?.querySelector('.result-item[selected="true"]');
      if (firstSelected) {
        (firstSelected as HTMLElement).focus();
      } else {
        this.firstResultItem?.focus();
      }
    }, 0);
  }

  _onFocus(_ev: Event): void {
    if (!this.hasAttribute('active')) {
      this._activate();
      this._focusFirstSelectItem();
    }
  }

  _onBlur(_ev: Event): void {
    if (this.hasAttribute('active')) {
      this._deactivate();

    }
  }
  
  _activate(): void {
    this.setAttribute('active', 'true');
    this.addEventListener('keydown', this._resultContainerKeyboardEvent);
    if (this.searchable) {
      this._onClearSearch();
    }
  }

  _deactivate(): void {
    this.removeAttribute('active');
    this.removeEventListener('keydown', this._resultContainerKeyboardEvent);
    this._input?.blur();
    this.searchInput = this.keyToText(this.value) ?? '';
    this.filtered = this.items;
  }

  keyToText(key: string | null): string | null {
    if (key === null || key === undefined) {
      return null;
    }
    return this.items.find(x => x.key === key)?.text ?? null;
  }

  focus(options?: FocusOptions): void {
    this._input?.focus(options);
  }

  render(): TemplateResult {
    return html`
<div class="wrapper" tabindex="-1">
  <div>
    <div @click="${(e: Event) => { e.preventDefault(); e.stopPropagation(); this._activate(); }}"
      class="select-container ${classMap({ 'alert-border': !!this.invalid, })}" ?active="${this.active}">
      <div class="select-container-left">
        <div class="${classMap({ 'hidden': !this.searchable || this.status !== 'Loading' })}">
          <md-icon-spinner class="size-xs spinner"></md-icon-spinner>
        </div>
        <input tabindex="-1" class="search-input ${classMap({ 'hidden': !this.searchable })}" type="text"
          ?disabled="${this.disabled || this.readOnly}" .value="${this.searchInput}" placeholder="${this.placeholder}"
          @input="${this._onSearchChange}" @click="${(ev: Event) => {
        ev.stopPropagation();
        this._activate();
      }}" @keydown="${this._onSearchKeyBoardEvent}" />
        <div class="selected-text ${classMap({ 'hidden': this.searchable })}">
          ${this.keyToText(this.value) ?? this.placeholder}
        </div>
      </div>
      <div class="select-container-right">
        <div
          class=" ${classMap({ 'visibility-none': (this.active && (!this.searchable || !this.searchInput)) || (!this.active && this.requireSelection) || (!this.active && !this.requireSelection && (this.value === null || this.value === undefined)) })}">
          <md-icon-close-no-border class="pointer size-xs" @click="${this._onClearSelection}"
            @keydown="${(e: KeyboardEvent) => e.key === 'Enter' && this._onClearSelection(e as Event)}" tabindex="0"
            ?inactive="${this.disabled || this.readOnly}">
          </md-icon-close-no-border>
        </div>
        <md-icon-dropdown-arrow class="pointer size-s rotate-180 ${classMap({ 'hidden': !this.active })}"
          @click="${(e: Event) => { e.preventDefault(); e.stopPropagation(); this._deactivate(); }}">
        </md-icon-dropdown-arrow>
        <md-icon-dropdown-arrow class="pointer size-s ${classMap({ 'hidden': this.active })}"
          ?inactive="${this.disabled}"
          @click="${(e: Event) => { e.preventDefault(); e.stopPropagation(); this._activate(); }}">
        </md-icon-dropdown-arrow>
      </div>
    </div>

    <div tabindex="${!this.active ? 0 : -1}" @focus="${this._onFocus}">
      <div class="${classMap({ 'hidden': !this.active })}">
        <div class="result-container ${classMap({
        'alert-border': !!this.invalid,
      })}" ?hidden="${!this.active && this.displayItems.length > 0}">

          ${this.displayItems.map((item) => html`
          <div tabindex="-1" class="result-item" selected="${this.value === item.key}" data-key="${item.key}"
            data-text="${item.text}" @click="${() => this._onSelect(item)}"
            @keydown="${(ev: KeyboardEvent) => this._onKeyBoardEvent(ev, item)}">
            ${item.text}
          </div>`
              )}
          <div class="no-result-container ${classMap({ 'hidden': this.displayItems.length })}">
            <span>Inget resultat</span>
          </div>
        </div>
      </div>
    </div>

  </div>
  <span class="alert-text ${classMap({ 'hidden': !this.invalid })}">${this.invalid}</span>
</div>
`;
  }
}
