/** Helper class to parse a string to it's numeric value based on a locale. 
 * Be aware that javascript number are Floating Point, so some numbers cannot be represented.
 * Another option is to use javascript `Bigint`, but more work could be needed in this helper. 
*/
export class NumberParser {
  /* See: https://observablehq.com/@mbostock/localized-number-parsing
   *
   * new Intl.NumberFormat('no-NB').formatToParts(12345.6); // `12 345,6`
   *
   * parts = [
   *   type: integer,  value: "12",
   *   type: group,    value: " ",
   *   type: integer,  value: "345",
   *   type: decimal,  value: ",",
   *   type: fraction, value: "6",
   * ]
  */

  _group: RegExp;
  _decimal: RegExp;
  _numeral: RegExp;
  _numerals: Map<string, number>;
  getNumeral = (substring: string, ..._args: unknown[]): string => '' + this._numerals.get(substring);

  constructor(
    locales?: string | string[] | undefined
  ) {
    const parts = new Intl.NumberFormat(locales).formatToParts(12345.6);

    // this looks complicated, but it's needed to support RTL locales
    const numerals = [...new Intl.NumberFormat(locales, { useGrouping: false }).format(9876543210)].reverse();
    this._numerals = new Map(numerals.map((d, i) => [d, i]));

    // rules to replace numeric symbols
    let groupSymbol = parts.find(d => d.type === 'group')?.value || '';
    const decimalSymbol = parts.find(d => d.type === 'decimal')?.value || '';

    if (groupSymbol.length > 0 && groupSymbol.charCodeAt(0) === 160) {
      // no-NB has a space char that is not 32, but 160, so I'm adding both to avoid keyboard/locale/OS problems.
      groupSymbol += String.fromCharCode(32);
    }
    // console.log(groupSymbol?.charCodeAt(0), decimalSymbol?.charCodeAt(0));

    this._group = new RegExp(`[${groupSymbol}]`, 'g');
    this._decimal = new RegExp(`[${decimalSymbol}]`);
    this._numeral = new RegExp(`[${numerals.join('')}]`, 'g');
  }

  parse(text: string): { value: number, valid: boolean } {
    if ((text || '').length === 0) {
      return { value: NaN, valid: false };
    }

    const str = text.trim()
      .replace(this._group, '')
      .replace(this._decimal, '.')
      .replace(this._numeral, this.getNumeral);

    const value = +str;
    return { value, valid: !isNaN(value) };
  }
}

/**
 * Number/String formatter helper based on `ECMAScript Internationalization API` specifications.
 * Be aware that javascript number are Floating Point, so some numbers cannot be represented.
 * Another option is to use javascript `Bigint`, but more work could be needed in this helper. 
 * 
 * More info about locales: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl#locale_identification_and_negotiation
 * 
 * `Number-to-string` formatter helper based on Intl.NumberFormat.
 * 
 * `String-to-number` is not available in the specs, but it uses the same APIs to detect locale specs and (try to) parse the string accordingly.
 * More info: https://observablehq.com/@mbostock/localized-number-parsing 
*/
export class IntlNumberFormatter {
  private _toStringFormatter: Intl.NumberFormat;
  private _numberParser: NumberParser;

  constructor(
    locales?: string | string[] | undefined,
    options?: Intl.NumberFormatOptions | undefined
  ) {
    this._toStringFormatter = new Intl.NumberFormat(locales, options);
    this._numberParser = new NumberParser(locales);
  }

  static get browserLocale() {
    return Intl.NumberFormat().resolvedOptions().locale;
  }

  /** Convert a javascript number to a string representation. The number is formatted based on the locale. */
  toString(value: number | bigint): string {
    return this._toStringFormatter.format(value);
  }

  /** Convert a string to a javascript number representation. The string is parsed based on the locale. */
  toNumber(text: string): number {
    const str = (text || '').trim();
    if (str === null || str === undefined || str === '') {
      return NaN;
    }

    const { value, valid } = this._numberParser.parse(str);
    return (valid) ? value : NaN;
  }

}
