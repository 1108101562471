<md-page-title value="md-tile-button | Tile button"></md-page-title>

<p>Width- decided by layout and set to Grid (column)</p>

<p>Content in <em>slot=icon</em> should be using one of the default sizes: <em>64x64</em> px or <em>128x128</em> px.</p>

<div class="flex direction-column gap-4 p-3">
  <h3>type: <em>vertical</em> (Default)</h3>

  <p>iconsize: <em>xl</em> (64px) or <em>xxl</em> (128px). Default to xxl.</p>

  <ng-container *ngFor="let size of sizes">
    <h4>size: <em>{{size}}</em> {{size === 'large' ? '(default)' : ''}}</h4>

    <div class="grid">
      <div class="col-1">
        <md-tile-button size="{{size}}" title="Søknad | {{size}}" text="grid col-1 | icon via attribute"
          icon="md-icon-trash-bin" (click)="onClick($event)">
        </md-tile-button>
      </div>

      <div class="col-2">
        <md-tile-button size="{{size}}" iconsize="xl" title="Søknad" text="grid col-2 | xl icon via slot"
          (click)="onClick($event)">
          <md-icon-trash-bin slot="icon" class="success size-xl"></md-icon-trash-bin>
        </md-tile-button>
      </div>

      <div class="col-2">
        <md-tile-button size="{{size}}" iconsize="xxl" title="Søknad" text="grid col-2 | xxl icon via slot"
          (click)="onClick($event)">
          <md-icon-trash-bin slot="icon" class="success size-xxl"></md-icon-trash-bin>
        </md-tile-button>
      </div>

      <div class="col-3">
        <md-tile-button size="{{size}}" title="grid col-3 | no text | icon via slot" (click)="onClick($event)">
          <md-icon-checkmark slot="icon" class="success size-xl">
          </md-icon-checkmark>
        </md-tile-button>
      </div>

      <div class="col-4">
        <md-tile-button size="{{size}}" title="Søknad" text="grid col-4 | icon via slot | disabled" disabled
          (click)="onClick($event)">
          <md-icon-checkmark slot="icon" class="success size-xl">
          </md-icon-checkmark>
        </md-tile-button>
      </div>

      <div class="col-1">
        <!-- 12 -->
      </div>
    </div>

  </ng-container>

  <h3>type: <em>horizontal</em></h3>

  <p>iconsize: only <em>xl</em> (64px). Ignored if anything else.</p>

  <div class="grid">
    <div class="col-3">
      <md-tile-button type="horizontal" title="Søknad" text="grid col-1 | icon via attribute" icon="md-icon-trash-bin"
        (click)="onClick($event)">
      </md-tile-button>
    </div>

    <div class="col-3">
      <md-tile-button type="horizontal" title="Søknad" text="grid col-1 | icon via attribute" icon="md-icon-trash-bin"
        (click)="onClick($event)">
      </md-tile-button>
    </div>

    <div class="col-3">
      <md-tile-button type="horizontal" title="Søknad" text="grid col-2 | icon via slot" (click)="onClick($event)">
        <md-icon-checkmark slot="icon" class="success size-xl">
        </md-icon-checkmark>
      </md-tile-button>
    </div>

    <div class="col-3">
      <md-tile-button type="horizontal" title="grid col-3 | no text | icon via slot" (click)="onClick($event)">
        <md-icon-checkmark slot="icon" class="success size-xl">
        </md-icon-checkmark>
      </md-tile-button>
    </div>

    <div class="col-3">
      <md-tile-button type="horizontal" title="grid col-4 | no text | icon via attribute" [icon]="'md-icon-checkmark'"
        (click)="onClick($event)">
      </md-tile-button>
    </div>

    <div class="col-3">
      <md-tile-button type="horizontal" title="Søknad" text="grid col-2 | icon via slot | disabled" disabled
        (click)="onClick($event)">
        <md-icon-checkmark slot="icon" class="success size-xl">
        </md-icon-checkmark>
      </md-tile-button>
    </div>
  </div>

</div>
