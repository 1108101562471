
import { LitElement, svg, TemplateResult } from 'lit';
import { customElement } from 'lit/decorators.js';
import { BaseMdElement } from '../base-lit-element';
import { iconClass } from '../../directives/icon-class.directive';
import { BaseIcon } from './base-icon.mixin';

// keep in synk global.d.ts
@customElement('md-icon-comment-fill')
export class IconCommentFill extends BaseIcon(BaseMdElement(LitElement)) {

  constructor() {
    super();

    this.smallSvgIcon = svg`
      <svg ${iconClass(this)} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
        <path d="M17.48,4.58a2.38,2.38,0,0,0-.65-1.65,2.28,2.28,0,0,0-1.64-.7H4.81a2.32,2.32,0,0,0-2.3,2.35v8.13a2.32,2.32,0,0,0,2.3,2.35h8.66l2.29,2.41a1,1,0,0,0,.73.3,1,1,0,0,0,.37-.07,1,1,0,0,0,.63-.93ZM7.55,9.49H6.06V7.94H7.55Zm3,0H9V7.94h1.48Zm3,0H12V7.94h1.48Z"/>
      </svg>`;

    this.largeSvgIcon = svg`
      <svg ${iconClass(this)} xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64">
        <path d="M49.32,8.46,14.76,8.41h0a5.42,5.42,0,0,0-5.32,5.51L9.39,41a5.56,5.56,0,0,0,1.54,3.88,5.22,5.22,0,0,0,3.77,1.64h0l29.82,0,8.33,8.73a1,1,0,0,0,.72.31.94.94,0,0,0,.37-.07,1,1,0,0,0,.63-.93l0-40.62A5.41,5.41,0,0,0,49.32,8.46ZM24.26,30.9a.89.89,0,0,1-.89.9H18.93a.9.9,0,0,1-.9-.9V26.27a.9.9,0,0,1,.9-.9h4.44a.89.89,0,0,1,.89.9Zm9.87,0a.89.89,0,0,1-.89.9H28.8a.89.89,0,0,1-.89-.9V26.27a.89.89,0,0,1,.89-.9h4.44a.89.89,0,0,1,.89.9Zm9.87,0a.89.89,0,0,1-.89.9H38.67a.89.89,0,0,1-.89-.9V26.27a.89.89,0,0,1,.89-.9h4.44a.89.89,0,0,1,.89.9Z"/>
      </svg>`;
  }

  render(): TemplateResult {
    return this.renderIcon();
  }
}
