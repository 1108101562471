<md-page-title value="Grid | scss/css helpers"></md-page-title>

<div class="flex direction-column gap-4 p-3">

  <h3>No media breakpoint (12 cols)</h3>
  <div class="grid">
    <div class="col-12 grid-column"></div>
    <div class="col-6 grid-column"></div>
    <div class="col-6 grid-column"></div>
    <div class="col-3 grid-column"></div>
    <div class="col-3 grid-column"></div>
    <div class="col-3 grid-column"></div>
    <div class="col-3 grid-column"></div>
    <div class="col-1 grid-column"></div>
    <div class="col-1 grid-column"></div>
    <div class="col-1 grid-column"></div>
    <div class="col-1 grid-column"></div>
    <div class="col-1 grid-column"></div>
    <div class="col-1 grid-column"></div>
    <div class="col-1 grid-column"></div>
    <div class="col-1 grid-column"></div>
    <div class="col-1 grid-column"></div>
    <div class="col-1 grid-column"></div>
    <div class="col-1 grid-column"></div>
    <div class="col-1 grid-column"></div>
  </div>

  <h3>Media breakpoint | 1024px: col-m-* with 8 cols, 768px: col-s-* with 4 cols</h3>
  <div class="grid-responsive">
    <div class="col-12 col-m-8 col-s-4 grid-column"></div>
    <div class="col-6 col-m-4 col-s-2 grid-column"></div>
    <div class="col-6 col-m-4 col-s-2 grid-column"></div>
    <div class="col-3 col-m-2 col-s-1 grid-column"></div>
    <div class="col-3 col-m-2 col-s-1 grid-column"></div>
    <div class="col-3 col-m-2 col-s-1 grid-column"></div>
    <div class="col-3 col-m-2 col-s-1 grid-column"></div>
    <div class="col-1 col-m-1 col-s-1 grid-column"></div>
    <div class="col-1 col-m-1 col-s-1 grid-column"></div>
    <div class="col-1 col-m-1 col-s-1 grid-column"></div>
    <div class="col-1 col-m-1 col-s-1 grid-column"></div>
    <div class="col-1 col-m-1 col-s-1 grid-column"></div>
    <div class="col-1 col-m-1 col-s-1 grid-column"></div>
    <div class="col-1 col-m-1 col-s-1 grid-column"></div>
    <div class="col-1 col-m-1 col-s-1 grid-column"></div>
    <div class="col-1 col-m-1 col-s-1 grid-column"></div>
    <div class="col-1 col-m-1 col-s-1 grid-column"></div>
    <div class="col-1 col-m-1 col-s-1 grid-column"></div>
    <div class="col-1 col-m-1 col-s-1 grid-column"></div>
  </div>

  <h3>Media breakpoint (1024px)</h3>
  <p>Below 768px not supported without col-s-*</p>
  <div class="grid-responsive">
    <div class="col-12 col-m-8 grid-column"></div>
    <div class="col-6 col-m-4 grid-column"></div>
    <div class="col-6 col-m-4 grid-column"></div>
    <div class="col-3 col-m-2 grid-column"></div>
    <div class="col-3 col-m-2 grid-column"></div>
    <div class="col-3 col-m-2 grid-column"></div>
    <div class="col-3 col-m-2 grid-column"></div>
    <div class="col-1 col-m-1 grid-column"></div>
    <div class="col-1 col-m-1 grid-column"></div>
    <div class="col-1 col-m-1 grid-column"></div>
    <div class="col-1 col-m-1 grid-column"></div>
    <div class="col-1 col-m-1 grid-column"></div>
    <div class="col-1 col-m-1 grid-column"></div>
    <div class="col-1 col-m-1 grid-column"></div>
    <div class="col-1 col-m-1 grid-column"></div>
    <div class="col-1 col-m-1 grid-column"></div>
    <div class="col-1 col-m-1 grid-column"></div>
    <div class="col-1 col-m-1 grid-column"></div>
    <div class="col-1 col-m-1 grid-column"></div>
  </div>

</div>
