import { html, TemplateResult } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { BaseLitElement } from '../base-lit-element';
import '../icons/icon-close';
import styles from './modal-title-style.scss';

// keep in synk global.d.ts
@customElement('md-modal-title')
export class ModalTitle extends BaseLitElement {

  public static styles = styles;

  @property({ type: String, attribute: true, reflect: true }) slot = 'modalTitle';

  render(): TemplateResult {
    return html`
    <h3>
      <slot class="test"></slot>
    </h3>
          `;
  }
}
