import { LitElement } from 'lit';

/**
 * Error thrown for a not provided mandatory property.
 */
export class PropertyRequiredError extends Error {
  public constructor(scope: LitElement, fieldName: string, value?: unknown) {
    const message = `[${scope.tagName}] property "${fieldName}" required (${JSON.stringify(value)} is invalid)`;
    super(message);
  }
}
