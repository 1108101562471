<md-page-title value="md-map | Map"></md-page-title>

<div class="flex direction-column gap-4 p-3">

  <div class="flex column-direction" [formGroup]="formNoInput">
    <md-map [x]="formNoInput.value.x" [y]="formNoInput.value.y" [zone]="formNoInput.value.zone"
      (change)="onChangeNoInput($event)"></md-map>
  </div>

  <div class="flex column-direction" [formGroup]="form">
    <h3>With text fields</h3>

    <div class="flex">
      <md-form-field label="Sone">
        <md-numeric-field formControlName="zone" placeholder="Ex. 32" style="width: 250px" ngDefaultControl>
        </md-numeric-field>
      </md-form-field>
      <md-form-field label="Utm øst">
        <md-text-field formControlName="x" placeholder="Ex. 597979.90" style="width: 250px" ngDefaultControl>
        </md-text-field>
      </md-form-field>

      <md-form-field label="Utm nord">
        <md-text-field formControlName="y" placeholder="Ex. 6643118.99" style="width: 250px" ngDefaultControl>
        </md-text-field>
      </md-form-field>
    </div>

    <h3>With layers</h3>

    <md-map [x]="form.value.x" [y]="form.value.y" [zone]="form.value.zone" (change)="onChange($event)"
      [markerLayers]="layers"></md-map>
    <div class="flex">
      <md-button class="secondary" (click)="clearLayers()" [disabled]="!layers.length">Clear Layers</md-button>
      <md-button (click)="addLayers()" [disabled]="layers.length">Add Layers</md-button>
    </div>
  </div>
</div>
