
import { LitElement, svg, TemplateResult } from 'lit';
import { customElement } from 'lit/decorators.js';
import { BaseMdElement } from '../base-lit-element';
import { iconClass } from '../../directives/icon-class.directive';
import { BaseIcon } from './base-icon.mixin';

// TODO: both 20px & 64px version do not support to set the color

// keep in synk global.d.ts
@customElement('md-icon-images')
export class IconImages extends BaseIcon(BaseMdElement(LitElement)) {

  constructor() {
    super();

    this.smallSvgIcon = svg`
      <svg ${iconClass(this)} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
        <defs>
          <style>
            .cls-1 {
              fill: none;
              stroke: #222;
              stroke-linecap: round;
              stroke-linejoin: round;
              stroke-width: 0.99px;
            }

            .cls-2 {
              fill: #222;
            }
          </style>
        </defs>
        <g>
          <rect class="cls-1" x="2.84" y="4.03" width="14.33" height="11.94" rx="0.55"/>
          <path class="cls-2" d="M4.49,13.1H8.82a.37.37,0,0,0,.31-.58L7,9.27a.37.37,0,0,0-.62,0L4.18,12.52A.37.37,0,0,0,4.49,13.1Z"/>
          <path class="cls-2" d="M8.72,13.1h6.92a.38.38,0,0,0,.32-.58L12.49,7.33a.36.36,0,0,0-.62,0L8.4,12.52A.38.38,0,0,0,8.72,13.1Z"/>
        </g>
      </svg>`;

    this.largeSvgIcon = svg`
      <svg ${iconClass(this)} xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64">
        <defs>
          <style>
            .cls-1 {
              fill: #222;
            }
          </style>
        </defs>
        <g>
          <path class="cls-1" d="M53.16,52.13H10.84a2.79,2.79,0,0,1-2.79-2.8V14.67a2.79,2.79,0,0,1,2.79-2.8H53.16A2.79,2.79,0,0,1,56,14.67V49.33A2.79,2.79,0,0,1,53.16,52.13ZM10.84,13.93a.74.74,0,0,0-.73.74V49.33a.74.74,0,0,0,.73.74H53.16a.74.74,0,0,0,.73-.74V14.67a.74.74,0,0,0-.73-.74Z"/>
          <path class="cls-1" d="M51.06,40.07,40,23.44a1.2,1.2,0,0,0-2,0L28.06,38.32,22.3,29.67a1.2,1.2,0,0,0-2,0l-6.93,10.4a1.19,1.19,0,0,0,1,1.86H50.06A1.2,1.2,0,0,0,51.06,40.07Z"/>
        </g>
      </svg>`;
  }

  render(): TemplateResult {
    return this.renderIcon();
  }
}
