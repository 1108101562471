import { html, PropertyValues, TemplateResult } from 'lit';
import { customElement, property, query, state } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';
import { when } from 'lit/directives/when.js';
import { PropertyMissmatchError } from '../../helpers/property-invalid-error';
import { LitChangeEvent, LitSearchCustomEvent, LitInputEvent, LitChangeCustomEvent } from '../../helpers/events';
import { ILitMandatoryFields } from '../../helpers/lit-mandatory-fields';
import { nameofFactory } from '../../helpers/nameof';
import { SelectItemsProp } from './select.model';
import { PropertyRequiredError } from '../../helpers/property-required-error';
import { BaseLitElement } from '../base-lit-element';
import { ILitFocusable } from '../focusable';
import '@a11y/focus-trap';
import styles from './select-style.scss';
import '../checkbox/checkbox';
import '../chips/chips';
import '../icons/icon-close-no-border';
import '../icons/icon-dropdown-arrow';
import '../icons/icon-spinner';

const nameof = nameofFactory<SelectMultiple>();

// keep in synk global.d.ts
@customElement('md-select-multiple')
export class SelectMultiple extends BaseLitElement implements ILitMandatoryFields, ILitFocusable {
  static styles = [styles];

  private _items: SelectItemsProp[] = [];
  private _value: string[] = [];

  @property({ type: String, attribute: true, reflect: true }) invalid?: string;
  @property({ type: String, attribute: true, reflect: true }) placeholder = '';
  @property({ type: Boolean, attribute: true, reflect: true }) readOnly = false;
  @property({ type: Boolean, attribute: true, reflect: true }) noBorder = false;
  @property({ type: Boolean, attribute: true, reflect: true }) allowSelectAll = false;

  @property({ type: Array, attribute: true, reflect: true })

  get items(): SelectItemsProp[] {
    return this._items;
  }

  set items(x: SelectItemsProp[]) {
    const oldValue = this._items;
    this._items = x;
    this.requestUpdate('items', oldValue);
    this.currentSelection = this.items.filter(item => this.value.includes(item.key));
  }

  @property({ type: Array, attribute: true, reflect: true })
  get value(): string[] {
    return this._value;
  }

  set value(x: string[]) {
    const oldValue = this._value;
    this._value = x;
    this.requestUpdate('value', oldValue);
    this.currentSelection = this.items.filter(item => this.value.includes(item.key));
  }

  @property({ type: Boolean, attribute: true, reflect: true }) searchable = false;

  @property({ type: Boolean, attribute: true, reflect: true }) customSearch = false;

  @property({ type: Boolean, attribute: true, reflect: true }) active = false;

  @property({ type: Boolean, attribute: true, reflect: true }) disabled = false;

  @property({ type: Boolean, attribute: true, reflect: true }) hideChips = false;

  @property({ type: Boolean, attribute: true, reflect: true }) singleLine = false;

  @property({ type: String, attribute: true, reflect: true }) status: 'Idle' | 'Loading' | 'Error' = 'Idle';

  @query('input', true)
  _input!: HTMLElement;

  @state()
  searchInput = '';

  @state()
  filtered: SelectItemsProp[] = [];

  @state()
  allSelected = false;

  @state()
  currentSelection: SelectItemsProp[] = [];

  update(_changedProperties: Map<string | number | symbol, unknown>): void {
    this.checkProperties();
    super.update(_changedProperties);
  }

  checkProperties(): void {
    if (!this.value) {
      throw new PropertyRequiredError(this, nameof('value'), this.value);
    }
    if (!this.items) {
      throw new PropertyRequiredError(this, nameof('items'), this.items);
    }
    if (this.status !== 'Loading' && this.items && this.value && !this.value.every(v => this.items.map(x =>
      x.key).includes(v))) {
      throw new PropertyMissmatchError(this, nameof('items'), nameof('value'), this.items, this.value);
    }

  }

  get displayItems(): SelectItemsProp[] {
    return this.customSearch || !this.searchable ? this.items : this.searchInput === ''
      ? this.items
      : this.filtered;
  }

  firstUpdated(_changedProperties: PropertyValues): void {
    super.firstUpdated(_changedProperties);
    document.addEventListener('click', ev => this._onDocumentClick(ev));
    // this.addEventListener('focus', this._onFocus);
    this.addEventListener('blur', this._onBlur);
  }

  disconnectedCallback(): void {
    super.disconnectedCallback();
    document.removeEventListener('click', ev => this._onDocumentClick(ev));
  }

  _dispatchChangeEvent(): void {
    this.dispatchEvent(new LitInputEvent());
    this.dispatchEvent(new LitChangeCustomEvent(this.currentSelection));
  }

  _onDocumentClick(ev: Event): void {
    if (this.active && !this.contains(ev.target as HTMLElement)) {
      this._deactivate();
    }
  }

  _onSelectAll(event: LitChangeEvent): void {
    if ((event.target as HTMLInputElement).value) {
      this.currentSelection = [... this.items];
      this._value = this.currentSelection.map(x => x.key);
    } else {
      this.currentSelection = [];
      this._value = [];
    }
    this._dispatchChangeEvent();

    this.requestUpdate();
  }

  _onSelect(event: Event, item: SelectItemsProp): void {
    if ((event.target as HTMLInputElement).value) {
      this.currentSelection = [... this.currentSelection, item];
      this._value = [...this.value, item.key];
    } else {
      this.currentSelection = this.currentSelection.filter(x => {
        let keep = true;
        const itemToRemove = x.key === item.key ? x : undefined;
        if (itemToRemove && itemToRemove.text === item.text && itemToRemove.tag === item.tag) {
          keep = false;
        }
        return keep;
      });
      this._value = this.currentSelection.map(x => x.key);
    }
    this._dispatchChangeEvent();
    this.requestUpdate();
  }

  _setFilteredItems(): void {
    if (this.searchInput !== '') {
      this.filtered = this.items.filter(x =>
        x.text
          .trim()
          .toLowerCase()
          .includes(this.searchInput.trim().toLowerCase())
      );
    } else {
      this.filtered = [];
    }
  }

  _onSearchChange(event: Event): void {
    this.searchInput = (event.target as HTMLInputElement).value;
    if (this.customSearch) {
      this.dispatchEvent(
        new LitSearchCustomEvent(this.searchInput)
      );
    } else {
      this._setFilteredItems();
      this.requestUpdate();
    }
  }

  _onClearSearch(): void {
    this.searchInput = '';
    if (this.customSearch) {
      this.dispatchEvent(
        new LitSearchCustomEvent(this.searchInput)
      );
    } else {
      if (this.searchInput !== '') {
        this.filtered = this.items.filter(x =>
          x.text
            .trim()
            .toLowerCase()
            .includes(this.searchInput.trim().toLowerCase())
        );
      } else {
        this.filtered = [];
      }
      this.requestUpdate();
    }
  }

  _onClearSelection(ev: Event): void {
    ev.stopPropagation();
    if (!this.active) {
      this.currentSelection = [];
      this._value = [];
    }
    if (this.searchable) {
      this._onClearSearch();
    }
    this._dispatchChangeEvent();
    this.requestUpdate();
  }

  _resultContainerKeyboardEvent(ev: KeyboardEvent): void {
    switch (ev.key) {
      case 'End':
        ev.preventDefault();
        this.lastResultItem?.focus();
        break;
      case 'Home':
        ev.preventDefault();
        this.firstResultItem?.focus();
        break;
    }
  }

  get lastResultItem(): HTMLElement | null {
    const items = Array.from(this.shadowRoot?.querySelectorAll(`.result-item:not([disabled]):not(.hidden)`) ?? []);
    const lastItem = items[items.length - 1];

    if (!lastItem) {
      return null;
    }
    return lastItem as HTMLElement;
  }

  get firstResultItem(): HTMLElement | null {
    const firstItem = Array.from(this.shadowRoot?.querySelectorAll(`.result-item:not([disabled]):not(.hidden)`) ?? [])[0];
    if (!firstItem) {
      return null;
    }
    return firstItem as HTMLElement;
  }

  _getSiblingItem(radios: HTMLElement[], current: HTMLElement) {
    const enabledRadios = radios;
    for (let i = 0; i < enabledRadios.length; i++) {
      if (enabledRadios[i] === current) {
        if (!enabledRadios[i + 1]) {
          return null;
        }
        return enabledRadios[i + 1];
      }
    }
    return null;
  }

  _getNextResultItem(current: HTMLElement) {
    const items = Array.from(this.shadowRoot?.querySelectorAll(`.result-item:not(.hidden)`) ?? []) as HTMLElement[];
    return this._getSiblingItem(items, current);
  }

  _getPreviousResultItem(current: HTMLElement) {
    const items = Array.from(this.shadowRoot?.querySelectorAll(`.result-item:not(.hidden)`) ?? []).reverse() as HTMLElement[];
    return this._getSiblingItem(items, current);
  }

  _onSearchKeyBoardEvent(ev: KeyboardEvent): void {
    switch (ev.key) {
      case 'Down': // IE/Edge specific value
      case 'ArrowDown': {
        ev.preventDefault();
        this.firstResultItem?.focus();
        return;
      }
      case 'Up': // IE/Edge specific value
      case 'ArrowUp': {
        ev.preventDefault();
        this.lastResultItem?.focus();
        return;
      }
    }
  }

  _onKeyBoardEvent(ev: KeyboardEvent): void {
    switch (ev.key) {
      case 'Down': // IE/Edge specific value
      case 'ArrowDown':
      case 'ArrowRight': {
        ev.preventDefault();
        const next = this._getNextResultItem(ev.target as HTMLElement);
        if (!next) {
          if (this.searchable) {
            this._input?.focus();
            return;
          }
          const first = this.firstResultItem;
          first?.focus();
          return;
        }

        next.focus();
        break;
      }
      case 'Up': // IE/Edge specific value
      case 'ArrowUp':
      case 'ArrowLeft': {
        ev.preventDefault();
        const previous = this._getPreviousResultItem(ev.target as HTMLElement);
        if (!previous) {
          if (this.searchable) {
            this._input?.focus();
            return;
          }
          const last = this.lastResultItem;
          last?.focus();
          return;
        }
        previous.focus();
        break;
      }

    }

  }

  _focusFirstSelectItem() {
    setTimeout(() => {
      const firstSelected = this.shadowRoot?.querySelector('.result-item[value]');
      if (firstSelected) {
        (firstSelected as HTMLElement).focus();
      } else {
        this.firstResultItem?.focus();
      }
    }, 0);
  }

  _activate(): void {
    this.setAttribute('active', 'true');
    this.addEventListener('keydown', this._resultContainerKeyboardEvent);

    if (this.searchable) {
      this._onClearSearch();
    }
  }

  _deactivate(): void {
    // (this.shadowRoot?.querySelector('.select-container')as HTMLElement).focus();
    this.removeAttribute('active');
    this.removeEventListener('keydown', this._resultContainerKeyboardEvent);
    this.searchInput = '';
    this.filtered = this.items;
  }

  _onFocus(ev: Event): void {
    if (!this.hasAttribute('active')) {
      this._activate();
      this._focusFirstSelectItem();
    }
  }

  _onBlur(ev: Event): void {
    if (this.hasAttribute('active')) {
      this._deactivate();
    }
  }

  _onChipsDelete(event: CustomEvent): void {
    this.currentSelection = this.currentSelection.filter(
      x => x.key !== event.detail.key
    );
    this._value = this.currentSelection.map(x => x.key);
    this._dispatchChangeEvent();
  }

  focus(options?: FocusOptions): void {
    this._input?.focus(options);
  }

  renderLeftSelectContainer(): TemplateResult {
    return html`
    <div class="select-container-left">
      <input tabindex="-1" class="search-input ${classMap({ 'hidden': !this.searchable })}" type="text"
        ?disabled="${this.disabled || this.readOnly}" .value="${this.searchInput}" placeholder="${this.placeholder}"
        @input="${this._onSearchChange}" @click="${(ev: Event) => {
            ev.stopPropagation();
            this._activate();
          }}" @keydown="${this._onSearchKeyBoardEvent}" />
      <div class="selected-text ${classMap({ 'hidden': this.searchable })}">
        ${this.currentSelection.length > 0
        ? this.currentSelection.map(
        item => html`<span class="selected-item" data-key="${item.key}" data-text="${item.text}">${item.text}</span>`
        )
        : html`${this.placeholder}`}
      </div>
    </div>`;
  }

  renderRightSelectContainer(): TemplateResult {
    return html`
    <div class="select-container-right">
      <div class="${classMap({ 'hidden': !this.searchable || this.status !== 'Loading' })}">
        <md-icon-spinner class="size-xs spinner"></md-icon-spinner>
      </div>
      <div class="select-count ${classMap({ 'hidden': !this.value.length })}">
        + ${this.value.length}
      </div>
      <md-icon-close-no-border
        class="pointer size-xs ${classMap({ 'hidden': (this.active && !this.searchInput) || (!this.active && !this.value.length) })}"
        ?inactive="${this.disabled || this.readOnly}" @click="${this._onClearSelection}"
        @keydown="${(e: KeyboardEvent) => e.key === 'Enter' && this._onClearSelection(e as Event)}" tabindex="0">
      </md-icon-close-no-border>
      <md-icon-dropdown-arrow class="pointer size-s rotate-180 ${classMap({ 'hidden': !this.active })}"
        @click="${(e: Event) => { e.preventDefault(); e.stopPropagation(); this._deactivate(); }}">
      </md-icon-dropdown-arrow>
      <md-icon-dropdown-arrow class="pointer size-s ${classMap({ 'hidden': this.active })}" ?inactive="${this.disabled}"
        @click="${(e: Event) => { e.preventDefault(); e.stopPropagation(); this._activate(); }}">
      </md-icon-dropdown-arrow>
    </div>`;
  }

  renderSelectAllOption(): TemplateResult {
    return when(this.allowSelectAll, () => html`
      <md-checkbox tabindex="-1" @keydown="${this._onKeyBoardEvent}"
        class="result-item static ${classMap({ 'hidden': this.searchInput })}" ?disabled="${this.readOnly}"
        @change="${(ev: CustomEvent) => this._onSelectAll(ev)}">
        Velg alle
      </md-checkbox>`);
  }

  renderResultContainer(): TemplateResult {
    return html`
            <div tabindex="${!this.active ? 0 : -1}" @focus="${this._onFocus}">
              <div class="${classMap({ 'hidden': !this.active })}">
                <div class="result-container ${classMap({ 'alert-border': !!this.invalid })}" id="ifc"
                  ?hidden="${!this.active && this.displayItems.length > 0}">
            
                  ${this.renderSelectAllOption()}
            
                  ${this.displayItems.map((item) => html`
                  <md-checkbox tabindex="-1" @keydown="${this._onKeyBoardEvent}" class="result-item"
                    ?value="${this.currentSelection.map(x => x.key).includes(item.key)}"
                    @change="${(ev: Event) => this._onSelect(ev, item)}" ?disabled="${this.readOnly}">${item.text}</md-checkbox>
                  ` )}
                  <div class="no-result-container ${classMap({ 'hidden': this.displayItems.length })}">
                    <span>Inget resultat</span>
                  </div>
                </div>
              </div>
            </div>
    `;
  }

  render(): TemplateResult {
    return html`
    <div class="wrapper" tabindex="-1">
      <div>
        <div @click="${(e: Event) => { e.preventDefault(); e.stopPropagation(); this._activate(); }}"
          class="select-container ${classMap({ 'alert-border': !!this.invalid })}" ?active="${this.active}">
          ${this.renderLeftSelectContainer()}
          ${this.renderRightSelectContainer()}
        </div>
    
        ${this.renderResultContainer()}
      </div>
    
      <span class="alert-text ${classMap({ 'hidden': !this.invalid })}">${this.invalid}</span>
    
      <md-chips class="${classMap({ 'hidden': this.hideChips || !this.value.length })}"
        .items="${this.currentSelection.map(x => ({ ...x, disabled: this.readOnly }))}" @delete="${this._onChipsDelete}">
      </md-chips>
    </div>
    `;
  }
}
