
import { LitElement, svg, TemplateResult } from 'lit';
import { customElement } from 'lit/decorators.js';
import { BaseMdElement } from '../base-lit-element';
import { iconClass } from '../../directives/icon-class.directive';
import { BaseIcon } from './base-icon.mixin';

// keep in synk global.d.ts
@customElement('md-icon-info')
export class IconInfo extends BaseIcon(BaseMdElement(LitElement)) {

  constructor() {
    super();

    this.smallSvgIcon = svg`
      <svg ${iconClass(this)} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
        <g>
          <path d="M10,2.15A7.84,7.84,0,1,0,17.84,10,7.84,7.84,0,0,0,10,2.15Zm0,14.67A6.84,6.84,0,1,1,16.84,10,6.85,6.85,0,0,1,10,16.82Z"/>
          <rect x="9.44" y="8.59" width="1.13" height="6.04"/>
          <path d="M10,5.62a.75.75,0,1,0,0,1.5.75.75,0,1,0,0-1.5Z"/>
        </g>
      </svg>`;

    this.largeSvgIcon = svg`
      <svg ${iconClass(this)} xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64">
        <g>
          <path d="M32,7.75A24.48,24.48,0,1,0,56.47,32.23,24.5,24.5,0,0,0,32,7.75Zm0,47A22.48,22.48,0,1,1,54.47,32.23,22.5,22.5,0,0,1,32,54.7Z"/>
          <rect x="30.2" y="27.32" width="3.6" height="19.33"/>
          <path d="M32,17.8a2.41,2.41,0,1,0,2.57,2.4A2.46,2.46,0,0,0,32,17.8Z"/>
        </g>
      </svg>`;
  }

  render(): TemplateResult {
    return this.renderIcon();
  }
}
