
import { LitElement, svg, TemplateResult } from 'lit';
import { customElement } from 'lit/decorators.js';
import { BaseMdElement } from '../base-lit-element';
import { iconClass } from '../../directives/icon-class.directive';
import { BaseIcon } from './base-icon.mixin';

// keep in synk global.d.ts
@customElement('md-icon-graph')
export class IconGraph extends BaseIcon(BaseMdElement(LitElement)) {

  constructor() {
    super();

    this.smallSvgIcon = svg`
      <svg ${iconClass(this)} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
        <path d="M12.1,15.6h3.8V8.4H12.1Zm1-6.2h1.8v5.2H13.1Z"/>
        <path d="M14.8,6.67,12.56,5.6l-.67.67V4.45H8.09v5L6.11,8l-.3.26c-1.34,1.09-3.53,2.88-3.71,3l.29.41.27.42c.08-.05.6-.47,1.24-1v4.5H7.7V10.83H4.18l2-1.6,2,1.51v4.81h3.8V7.69l.88-.88L15,7.86,17.9,5.25l-.67-.75ZM4.9,11.83H6.7v2.72H4.9Zm6-6.38V7.27L9.09,9.08V5.45Zm0,9.1H9.09V10.49l1.8-1.8Z"/>
      </svg>`;

    this.largeSvgIcon = svg`
      <svg ${iconClass(this)} xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64">
        <g>
          <path d="M13.11,49.17h11V35.26h-11Zm2-11.91h7v9.91h-7Z"/>
          <path d="M39.93,49.17h11V27.33h-11Zm2-19.84h7V47.17h-7Z"/>
          <path d="M40.77,21.07l7.06,3.36,9.07-8.08-1.33-1.49-8.08,7.19-7.14-3.4-2.87,2.88v-6.7h-11V31.55L19.6,26.22l-.61.5C14.49,30.42,7.61,36,7.1,36.39l.56.83.55.83c.53-.35,8.63-7,11.43-9.28l6.88,5.3v15.1h11V24.36Zm-5.29-4.24v6.7l-7,7V16.83Zm0,30.34h-7V33.33l7-7Z"/>
        </g>
      </svg>`;
  }

  render(): TemplateResult {
    return this.renderIcon();
  }
}
