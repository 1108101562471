import { html, TemplateResult } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { ILitMandatoryFields } from '../../helpers/lit-mandatory-fields';
import { PropertyRequiredError } from '../../helpers/property-required-error';
import { nameofFactory } from '../../helpers/nameof';
import { BaseLitElement } from '../base-lit-element';
import styles from './page-title-style.scss';

const nameof = nameofFactory<PageTitle>();

// keep in synk global.d.ts
@customElement('md-page-title')
export class PageTitle extends BaseLitElement implements ILitMandatoryFields {

  public static styles = styles;

  @property({ type: String, attribute: true, reflect: true })
  value = '';

  update(changedProperties: Map<string | number | symbol, unknown>): void {
    this.checkProperties();

    super.update(changedProperties);
  }

  checkProperties(): void {
    if (!this.value) {
      throw new PropertyRequiredError(this, nameof('value'));
    }
  }

  render(): TemplateResult {
    return html`
      <h1 id="container">
        ${this.value}
      </h1>`;
  }
}
