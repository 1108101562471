<md-page-title value="md-footer | Footer"></md-page-title>

<div class="flex direction-column gap-4 p-3">

  <md-footer logoLink="#" [links]="links"></md-footer>
  <h3>Style layout and component</h3>

  <md-paragraph [value]="explanation"> </md-paragraph>

  <div class="flex direction-column gap-2">
    <h3>Grid responsive layout</h3>

    <md-paragraph [value]="gridExplanation">
    </md-paragraph>
    <pre><code>{{codeExample}}</code></pre>
    <h5>Example:</h5>
    <div class="footer-background">
      <div class="grid-responsive grid-responsive-p screen-wrapper ">
        <div class="col-s-start-0 col-m-start-1 col-start-1 col-s-4 col-m-6 col-10">
          <md-footer class="no-border" logoLink="#" [links]="links"></md-footer>
        </div>
      </div>
    </div>
  </div>

  <div class="flex direction-column gap-2">
    <h3>Grid responsive layout (bad example)</h3>

    <pre><code>{{codeBadExample}}</code></pre>
    <h5>Example:</h5>
    <div class="footer-background-bad-example">
      <div class="grid-responsive grid-responsive-p screen-wrapper ">
        <div class="col-s-start-0 col-m-start-1 col-start-1 col-s-4 col-m-6 col-10">
          <md-footer class="no-border" logoLink="#" [links]="links"></md-footer>
        </div>
      </div>
    </div>
  </div>

  <div class="flex direction-column gap-2">
    <h3>Example change <b>--footer-padding-left</b> and <b>--footer-padding-right</b></h3>

    <pre><code>{{codeExamplePadding}}</code></pre>
    <h5>Example:</h5>
    <md-footer class="footer-padding" logoLink="#" [links]="links"></md-footer>
  </div>
</div>
