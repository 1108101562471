
import { LitElement, svg, TemplateResult } from 'lit';
import { customElement } from 'lit/decorators.js';
import { BaseMdElement } from '../base-lit-element';
import { iconClass } from '../../directives/icon-class.directive';
import { BaseIcon } from './base-icon.mixin';

// keep in synk global.d.ts
@customElement('md-icon-trash-bin')
export class IconTrashBin extends BaseIcon(BaseMdElement(LitElement)) {

  constructor() {
    super();

    this.smallSvgIcon = svg`
      <svg ${iconClass(this)} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
        <g>
          <path d="M14.81,3.84H11.94v-1a.5.5,0,0,0-.5-.5H8.56a.5.5,0,0,0-.5.5v1H5.19a.5.5,0,0,0-.5.5V6.91a.51.51,0,0,0,.5.5h.3v9.75a.5.5,0,0,0,.5.5h8a.5.5,0,0,0,.5-.5V7.41h.3a.51.51,0,0,0,.5-.5V4.34A.5.5,0,0,0,14.81,3.84Zm-5.75-.5h1.88v.5H9.06ZM5.69,4.84h8.62V6.41H5.69Zm7.82,11.82h-7V7.41h7Z"/>
          <path d="M8.56,14.75a.5.5,0,0,0,.5-.5V9.58a.5.5,0,0,0-.5-.5.5.5,0,0,0-.5.5v4.67A.5.5,0,0,0,8.56,14.75Z"/>
          <path d="M11.44,14.75a.5.5,0,0,0,.5-.5V9.58a.5.5,0,0,0-.5-.5.5.5,0,0,0-.5.5v4.67A.5.5,0,0,0,11.44,14.75Z"/>
        </g>
      </svg>`;

    this.largeSvgIcon = svg`
      <svg ${iconClass(this)} xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64">
        <g>
          <path d="M47.38,12.89H37.61V9.09a1,1,0,0,0-1-1H27.39a1,1,0,0,0-1,1v3.8H16.62a1,1,0,0,0-1,1V22.1a1,1,0,0,0,1,1h1.56V54.91a1,1,0,0,0,1,1H44.82a1,1,0,0,0,1-1V23.1h1.56a1,1,0,0,0,1-1V13.89A1,1,0,0,0,47.38,12.89Zm-19-2.8h7.22v2.8H28.39ZM43.82,53.91H20.18V23.1H43.82ZM46.38,21.1H17.62V14.89H46.38Z"/>
          <path d="M27.39,46.61a1,1,0,0,0,1-1V30.66a1,1,0,1,0-2,0v15A1,1,0,0,0,27.39,46.61Z"/>
          <path d="M36.61,46.61a1,1,0,0,0,1-1V30.66a1,1,0,1,0-2,0v15A1,1,0,0,0,36.61,46.61Z"/>
        </g>
      </svg>`;
  }

  render(): TemplateResult {
    return this.renderIcon();
  }
}
