import { html, TemplateResult } from 'lit';
import { customElement, property, query } from 'lit/decorators.js';
import { LitCloseEvent } from '../../helpers/events';
import { BaseLitElement } from '../base-lit-element';
import { nameofFactory } from '../../helpers/nameof';
import styles from './modal-style.scss';
import '../icons/icon-cancel';
import '../icons/icon-close-no-border';

const nameof = nameofFactory<Modal>();

export const modalSize = [
  'size-xs',
  'size-s',
  'size-m',
  'size-l',
  'size-xl',
  'size-xl-auto',
  'size-auto',
] as const;

export const modalColor = [
  'default',
  'secondary'
] as const;
export type ModalSize = typeof modalSize[number];
export type ModalColor = typeof modalColor[number];

// keep in synk global.d.ts
@customElement('md-modal')
export class Modal extends BaseLitElement {

  public static styles = styles;

  @property({ type: Boolean, attribute: true, reflect: true }) open = false;

  @property({ type: Boolean, attribute: true, reflect: true }) error = false;

  @property({ type: Boolean, attribute: true, reflect: true }) hideCloseBtn = false;
  @property({ type: Boolean, attribute: true, reflect: true }) noAutoFocus = false;

  @query('#container', true)
  _container!: HTMLElement;

  close(): void {
    this.dispatchEvent(new LitCloseEvent());
  }

  connectedCallback(): void {
    super.connectedCallback();
    this.addEventListener('keyup', this._keyUp);
  }

  // https://github.com/focus-trap/focus-trap/issues/601 
  // focus-trap@6.8.0 should work with shadow roots

  protected updated(_changedProperties: Map<string | number | symbol, unknown>): void {
    super.updated(_changedProperties);

    if (_changedProperties.has(nameof('open')) && this.open && !this.noAutoFocus) {
      this._container.focus();
    }
  }

  _keyUp(ev: KeyboardEvent) {
    if (this.open && ev.key === 'Escape') {
      this._onClose(ev);
    }
  }

  _onClose(ev: PointerEvent | KeyboardEvent): void {
    ev.preventDefault();
    ev.stopPropagation();
    if (this.hideCloseBtn) {
      return;
    }

    this.dispatchEvent(new LitCloseEvent());
  }

  render(): TemplateResult {
    return html`
      <div class="wrapper ${this.open ? 'open' : ''}" aria-hidden="${!this.open}">
        <div class="overlay" @click="${this._onClose}"></div>
        <div class="dialog" role="dialog" aria-labelledby="title" aria-describedby="content">
          ${this.hideCloseBtn ? null : html` <md-icon-close-no-border id="closeButton" class="pointer size-s"
            aria-label="Close" tabindex="0" @click=${this._onClose}
            @keydown="${(e: KeyboardEvent) => e.key === 'Enter' && this._onClose(e)}">
          </md-icon-close-no-border>`}
          <div class="container" id="container">
            <div class="content">
              <div class="content-title">
                <slot name="modalTitle"></slot>
              </div>
              <slot name="modalContent"></slot>
            </div>
            <slot name="modalButtons"></slot>
          </div>
        </div>
      </div>
          `;
  }
}
