import * as L from 'leaflet';

const blueIcon = new L.DivIcon({
  className: 'my-custom-pin',
  iconAnchor: [12, 20],
  popupAnchor: [0, -36],
  html: `<md-icon-pin class="size-m" style="--icon-color:#1472CE;"></md-icon-pin>`,
});

const goldIcon = new L.DivIcon({
  className: 'my-custom-pin',
  iconAnchor: [12, 20],
  popupAnchor: [0, -36],
  html: `<md-icon-pin class="size-m" style="--icon-color:#ECD898;"></md-icon-pin>`,
});

const redIcon = new L.DivIcon({
  className: 'my-custom-pin',
  iconAnchor: [12, 20],
  popupAnchor: [0, -36],
  html: `<md-icon-pin class="size-m" style="--icon-color:#CA0000;"></md-icon-pin>`,
});

const greenIcon = new L.DivIcon({
  className: 'my-custom-pin',
  iconAnchor: [12, 20],
  popupAnchor: [0, -36],
  html: `<md-icon-pin class="size-m" style="--icon-color:#40C1AC;"></md-icon-pin>`,
});

const orangeIcon = new L.DivIcon({
  className: 'my-custom-pin',
  iconAnchor: [12, 20],
  popupAnchor: [0, -36],
  html: `<md-icon-pin class="size-m" style="--icon-color:#DE8215;"></md-icon-pin>`,
});

const yellowIcon = new L.DivIcon({
  className: 'my-custom-pin',
  iconAnchor: [12, 20],
  popupAnchor: [0, -36],
  html: `<md-icon-pin class="size-m" style="--icon-color:#ECD898;"></md-icon-pin>`,
});

const violetIcon = new L.DivIcon({
  className: 'my-custom-pin',
  iconAnchor: [12, 20],
  popupAnchor: [0, -36],
  html: `<md-icon-pin class="size-m" style="--icon-color:#E6BAA8;"></md-icon-pin>`,
});

const greyIcon = new L.DivIcon({
  className: 'my-custom-pin',
  iconAnchor: [12, 20],
  popupAnchor: [0, -36],
  html: `<md-icon-pin class="size-m" style="--icon-color:#A6A6A6;"></md-icon-pin>`,
});

export const blackIcon = new L.DivIcon({
  className: 'my-custom-pin',
  iconAnchor: [12, 20],
  popupAnchor: [0, -36],
  html: `<md-icon-pin class="size-m" style="--icon-color:#000000;"></md-icon-pin>`,
});

export const leafletIcons: Array<L.DivIcon> = [
  blueIcon,
  greenIcon,
  redIcon,
  orangeIcon,
  yellowIcon,
  goldIcon,
  violetIcon,
  greyIcon,
];
