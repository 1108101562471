
import { LitElement, svg, TemplateResult } from 'lit';
import { customElement } from 'lit/decorators.js';
import { BaseMdElement } from '../base-lit-element';
import { iconClass } from '../../directives/icon-class.directive';
import { BaseIcon } from './base-icon.mixin';

// keep in synk global.d.ts
@customElement('md-icon-help-fill')
export class IconHelpFill extends BaseIcon(BaseMdElement(LitElement)) {

  constructor() {
    super();

    this.smallSvgIcon = svg`
      <svg ${iconClass(this)} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
        <path d="M10,2.16A7.84,7.84,0,1,0,17.84,10,7.85,7.85,0,0,0,10,2.16Zm-.78,12.6a.79.79,0,1,1,.83-.78A.79.79,0,0,1,9.22,14.76Zm1.5-4.17c-.54.17-.93.35-.93,1v.51H8.68v-.51A1.71,1.71,0,0,1,10,9.68l.44-.15a1.56,1.56,0,0,0,1.07-1.44,1.35,1.35,0,0,0-1.41-1.46A1.62,1.62,0,0,0,8.47,8.26H7.4a2.62,2.62,0,0,1,2.66-2.68A2.43,2.43,0,0,1,12.6,8.09,2.62,2.62,0,0,1,10.72,10.59Z"/>
      </svg>`;

    this.largeSvgIcon = svg`
      <svg ${iconClass(this)} xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64">
        <defs>
          <style>
            .cls-1 {
              fill: #1d1d1b;
            }
          </style>
        </defs>
        <path class="cls-1" d="M32,9A23,23,0,1,0,55,32,23,23,0,0,0,32,9ZM30,44a2.06,2.06,0,1,1,2.17-2.06A2.1,2.1,0,0,1,30,44Zm3.92-10.92c-1.42.44-2.43.91-2.43,2.54l0,1.35H28.55l0-1.35c-.07-3,1.38-4.16,3.38-4.9l1.15-.41a4.05,4.05,0,0,0,2.81-3.75,3.53,3.53,0,0,0-3.69-3.82A4.21,4.21,0,0,0,28,27H25.2a6.85,6.85,0,0,1,7-7,6.36,6.36,0,0,1,6.63,6.56A6.85,6.85,0,0,1,33.89,33.08Z"/>
      </svg>`;
  }

  render(): TemplateResult {
    return this.renderIcon();
  }
}
