
import { html, TemplateResult } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';
import { ILitMandatoryFields } from '../../helpers/lit-mandatory-fields';
import { nameofFactory } from '../../helpers/nameof';
import { PropertyRequiredError } from '../../helpers/property-required-error';
import { BaseLitElement } from '../base-lit-element';
import styles from './left-menu-style.scss';
import { AvailableIcons } from '../icons/icons';
import { LitCloseEvent, LitOpenEvent } from '../../helpers/events';
import './left-menu-item';
import '../icons/icon-dropdown-arrow';

export interface MenuLink {
  text: string;
  url?: string;
}

/** A menu item. */
export interface MenuItem extends MenuLink {
  icon: AvailableIcons;
  active?: boolean;
  disabled?: boolean;
  subItems?: SubMenuItem[];
  showSubItems?: boolean;
}

/** A secondary menu item. */
export interface SubMenuItem extends MenuLink {
  active?: boolean;
  disabled?: boolean;
}

const nameof = nameofFactory<LeftMenu>();

/**
 * A component rendering a `nav` left menu.
 *
 * @fires LeftMenu#open
 * @fires LeftMenu#close
 * @fires LeftMenuItem#change
 * */
// keep in synk global.d.ts
@customElement('md-left-menu')
export class LeftMenu extends BaseLitElement implements ILitMandatoryFields {

  public static styles = styles;

  /** [Mandatory] List of {MenuItem} menu items rendering a {LeftMenuItem} `md-left-menu-item`.
   *  Each menu item can have secondary items (children).
  */
  @property({ type: Array, attribute: true, reflect: true })
  items: MenuItem[] = [];

  /** {boolean} Indicate if the menu is collapsed. */
  @property({ type: Boolean, attribute: true, reflect: true })
  collapsed = false;

  update(changedProperties: Map<string | number | symbol, unknown>): void {
    this.checkProperties();

    super.update(changedProperties);
  }

  checkProperties(): void {
    if (!(this.items.length > 0)) {
      throw new PropertyRequiredError(this, nameof('items'), this.items);
    }
  }

  private toggleCollapsed() {
    this.collapsed = !this.collapsed;
    this.requestUpdate();

    if (this.collapsed) {
      this.dispatchEvent(new LitCloseEvent());
    } else {
      this.dispatchEvent(new LitOpenEvent());
    }
  }

  render(): TemplateResult {
    return html`
      <nav class="container ${classMap({ 'collapsed': this.collapsed })}">
        <div role="button" class="collapse" aria-label="collapse">
          <md-icon-dropdown-arrow
            class="size-s primary pointer ${classMap({ 'rotate-270': this.collapsed, 'rotate-90': !this.collapsed, })}"
            @click="${() => this.toggleCollapsed()}"></md-icon-dropdown-arrow>
        </div>
      
        <ul>
          ${this.items.map((item, index, arr) => html`
          <li>
            <md-left-menu-item class="${classMap({ 'first': index === 0, 'last': index === arr.length - 1, })}"
              .item="${item}" .collapsed="${this.collapsed}" .showChildren="${item.showSubItems || false}">
            </md-left-menu-item>
          </li>
          `)}
        </ul>
      </nav>
    `;
  }
}
