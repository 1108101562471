import { Component } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { SectionBarMessageClickDetail } from 'miljodir-wc/dist/src/components/section-bar/section-bar';
import { Item } from 'miljodir-wc/dist/src/components/section-bar/section-bar-item';

@Component({
  selector: 'app-section-bar',
  templateUrl: './section-bar.component.html',
  styleUrls: ['./section-bar.component.scss']
})
export class SectionBarComponent {
  items: Item[] = [
    { key: '1', text: '1, first', },
    { key: '2', text: '2, default' },
    { key: '3', text: '3, selected' },
    { key: '4', text: '4, completed', complete: true },
    { key: '5', text: '5, disabled', disabled: true },

    { key: '-', text: '' },
  ];

  withMessage: Item[] = this.items.map(x => {
    const item: Item = { ...x, message: true };
    return item;
  });

  withMessages: Item[] = this.items.map((x, i) => {
    const item: Item = { ...x, message: true, messages: (i + 1) * 3 };
    return item;
  });

  form = new FormGroup({
    currentPage: new FormControl({ value: '3', disabled: false }),
  });

  handleClick(ev: Event) {
    console.log('Debug angular click event', ev);
  }

  onMessageClick($event: Event) {
    const ev = $event as CustomEvent<SectionBarMessageClickDetail>;
    console.log($event, ev.detail.key, ev.detail.message);
  }

}
