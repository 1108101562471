
import { html, TemplateResult } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { LitHomeClickEvent, LitLogInEvent, LitLogOutEvent, LitOrgClickEvent } from '../../helpers/events';
import { ILitMandatoryFields } from '../../helpers/lit-mandatory-fields';
import { nameofFactory } from '../../helpers/nameof';
import { PropertyRequiredError } from '../../helpers/property-required-error';
import { BaseLitElement } from '../base-lit-element';
import styles from './navigation-header-style.scss';
import '../link-button/link-button';
import '../icons/icon-avatar';
import '../icons/icon-logo';

const nameof = nameofFactory<NavigationHeader>();
export type AuthenticationStatus = 'auth' | 'unauth';

// keep in synk global.d.ts
@customElement('md-navigation-header')
export class NavigationHeader extends BaseLitElement implements ILitMandatoryFields {

  public static styles = styles;

  @property({ type: String, attribute: true, reflect: true })
  header = '';

  @property({ type: String, attribute: true, reflect: true })
  name: string | null = null;

  @property({ type: String, attribute: true, reflect: true })
  org: string | null = null;

  @property({ type: String, attribute: true, reflect: true })
  status: AuthenticationStatus = 'unauth';

  update(changedProperties: Map<string | number | symbol, unknown>): void {
    this.checkProperties();

    super.update(changedProperties);
  }

  checkProperties(): void {
    if (!this.header) {
      throw new PropertyRequiredError(this, nameof('header'), this.header);
    }
    if (this.status === 'auth' && !this.name) {
      throw new PropertyRequiredError(this, nameof('name'), this.name);
    }
  }

  _onLogIn(e: PointerEvent | KeyboardEvent): void {
    // `click` is a bubbling event, so we re-throw a new event manually.
    e.stopPropagation();

    this.dispatchEvent(new LitLogInEvent());
  }

  _onLogOut(e: PointerEvent | KeyboardEvent): void {
    // `click` is a bubbling event, so we re-throw a new event manually.
    e.stopPropagation();

    this.dispatchEvent(new LitLogOutEvent());
  }

  _onLogoClick(e: PointerEvent | KeyboardEvent): void {
    // `click` is a bubbling event, so we re-throw a new event manually.
    e.stopPropagation();

    this.dispatchEvent(new LitHomeClickEvent());
  }

  _onOrgClick(e: PointerEvent | KeyboardEvent) {
    // `click` is a bubbling event, so we re-throw a new event manually.
    e.stopPropagation();

    this.dispatchEvent(new LitOrgClickEvent());
  }

  render(): TemplateResult {
    return html`
            <div class="container">
              <div class="image-title">
                <div id="homeLogo">
                  <md-icon-logo tabindex="0" @click="${(e: PointerEvent) => this._onLogoClick(e)}"
                    @keydown="${(e: KeyboardEvent) => e.key === 'Enter' && this._onLogoClick(e)}"></md-icon-logo>
                </div>
                <div>
                  <h3>${this.header}</h3>
                </div>
              </div>
              ${this.status === 'auth' ? html`<div class="user-container">
                <md-icon-avatar class="size-xl"></md-icon-avatar>
                <div class="user-info">
                  <span>${this.name}</span>
                  <md-link-button id="org" @click="${(e: PointerEvent) => this._onOrgClick(e)}"
                    @keydown="${(e: KeyboardEvent) => e.key === 'Enter' && this._onOrgClick(e)}">${this.org}</md-link-button>
                  <md-link-button id="logout" @click="${(e: PointerEvent) => this._onLogOut(e)}"
                    @keydown="${(e: KeyboardEvent) => e.key === 'Enter' && this._onLogOut(e)}">Logg ut</md-link-button>
                </div>
              </div>` : html`<md-link-button id="login" @click="${(e: PointerEvent) => this._onLogIn(e)}"
                @keydown="${(e: KeyboardEvent) => e.key === 'Enter' && this._onLogIn(e)}">Logg inn
              </md-link-button>`}
            </div>
          `;
  }
}
