<md-page-title value="md-icon* | Icons"></md-page-title>

<div class="flex direction-column gap-4 p-3">

  <div id="icons" #icons class="flex wrap row-direction"></div>

  <h3>width 100%</h3>
  <div id="w100-icons" class="flex wrap row-direction">
    <p>
      100%: <md-icon-spinner class="w-100"></md-icon-spinner>
    </p>
  </div>

  <h3>Spinner</h3>
  <div id="spinner-icons" class="flex wrap row-direction">
    <p>
      <md-icon-spinner class="size-m spinner"></md-icon-spinner>
    </p>
  </div>

  <h3>Sorting icons</h3>
  <div id="sorting-icons" class="flex wrap row-direction">
    <p>
      Default: <md-icon-sorting class="size-m"></md-icon-sorting>
    </p>
    <p>
      None: <md-icon-sorting class="size-m" direction="none"></md-icon-sorting>
    </p>
    <p>
      Asc: <md-icon-sorting class="size-xs" direction="asc"></md-icon-sorting>
    </p>
    <p>
      Desc: <md-icon-sorting class="size-xs" direction="desc"></md-icon-sorting>
    </p>
  </div>

  <h3>Named icons</h3>
  <div id="named-icons" #namedicons class="flex wrap row-direction"></div>
</div>
