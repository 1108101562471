/**
 * 
 * @param attributes Element's attributes.
 * @param excludedAttributes Array of attribute names to exclude from the returned string.
 * @returns A string concatenated list of attributes.
 * 
 * @example
 * <div attr1="a" attr2 attr3=""></div>
 * returns `attr1="a" attr2 attr3`
 */
export const attributesAsString = (attributes: NamedNodeMap, excludedAttributes: string[] = []): string => {
  const attrs: Attr[] = [];
  for (let i = 0; i < attributes.length; i++) {
    const attr = attributes[i];
    if (excludedAttributes.includes(attr.name)) {
      continue;
    }
    attrs.push(attr);
  }
  const result = attrs.map(attr => attr.value ? `${attr.name}="${attr.value}"` : attr.name).join(' ');
  return result;
};
