import { html, TemplateResult } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { unsafeHTML } from 'lit/directives/unsafe-html.js';
import { BaseLitElement } from '../base-lit-element';
import styles from './paragraph-style.scss';

// keep in synk global.d.ts
@customElement('md-paragraph')
export class Paragraph extends BaseLitElement {

  @property({ type: String, attribute: true, reflect: true })
  value = '';

  public static styles = styles;

  render(): TemplateResult {
    return html`
      <div id="container">${unsafeHTML(this.value)}<slot></slot>
      </div>`;
  }
}
