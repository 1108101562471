<md-page-title value="Scss variables"></md-page-title>

<div class="flex direction-column gap-4 p-3">
  <p>NB. Run the NPM script "scss-to-json" to update with the latest values.</p>

  <table class="table">
    <caption>{{data.length}} treff</caption>
    <thead>
      <tr>
        <th>Name</th>
        <th>Value</th>
        <th>Compiled value</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let value of data">
        <td>{{value.name}}</td>
        <td>{{value.value}}</td>
        <td>{{value.compiledValue}}</td>
        <td>
          <ng-container *ngIf="isValidColor(value.compiledValue)">
            <div class="color-block" [style.background-color]="value.compiledValue">&nbsp;</div>
          </ng-container>
        </td>
      </tr>
    </tbody>
  </table>
</div>
