import type { StrictModifiers } from '@popperjs/core';
import { createPopper, Instance } from '@popperjs/core';
import { html, PropertyValues, TemplateResult } from 'lit';
import { customElement, property, query } from 'lit/decorators.js';
import { BaseLitElement } from '../base-lit-element';
import { classMap } from 'lit/directives/class-map.js';
import { nameofFactory } from '../../helpers/nameof';
import styles from './context-menu-style.scss';
import '../icons/icon-sub-menu';

// TODO: this component is not keyboard-accessible

const nameof = nameofFactory<ContextMenu>();

// keep in synk global.d.ts
@customElement('md-context-menu')
export class ContextMenu extends BaseLitElement {

  static styles = [styles];

  @property({ type: Boolean, attribute: true, reflect: true }) visible = true;

  @query('#ctxMenu', true)
  _dialog!: HTMLElement;

  @query('#icon', true)
  _icon!: HTMLElement;

  _popperInstance: Instance | null = null;

  showEvents = ['click', 'keydown'];
  hideEvents = ['mouseleave', 'blur'];

  firstUpdated(_changedProperties: PropertyValues): void {
    super.firstUpdated(_changedProperties);

    this._popperInstance = createPopper<StrictModifiers>(this._icon, this._dialog, {
      placement: 'right-start',
    });
    this.attachEventListenersToMultipleAnchors(this._icon, this);
  }

  disconnectedCallback(): void {
    if (this._icon) {
      // element could be unmounted in testing platform (Angular)
      this.removeEventListenersFromMultipleAnchors(this._icon, this);
    }
    super.disconnectedCallback();
  }

  attachEventListenersToMultipleAnchors(_showAnchor: HTMLElement, _hideAnchor: HTMLElement): void {
    this.showEvents.forEach(event => {
      _showAnchor.addEventListener(event, (event: Event) => this.show(event, this._dialog));
    });
    this.hideEvents.forEach(event => {
      _hideAnchor.addEventListener(event, (event: Event) => this.hide(event, this._dialog));
    });
  }

  removeEventListenersFromMultipleAnchors(showAnchor: HTMLElement, hideAnchor: HTMLElement): void {
    this.showEvents.forEach(event => {
      showAnchor.removeEventListener(event, (event: Event) => this.show(event, this._dialog));
    });
    this.hideEvents.forEach(event => {
      hideAnchor.removeEventListener(event, (event: Event) => this.hide(event, this._dialog));
    });
  }

  show(event: Event, dialog: HTMLElement): void {
    event.preventDefault();
    event.stopPropagation();

    // Make the tooltip visible
    dialog.setAttribute('data-show', '');

    // Enable the event listeners
    this._popperInstance?.setOptions({
      modifiers: [{ name: 'eventListeners', enabled: true }],
    });

    // Update its position
    this._popperInstance?.update();
  }

  hide(event: Event, dialog: HTMLElement): void {
    event.preventDefault();
    event.stopPropagation();

    // Hide the tooltip
    dialog.removeAttribute('data-show');

    // Disable the event listeners
    this._popperInstance?.setOptions({
      modifiers: [{ name: 'eventListeners', enabled: false }],
    });
  }

  onPreventClick(event: Event): void {
    event.stopPropagation();
    event.preventDefault();
  }

  _handleSlotChange(e: Event) {
    // if no items are visible/enabled, the container should be hidden as no items are selectable.

    const prev = this.visible;
    const slot = e.target as HTMLSlotElement | undefined;
    const activeItems = slot?.assignedElements()
      .filter(x =>
        x.matches('md-context-menu-item') &&
        !x.hasAttribute('disabled'))
      || [];
    this.visible = activeItems.length > 0;
    this.requestUpdate(nameof('visible'), prev);
  }

  render(): TemplateResult {
    return html`
      <md-icon-sub-menu id="icon" class="pointer ${classMap({ visible: this.visible })}"></md-icon-sub-menu>
      <div id="ctxMenu" class="dialog ${classMap({ visible: this.visible })}"
        @click="${(e: Event) => this.onPreventClick(e)}">
        <div class="context-menu-container">
          <slot id="slot" @slotchange=${this._handleSlotChange}></slot>
        </div>
        <div id="arrow" data-popper-arrow></div>
      </div>
    `;
  }
}
