import { html, TemplateResult } from 'lit';
import { customElement, property, query } from 'lit/decorators.js';
import { LitChangeEvent, LitInputEvent } from '../../helpers/events';
import { BaseLitElement } from '../base-lit-element';
import { ILitFocusable } from '../focusable';
import styles from './text-area-style.scss';

// keep in synk global.d.ts
@customElement('md-text-area')
export class TextArea extends BaseLitElement implements ILitFocusable {
  static styles = [styles];

  @property({ type: String, attribute: true, reflect: true }) invalid?: string;
  @property({ type: String, attribute: true, reflect: true }) placeholder = '';
  @property({ type: Boolean, attribute: true, reflect: true }) readOnly = false;
  @property({ type: Boolean, attribute: true, reflect: true }) noBorder = false;

  @property({ type: Boolean, attribute: true, reflect: true }) resize = false;
  @property({ type: Number, attribute: true, reflect: true }) rows = 6;

  @property({ type: String, attribute: true, reflect: true }) value = '';

  _onChange(event: Event): void {
    event.stopPropagation();
    this.value = (event.target as HTMLInputElement).value;
    this._dispatchChange();
  }

  _dispatchChange(): void {
    this.dispatchEvent(new LitInputEvent());
    this.dispatchEvent(new LitChangeEvent());
  }

  @query('textarea', true) _inputNode!: HTMLInputElement;

  focus(options?: FocusOptions): void {
    this._inputNode.focus(options);
  }

  renderComponent(): TemplateResult {
    return html`
        <textarea type="text" aria-label="md-text-area" rows=${this.rows} ?resize=${this.resize} .value=${this.value}
          placeholder=${this.placeholder} @input=${this._onChange} class=${this.invalid ? 'alert-border' : ''}
          ?disabled=${this.readOnly}></textarea>
      `;
  }

  render(): TemplateResult {
    return html`
        ${this.renderComponent()}
        ${this.invalid && html`<span class="alert-text">${this.invalid}</span>`}
      `;
  }
}
