
import { LitElement, svg, TemplateResult } from 'lit';
import { customElement } from 'lit/decorators.js';
import { BaseMdElement } from '../base-lit-element';
import { iconClass } from '../../directives/icon-class.directive';
import { BaseIcon } from './base-icon.mixin';

// keep in synk global.d.ts
@customElement('md-icon-logo')
export class IconLogo extends BaseIcon(BaseMdElement(LitElement)) {

  constructor() {
    super();

    // src\assets\ikoner\logo.svg
    this.smallSvgIcon = svg`
      <svg ${iconClass(this, [])} xmlns="http://www.w3.org/2000/svg" width="156.46" height="46.035" viewBox="0 0 156.46 46.035">
        <path id="Path_4349" data-name="Path 4349" d="M30.69,0,23.026,7.664,15.345,0H0V46.035H15.345V40.92H30.69v5.115H46.052V0Z" fill="#005e5d"/>
        <path id="Path_4350" data-name="Path 4350" d="M447.857,171.07h-1.891V167.78h-1.842v3.289h-1.579v1.546h1.579v7.253h1.842v-7.253h1.891Zm-13.5,3.651c.345-2.862,4.934-2.829,5.181,0Zm7.023.707c.2-6.036-9.1-5.937-8.947.016-.23,4.786,6.447,6.266,8.569,2.385l-1.464-.74c-1.168,1.924-4.934,1.612-5.2-.888h7.006c.016-.263.033-.526.033-.773m-9.967-4.358h-1.891V167.78h-1.842v3.289H426.1v1.546h1.579v7.253h1.842v-7.253h1.891Zm-11.891,7.237c-3.536.115-3.569-5.822,0-5.674,3.618-.1,3.7,5.756,0,5.674m2.829-7.237v1.3c-2.253-3.108-7.631-1.069-7.4,3.092-.214,4.128,5.115,6.25,7.4,3.092v1.316h1.842v-8.8Zm-9.7-.181c-1.135,0-2.023.625-2.467,2.171v-1.974h-1.859v8.783h1.859c-.115-2.73-.28-8.766,3.651-6.908l.312-1.76a3.391,3.391,0,0,0-1.5-.312m-10.477,7.418c-3.569.164-3.618-5.855,0-5.691,3.618-.164,3.585,5.855,0,5.691m0,1.76c6.053.132,6.1-9.342,0-9.177-6.085-.181-6.036,9.309,0,9.177m-5.641-9h-1.891V167.78h-1.842v3.289h-1.579v1.546h1.579v7.253h1.842v-7.253h1.891Zm-5.674,8.8-4.572-5,3.717-3.8h-2.5l-2.878,2.993V166.3h-1.842v13.569h1.842v-4.178l3.849,4.178Zm-17.269-5.148c.345-2.862,4.934-2.829,5.181,0Zm7.023.707c.2-6.036-9.1-5.937-8.947.016-.23,4.786,6.447,6.266,8.569,2.385l-1.464-.74c-1.168,1.924-4.934,1.612-5.2-.888h7.006c.016-.263.033-.526.033-.773m-10.888-4.539c-1.135,0-2.023.625-2.467,2.171v-1.974h-1.858v8.783h1.858c-.115-2.73-.28-8.766,3.651-6.908l.312-1.76a3.391,3.391,0,0,0-1.5-.312m-8.947,8.98h1.842v-8.8H360.77Zm.921-10.806a1.185,1.185,0,1,0,0-2.368,1.185,1.185,0,1,0,0,2.368m-8.355,9.243c-3.536.115-3.569-5.822,0-5.674,3.618-.1,3.717,5.756,0,5.674m2.829-12.006v6.069c-2.253-3.108-7.631-1.069-7.4,3.092-.214,4.128,5.115,6.25,7.4,3.092v1.316h1.842V166.3Z" transform="translate(-291.396 -138.949)" fill="#005e5d"/>
        <path id="Path_4351" data-name="Path 4351" d="M464.646,56.633H470.6v-1.76h-5.954Zm-3.816-.888a2.786,2.786,0,0,1-4.227,2.467l3.849-3.964a2.975,2.975,0,0,1,.378,1.5m-5.543,0a2.776,2.776,0,0,1,4.309-2.368l-3.865,3.964a3.077,3.077,0,0,1-.444-1.6m7.418-3.783-.888-.855-.921.937c-4.21-3.158-9.885,2.418-6.464,6.628l-1.053,1.069.888.872,1.069-1.086c4.161,3.059,9.671-2.451,6.447-6.612ZM446.784,64.445c2.681.329,4.589-.937,4.424-3.832V51.37h-1.842V60.6c.148,1.71-.9,2.319-2.582,2.105Zm3.5-15.1a1.185,1.185,0,1,0,0-2.368,1.185,1.185,0,1,0,0,2.368M444.6,60.169h1.842V46.6H444.6Zm-4.622,0h1.842v-8.8h-1.842Zm.921-10.822a1.185,1.185,0,1,0,0-2.368,1.185,1.185,0,1,0,0,2.368" transform="translate(-367.361 -38.936)" fill="#005e5d"/>
        <path id="Path_4352" data-name="Path 4352" d="M362.5,60.169V46.6h-1.727L356.3,55.942,351.843,46.6H350.1V60.169h1.842V50.893l3.553,7.253h1.661l3.5-7.237v9.26Z" transform="translate(-292.52 -38.936)" fill="#005e5d"/>
      </svg>`;

    this.largeSvgIcon = this.smallSvgIcon;
  }

  render(): TemplateResult {
    return this.renderIcon();
  }

}
