<md-page-title value="md-text-field | Input text field"></md-page-title>

<div class="flex direction-column gap-4 p-3" [formGroup]="form">

  <h3>Type text </h3>
  <md-text-field placeholder="Fornavn"></md-text-field>

  <h3>Type text - fixed width</h3>
  <md-text-field placeholder="Fornavn" style="width: 250px"></md-text-field>

  <h3>Type text with validation</h3>
  <md-text-field formControlName="firstName" ngDefaultControl placeholder="Fornavn" style="width: 250px" [invalid]="
              form.controls.firstName.errors?.forbiddenName
                ? 'Navn kan ikke inneholde bob'
                : null
            " (blur)="onBlur()"></md-text-field>

  <h3>Invalid</h3>
  <md-text-field placeholder="Fornavn" invalid="invalid"></md-text-field>

  <h3>Readonly</h3>
  <md-text-field value="Read-only" [invalid]="
                      form.controls.lastName.errors?.forbiddenName
                        ? 'Navn kan ikke vare bob'
                        : null
                    " readOnly required></md-text-field>

  <h3>Type number (see <em>md-numeric-field</em>)</h3>
  <md-numeric-field value=""></md-numeric-field>

  <h3>NoBorder | true</h3>
  <md-text-field type="text" value="" [noBorder]="true" placeholder="noBorder = true"></md-text-field>

  {{ form.value | json }}

</div>

<div class="flex direction-column p-3">
  <p>Outside Angular formGroup</p>

  <md-text-field ngDefaultControl placeholder="Søk i komponenter (no custom width set)" maxlength="50"
    [ngModel]="searchText" (ngModelChange)="textChanged($event)" (blur)="onBlur()"></md-text-field>
  {{ searchText | json }}

  <md-text-field ngDefaultControl placeholder="noBorder = true" [noBorder]="true"></md-text-field>
</div>
