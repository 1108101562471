<md-page-title value="md-accordion-list & md-accordion-card"></md-page-title>

<div class="flex direction-column gap-4 p-4">

  <details>
    <summary>The component supports <em>keyboard navigation</em> based on <a
        href="https://www.w3.org/TR/wai-aria-practices-1.1/examples/accordion/accordion.html" target="_blank">w3.org
        accordion reference</a>.
    </summary>

    <dl>
      <dt>Space or Enter</dt>
      <dd>When focus is on the accordion header of a collapsed section, expands the section.</dd>

      <dt>Tab</dt>
      <dd>Moves focus to the next focusable element. All focusable elements in the accordion are included in the page
        Tab sequence.</dd>

      <dt>Shift + Tab</dt>
      <dd>Moves focus to the previous focusable element. All focusable elements in the accordion are included in the
        page Tab sequence.</dd>

      <dt>Down Arrow</dt>
      <dd>When focus is on an accordion header, moves focus to the next accordion header. When focus is on last
        accordion header, moves focus to first accordion header.</dd>

      <dt>Up Arrow</dt>
      <dd>When focus is on an accordion header, moves focus to the previous accordion header. When focus is on first
        accordion header, moves focus to last accordion header.</dd>

      <dt>Home</dt>
      <dd>When focus is on an accordion header, moves focus to the first accordion header.</dd>

      <dt>End</dt>
      <dd>When focus is on an accordion header, moves focus to the last accordion header.</dd>
    </dl>
  </details>

  <h3>Singlepanel (Default)</h3>

  <md-paragraph>Only one accordion can be open at a single time, equals to <em>multipanel="false"</em>.
  </md-paragraph>

  <md-accordion-list>

    <md-accordion-card header="Can focus via keyboard navigation to the first focusable inner elements">
      {{text}}

      <!-- inner element -->
      <div class="grid-responsive gap-4">

        <!-- a deeper inner element -->
        <div>
          <a href="">a link not focusable</a>

          <md-form-field label="A form field">
            <!-- anf then a focusable element -->
            <md-text-area style="width: 350px"> </md-text-area>
          </md-form-field>
        </div>
      </div>

      <md-text-area style="width: 350px"> </md-text-area>
    </md-accordion-card>

    <md-accordion-card header="Default">
      {{text}}
    </md-accordion-card>

    <md-accordion-card header="Default">
      {{text}}
    </md-accordion-card>
  </md-accordion-list>

  <h3>Multipanel</h3>

  <md-paragraph>If multiple accordions could be opened, set the attribute <em>multiPanel="true"</em>
  </md-paragraph>

  <md-accordion-list [multiPanel]="true">
    <md-accordion-card header="Default">
      {{text}}
    </md-accordion-card>
    <md-accordion-card header="Default">
      {{text}}

      <md-text-area style="width: 350px"> </md-text-area>

      <md-text-area style="width: 350px"> </md-text-area>
    </md-accordion-card>
    <md-accordion-card header="Default">
      {{text}}
    </md-accordion-card>
  </md-accordion-list>

  <h3>Configurations</h3>

  <md-accordion-list [multiPanel]="false">
    <md-accordion-card header="Default">
      {{text}}
    </md-accordion-card>
    <md-accordion-card header="Expanded" expanded>
      {{text}}
    </md-accordion-card>

    <md-accordion-card header="DestroyOnHidden" [destroyOnHidden]="true">
      {{text}}
    </md-accordion-card>

    <md-accordion-card header="Width='fit content'" class="flex column-direction">
      {{text}}
    </md-accordion-card>

    <md-accordion-card header="Longer Title: {{text}}">
      default
    </md-accordion-card>

    <md-accordion-card header="status == 'default'" [status]="statusDefault">
      {{text}}
    </md-accordion-card>

    <md-accordion-card header="status == 'done'" [status]="statusDone">
      {{text}}
    </md-accordion-card>

    <md-accordion-card header="status == 'warning'" [status]="statusWarning">
      {{text}}
    </md-accordion-card>

    <md-accordion-card header="status == 'error'" [status]="statusError">
      {{text}}
    </md-accordion-card>

    <md-accordion-card header="Static" static="true">
      {{text}}
    </md-accordion-card>
  </md-accordion-list>

  <h3>Standalone</h3>

  <md-accordion-card (toggle)="standAloneOpen = !standAloneOpen" [expanded]="standAloneOpen" header="Standalone">
    {{text}}
  </md-accordion-card>

  <md-accordion-card [static]="true" header="">
    Static without header
  </md-accordion-card>

  <h3>Accordion in accordion</h3>

  <md-accordion-list>
    <md-accordion-card header="Outer" expanded>

      <md-button class="secondary">
        <md-icon-plus class="size-m"></md-icon-plus> Click here
      </md-button>
      <md-accordion-list>
        <md-accordion-card header="Inner" expanded>

          <md-button class="secondary">
            <md-icon-plus class="size-m"></md-icon-plus> Click here
          </md-button>

        </md-accordion-card>

      </md-accordion-list>
    </md-accordion-card>

  </md-accordion-list>
</div>
