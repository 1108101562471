<md-page-title value="Page not found template"></md-page-title>

<div class="flex direction-column gap-4 p-3">

  <!-- Template | start -->
  <div class="grid-responsive grid-responsive-p py-5">
    <div class="col-s-start-0 col-m-start-1 col-start-1 col-5 col-s-4 col-m-6 flex direction-column">
      <img style="max-width: 250px;" src="/assets/images/error_404.svg">
      <h3>
        Siden du leter etter ble ikke funnet.
      </h3>

      <md-button (click)="gotoHome()" class="secondary">
        <md-icon-chevron class="size-l rotate-180"></md-icon-chevron> Gå til startside
      </md-button>
    </div>
  </div>
  <!-- Template | end -->

</div>
