<md-page-title value="md-select-multiple | Multiple select"></md-page-title>

<div class="flex direction-column gap-4 p-3 scroll-auto" [formGroup]="form">
  <!-- Multiple select example -->

  <!-- Example | Single line (container doesn't expand view) -->
  <md-form-field for="noSearch" label="Single line (container doesn't expand view)">
    <md-select-multiple style="width: 250px; --select-max-height: 400px" [items]="itemsSingleLine"
      formControlName="singleLine" placeholder="Velg grønnsak" singleLine ngDefaultControl>
    </md-select-multiple>
  </md-form-field>
  {{ form.value.singleLine | json }}

  <!-- Example | Invalid  -->
  <md-form-field for="invalid" label="Invalid">
    <md-select-multiple [items]="itemsSingleLine" placeholder="Velg..." invalid="Invalid">
    </md-select-multiple>
  </md-form-field>

  <!-- Example | allowSelectAll  -->
  <md-form-field for="allowSelectAll" label="Allow SelectAll/Velg alle">
    <md-select-multiple [items]="itemsSingleLine" placeholder="Velg..." allowSelectAll="true">
    </md-select-multiple>
  </md-form-field>

  <!-- Example | No search, no status attribute is needed  -->
  <md-form-field for="noSearch" label="No search, no status attribute is needed">
    <md-select-multiple style="width: 250px; --select-max-height: 400px" [items]="itemsNoSearch"
      formControlName="noSearch" placeholder="Velg grønnsak" ngDefaultControl>
    </md-select-multiple>
  </md-form-field>
  {{ form.value.noSearch | json }}

  <!-- Example | Simple search in component, no status attribute is needed  -->
  <md-form-field for="componentSearch" label="Simple search in component, no status attribute is needed">
    <md-select-multiple style="width: 250px; --select-max-height: 400px" [items]="itemsComponentSearch"
      formControlName="componentSearch" placeholder="Velg grønnsak" ngDefaultControl searchable>
    </md-select-multiple>
  </md-form-field>
  {{ form.value.componentSearch | json }}

  <!-- Example | Search on static data, no status attribute is needed   -->
  <md-form-field for="searchOnStaticData" label="Search on static data, no status attribute is needed">
    <md-select-multiple id="searchOnStaticData" style="width: 250px; --select-max-height: 400px"
      [items]="searchOnStaticData" formControlName="searchOnStaticData" placeholder="Velg grønnsak" ngDefaultControl
      searchable customSearch (search)="searchStaticData($event)">
    </md-select-multiple>
    {{ form.value.searchOnStaticData | json }}
  </md-form-field>

  <!-- Example | Search on application data, f.eks api, status is needed  -->
  <md-form-field for="searchCustom" label="Search on application data, f.eks api, status is needed">
    <md-select-multiple id="searchCustom" style="width: 250px; --select-max-height: 400px" [items]="searchCustom"
      formControlName="searchCustom" placeholder="Velg grønnsak" [status]="statusSearchCustom" searchable customSearch
      (search)="searchApiCall($event)" ngDefaultControl>
    </md-select-multiple>
    {{ form.value.searchCustom | json }}
  </md-form-field>

  <!-- Example | Add extra info via tag property  -->
  <md-form-field for="cmbItemTag" label="Add extra info via tag property">
    <md-select-multiple #cmbItemTag [items]="itemsWithTag" placeholder="Velg..." (change)="onTagItemChanged($event)">
    </md-select-multiple>
    {{ selectedTagTypes | json }}
  </md-form-field>


</div>
