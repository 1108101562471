
import { LitElement, svg, TemplateResult } from 'lit';
import { customElement } from 'lit/decorators.js';
import { BaseMdElement } from '../base-lit-element';
import { iconClass } from '../../directives/icon-class.directive';
import { BaseIcon } from './base-icon.mixin';

// keep in synk global.d.ts
@customElement('md-icon-zoom')
export class IconZoom extends BaseIcon(BaseMdElement(LitElement)) {

  constructor() {
    super();

    // src\assets\ikoner\20px\icon_20px_zoom_default.svg
    this.smallSvgIcon = svg`
      <svg ${iconClass(this)} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
        <path d="M16.83,15.12l-3.9-4-.62.62-1.17-1.22a5,5,0,0,0,1.26-3.3A4.86,4.86,0,0,0,7.62,2.3,4.86,4.86,0,0,0,2.84,7.23a4.86,4.86,0,0,0,4.78,4.93,4.6,4.6,0,0,0,2.78-.95l1.2,1.24.36-.34-1,1,3.9,4.06a1.35,1.35,0,0,0,1.9,0l0,0A1.43,1.43,0,0,0,16.83,15.12Zm-13-7.89A3.86,3.86,0,0,1,7.62,3.3,3.87,3.87,0,0,1,11.4,7.23a3.86,3.86,0,0,1-3.78,3.93A3.86,3.86,0,0,1,3.84,7.23Z"/>
      </svg>`;

    // src\assets\ikoner\64px\64px_zoom_default.svg
    this.largeSvgIcon = svg`
      <svg ${iconClass(this)} xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64">
        <path d="M53.41,48.31,40.93,35.56,38.45,38,34.28,33.7a15.26,15.26,0,0,0,4.25-10.62A14.86,14.86,0,0,0,23.92,8,14.86,14.86,0,0,0,9.31,23.08,14.87,14.87,0,0,0,23.92,38.16a14.3,14.3,0,0,0,9-3.23l4.22,4.39.65-.63-3,3.05,12.48,13a4.29,4.29,0,0,0,6.07.06l.1-.1A4.58,4.58,0,0,0,53.41,48.31ZM11.14,23.08A13,13,0,0,1,23.92,9.83,13,13,0,0,1,36.71,23.08,13,13,0,0,1,23.92,36.33,13,13,0,0,1,11.14,23.08Z"/>
      </svg>`;
  }

  render(): TemplateResult {
    return this.renderIcon();
  }
}
