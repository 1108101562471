import { AfterViewInit, Component, ElementRef, Renderer2, ViewChild } from '@angular/core';
import { allAvailableIcons } from 'miljodir-wc/dist/src/components/icons/icons';
import { Icon } from 'miljodir-wc/dist/src/index';

interface Attribute {
  name: string;
  value: string;
}

@Component({
  selector: 'app-icons',
  templateUrl: './icons.component.html',
  styleUrls: ['./icons.component.scss']
})
export class IconsComponent implements AfterViewInit {

  @ViewChild('icons')
  iconsContainer!: ElementRef;

  @ViewChild('namedicons')
  namedIconsContainer!: ElementRef;

  constructor(
    private renderer: Renderer2
  ) { }

  tags = allAvailableIcons;

  sizes = [
    'size-xs',
    'size-s',
    'size-m',
    'size-l',
    'size-xl',

    // 'w-100',
  ];

  utils = [
    'pointer',
    'primary',
    'secondary',
    'white',

    'success',
    'warning',
    'error',

    'rotate-90',
    'rotate-180',
    'rotate-270',

    // 'spinner',
  ];

  styles: Attribute[] = [
    { name: '--icon-color', value: 'pink' }
  ];

  attrs: Attribute[] = [
    { name: 'inactive', value: 'true' },
  ];

  ngAfterViewInit() {
    this.generateIcons();

    this.generateNamedIcons();
  }

  private generateIcons() {
    this.tags.forEach(tag => {
      const row = this.renderer.createElement('div') as HTMLElement;
      this.renderer.addClass(row, 'flex');
      this.renderer.addClass(row, 'wrap');
      this.renderer.addClass(row, 'row-direction');
      this.renderer.addClass(row, 'border');
      this.renderer.appendChild(row, this.renderer.createText(tag));

      this.renderer.appendChild(this.iconsContainer.nativeElement, row);

      this.appendElement(row, tag, null, null, null, null);

      this.sizes.forEach(size => {
        this.appendElement(row, tag, size, null, null, null);

        this.utils.forEach(util => {
          this.appendElement(row, tag, size, util, null, null);
        });

        this.attrs.forEach(attr => {
          this.appendElement(row, tag, size, null, attr, null);
        });

        this.styles.forEach(style => {
          this.appendElement(row, tag, size, null, null, style);
        });
      });
    });
  }

  private generateNamedIcons() {
    const row = this.renderer.createElement('div') as HTMLElement;
    this.renderer.addClass(row, 'flex');
    this.renderer.addClass(row, 'wrap');
    this.renderer.addClass(row, 'row-direction');
    this.renderer.addClass(row, 'border');
    this.renderer.appendChild(this.namedIconsContainer.nativeElement, row);

    this.tags.forEach(tag => {
      this.appendElement(row, tag, 'size-m', null, null, null);
    });
  }

  private appendElement(parentElement: HTMLElement, tagName: string, size: string | null, util: string | null, attribute: Attribute | null, style: Attribute | null) {
    // Use Angular's Renderer2 to create the div element
    const el = this.renderer.createElement(tagName) as HTMLElement;
    this.appendTitle(el, tagName);

    this.renderer.setAttribute(el, 'tabindex', '0');

    if (size) {
      this.renderer.addClass(el, size);
      this.appendTitle(el, size);
    }

    if (util) {
      this.renderer.addClass(el, util);
      this.appendTitle(el, util);
    }

    if (attribute) {
      this.renderer.setAttribute(el, attribute.name, attribute.value);
      this.appendTitle(el, `${attribute.name}:${attribute.value}`);
    }

    if (style) {
      this.renderer.setStyle(el, style.name, style.value, 2);
      this.appendTitle(el, `${style.name}:${style.value}`);
    }

    el.onclick = (ev: MouseEvent) => {
      console.log('clicked', (ev.target as Icon).localName);
    };

    // Append the created div to the parent element
    this.renderer.appendChild(parentElement, el);

    return el;
  }

  private appendTitle(el: HTMLElement, title: string) {
    const value = el.title ? `${el.title} ${title}` : title;
    this.renderer.setProperty(el, 'title', value);
  }
}
