
import { LitElement, svg, TemplateResult } from 'lit';
import { customElement } from 'lit/decorators.js';
import { BaseMdElement } from '../base-lit-element';
import { iconClass } from '../../directives/icon-class.directive';
import { BaseIcon } from './base-icon.mixin';

// keep in synk global.d.ts
@customElement('md-icon-time')
export class IconTime extends BaseIcon(BaseMdElement(LitElement)) {

  constructor() {
    super();

    this.smallSvgIcon = svg`
      <svg ${iconClass(this)} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
        <g>
          <path d="M10,2.16A7.84,7.84,0,1,0,17.84,10,7.85,7.85,0,0,0,10,2.16Zm0,14.68A6.84,6.84,0,1,1,16.84,10,6.85,6.85,0,0,1,10,16.84Z"/>
          <polygon points="9.63 7.31 8.63 7.31 8.63 12.69 13.88 12.69 13.88 11.69 9.63 11.69 9.63 7.31"/>
        </g>
      </svg>`;

    this.largeSvgIcon = svg`
      <svg ${iconClass(this)} xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64">
        <g>
          <path d="M32,7.53A24.47,24.47,0,1,0,56.47,32,24.49,24.49,0,0,0,32,7.53Zm0,46.94A22.47,22.47,0,1,1,54.47,32,22.5,22.5,0,0,1,32,54.47Z"/>
          <polygon points="30.21 20.57 28.21 20.57 28.21 37.21 44.42 37.21 44.42 35.21 30.21 35.21 30.21 20.57"/>
        </g>
      </svg>`;
  }

  render(): TemplateResult {
    return this.renderIcon();
  }
}
