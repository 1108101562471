export class LitAbortEvent extends Event {
  public constructor() {
    super('abort');
  }
}

export class LitInputEvent extends Event {
  public constructor() {
    super('input');
  }
}

export class LitTooltipToggleEvent extends Event {
  public constructor() {
    super('tooltipToggle');
  }
}

export class LitClickEvent extends Event {
  public constructor() {
    super('click');
  }
}

export class LitLogInEvent extends Event {
  public constructor() {
    super('login');
  }
}

export class LitLogOutEvent extends Event {
  public constructor() {
    super('logout');
  }
}

export class LitOrgClickEvent extends Event {
  public constructor() {
    super('orgClick');
  }
}

export class LitHomeClickEvent extends Event {
  public constructor() {
    super('home');
  }
}

export class LitChangeEvent extends Event {
  public constructor() {
    super('change');
  }
}

export class LitCloseEvent extends Event {
  public constructor() {
    super('close');
  }
}

export class LitOpenEvent extends Event {
  public constructor() {
    super('open');
  }
}

export class LitClosedEvent extends Event {
  public constructor() {
    super('closed');
  }
}

export class LitDeleteEvent extends Event {
  public constructor() {
    super('delete');
  }
}

export class LitChangeCustomEvent<T> extends CustomEvent<{ value?: T }> {
  public constructor(detail?: T) {
    super('change', {
      detail: {
        value: detail
      },
      bubbles: true,
      composed: true
    });
  }
}

export class LitRadioChangeCustomEvent extends CustomEvent<void> {
  public constructor() {
    super('radioChange', { bubbles: true, composed: true });
  }
}

export class LitSearchCustomEvent extends CustomEvent<{ value: string }> {
  public constructor(value: string) {
    super('search', {
      detail: {
        value: value,
      },
    });
  }
}

export class LitDeleteCustomEvent extends CustomEvent<{ key: string }> {
  public constructor(value: string) {
    super('delete', {
      detail: {
        key: value,
      },
    });
  }
}
