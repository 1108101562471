
import { html, TemplateResult } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';
import { when } from 'lit/directives/when.js';
import { LitClickEvent, LitInputEvent } from '../../helpers/events';
import { ILitMandatoryFields } from '../../helpers/lit-mandatory-fields';
import { nameofFactory } from '../../helpers/nameof';
import { PropertyRequiredError } from '../../helpers/property-required-error';
import { BaseLitElement } from '../base-lit-element';
import styles from './section-bar-item-style.scss';
import '../icons/icon-comment-fill';
import { ILitFocusable } from '../focusable';

export interface Item {
  key: string;
  text: string;
  complete?: boolean;
  message?: boolean;
  messages?: number;
  disabled?: boolean;
}

const nameof = nameofFactory<SectionBarItem>();

export class LitMessageClickEvent extends Event {
  public constructor() {
    super('messageClick');
  }
}

// keep in synk global.d.ts
@customElement('md-section-bar-item')
export class SectionBarItem extends BaseLitElement implements ILitMandatoryFields, ILitFocusable {
  static styles = styles;

  @property({ type: Object, attribute: true, reflect: true })
  item: Item | null = null;

  @property({ type: Boolean, attribute: true, reflect: true })
  active = false;

  @property({ type: Boolean, attribute: true, reflect: true })
  first = false;

  @property({ type: Boolean, attribute: true, reflect: true })
  disabled = false;

  _onChange(): void {
    this.dispatchEvent(new LitInputEvent());
    // this.requestUpdate();
  }

  update(changedProperties: Map<string | number | symbol, unknown>): void {
    this.checkProperties();

    super.update(changedProperties);
  }

  checkProperties(): void {
    if (!this.item) {
      throw new PropertyRequiredError(this, nameof('item'));
    }
  }

  focus(options?: FocusOptions): void {
    this.renderRoot?.querySelector('div')?.focus(options);
  }

  onClick(e: PointerEvent | KeyboardEvent): void {
    // `click` is a bubbling event, so we re-throw a new event manually.
    e.stopPropagation();

    if (this.disabled || !!this.item?.disabled) {
      return;
    }

    this.dispatchEvent(new LitClickEvent());
  }

  onMessageClick(e: PointerEvent | KeyboardEvent): void {
    // `click` is a bubbling event, so we re-throw a new event manually.
    e.stopPropagation();

    if (this.disabled || !!this.item?.disabled) {
      return;
    }

    this.dispatchEvent(new LitMessageClickEvent());
  }

  renderMessages(item: Item, disabled: boolean): TemplateResult {
    return html`
      <div class="message" tabindex="${disabled ? -1 : 0}" @click=${(e: PointerEvent) => this.onMessageClick(e)}
        @keydown="${(e: KeyboardEvent) => e.key === 'Enter' && this.onMessageClick(e)}">
        <md-icon-comment-fill class="size-m"></md-icon-comment-fill>
        ${when(item.messages, () => html`<span class="pill">
          ${(item.messages || 0) > 9 ? '9+' : item.messages}
        </span>`)}
      </div>`;
  }

  render(): TemplateResult {
    const disabled = this.disabled || !!this.item?.disabled;

    return html`
      <div id="container" tabindex="${disabled ? -1 : 0}" ?disabled="${disabled}" @click=${(e: PointerEvent)=> this.onClick(e)}
        @keydown="${(e: KeyboardEvent) => e.key === 'Enter' && this.onClick(e)}"
        class="item ${classMap({
          'active': this.active,
          'complete': !!this.item?.complete,
          'first-element': this.first,
          })}">
        <span class="arrow-left"></span>
        <span class="item-content">
          ${this.item?.text}
          ${when(this.item?.message, () => this.item ? this.renderMessages(this.item, disabled) : null)}
        </span>
        <span class="arrow-right"></span>
      </div>
    `;
  }
}
