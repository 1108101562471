<md-page-title value="Form | Real examples"></md-page-title>

<div class="flex direction-column gap-4 p-3">

  <md-paragraph>The components works fine with reactive forms, just add <code>ngDefaultControl</code> to the element and
    reactive forms will listen to the input-event.</md-paragraph>

  <form [formGroup]="form">

    <md-form-field for="customTooltip" label="Custom tooltip" style="width: 250px"
      (tooltipToggle)="onTooltipToggle($event)" [customTooltip]="true">
      <md-paragraph *ngIf="showTooltip" class="help" value="tooltip"></md-paragraph>
      <md-numeric-field id="customTooltip" formControlName="customTooltip" placeholder="Test tooltip | number"
        ngDefaultControl>
      </md-numeric-field>
    </md-form-field>

    <md-form-field for="firstName" [label]="labelUnsafe" style="width: 250px">
      <md-text-field id="firstName" formControlName="firstName" placeholder="Fornavn" ngDefaultControl [invalid]="
                      form.controls.firstName.errors?.forbiddenName
                        ? 'Navn kan ikke vare bob'
                        : null
                    "></md-text-field>
    </md-form-field>

    <md-form-field for="lastName" label="Efternavn" style="width: 250px" [tooltip]="tooltipText">
      <md-text-field id="lastName" formControlName="lastName" placeholder="Efternavn" ngDefaultControl required
        [invalid]="
                      form.controls.lastName.errors?.forbiddenName
                        ? 'Navn kan ikke vare bob'
                        : null
                    "></md-text-field>
    </md-form-field>

    <md-form-field label="Gender" for="gender" style="width: 250px">
      <md-radio-button-group id="gender" name="gender" formControlName="gender" ngDefaultControl>
        <md-radio-button name="gender" value="Male">Male</md-radio-button>
        <md-radio-button name="gender" value="Female">Female</md-radio-button>
      </md-radio-button-group>
    </md-form-field>

    <md-form-field for="description" label="Description" style="width: 350px" [tooltip]="tooltipText" required>
      <md-text-area id="description" formControlName="description" ngDefaultControl placeholder="Try it out" [invalid]="
                      form.controls.description.errors?.forbiddenName
                        ? 'Navn kan ikke vare bob'
                        : null
                    "></md-text-area>
    </md-form-field>

    <md-form-field label="Here are some random checkboxes" style="width: 500px" [tooltip]="tooltipText">
      <div style="display: flex; flex-direction: row; gap: 16px">
        <md-checkbox formControlName="checkIt" ngDefaultControl>Random checkbox</md-checkbox>
        <md-checkbox formControlName="checkItToo" ngDefaultControl>Random checkbox 2</md-checkbox>
      </div>
    </md-form-field>

    <md-form-field for="singleExample" label="Grønsak">
      <md-select-single id="singleExample" style="width: 250px; --select-max-height: 400px" [items]="testSelectData"
        formControlName="singleExample" placeholder="Velg grønnsak" ngDefaultControl>
      </md-select-single>
      {{ form.value.singleExample | json }}
    </md-form-field>

    <div class="submit-row">
      <md-button (click)="handleClick($event)">Lagre</md-button>
    </div>

    <div>
      {{ form.value | json }}
    </div>

  </form>

</div>
<div class="flex direction-column  p-3">
  <h3>ngModel example</h3>

  <md-paragraph>They will also work with ngModel if <code>ngDefaultControl</code> is used.</md-paragraph>

  <md-form-field for="ngModelId" label="ngModel" style="width: 500px">
    <md-text-field id="ngModelId" placeholder="ngModel" [(ngModel)]="valueNgModel" ngDefaultControl></md-text-field>
  </md-form-field>

  {{valueNgModel}}
</div>
