import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-numeric-field',
  templateUrl: './numeric-field.component.html',
  styleUrls: ['./numeric-field.component.scss']
})
export class NumericFieldComponent {

  value3?: string;

  form = new FormGroup({
    value1: new FormControl('101', [Validators.required, Validators.max(100)]),
    value2: new FormControl(null, []),
  });

  valueChanged(value: string) {
    console.log('changed to:', value);
    this.value3 = value;
  }

  onBlur() {
    console.log('blur');
  }
}
