import { Component } from '@angular/core';
import manifest from 'miljodir-wc/custom-elements.json';

@Component({
  selector: 'app-docs',
  templateUrl: './docs.component.html',
  styleUrls: ['./docs.component.scss']
})
export class DocsComponent {
  manifest = manifest;
}
