
import { LitElement, svg, TemplateResult } from 'lit';
import { customElement } from 'lit/decorators.js';
import { BaseMdElement } from '../base-lit-element';
import { iconClass } from '../../directives/icon-class.directive';
import { BaseIcon } from './base-icon.mixin';

// keep in synk global.d.ts
@customElement('md-icon-setting')
export class IconSetting extends BaseIcon(BaseMdElement(LitElement)) {

  constructor() {
    super();

    this.smallSvgIcon = svg`
      <svg ${iconClass(this)} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
        <g>
          <path d="M17.18,8.55l-.09,0-1.58-.45a5.89,5.89,0,0,0-.24-.58L16.06,6a.36.36,0,0,0,0-.09.76.76,0,0,0-.16-.81L14.85,4.06a.74.74,0,0,0-.8-.16l-.09,0-1.43.79-.59-.25-.45-1.57,0-.08a.75.75,0,0,0-.7-.46H9.23a.73.73,0,0,0-.68.45.36.36,0,0,0,0,.09L8.06,4.48c-.18.07-.36.14-.54.23l-1.46-.8L6,3.87A.78.78,0,0,0,5.16,4L4,5.16A.75.75,0,0,0,3.88,6l0,.07.8,1.46A4.25,4.25,0,0,0,4.5,8l-1.59.47-.09,0a.74.74,0,0,0-.45.69v1.57a.74.74,0,0,0,.45.7l.09,0,1.6.47a3,3,0,0,0,.2.49l-.8,1.46,0,.07a.77.77,0,0,0,.16.83L5.16,16a.78.78,0,0,0,.81.17l.09,0,1.46-.79.49.2.47,1.59a.42.42,0,0,0,0,.1.75.75,0,0,0,.69.44h1.58a.75.75,0,0,0,.69-.44.3.3,0,0,0,0-.1L12,15.5a3.25,3.25,0,0,0,.54-.23l1.43.8.08,0a.74.74,0,0,0,.82-.16l1.08-1.08a.79.79,0,0,0,.17-.81L16.06,14l-.79-1.43c.09-.2.17-.39.24-.59l1.58-.45.09,0a.73.73,0,0,0,.45-.68V9.24A.74.74,0,0,0,17.18,8.55Zm-.55,2L15,11.05a.5.5,0,0,0-.34.34,4.5,4.5,0,0,1-.38.91.48.48,0,0,0,0,.48l.83,1.5-.82.82-1.5-.83a.48.48,0,0,0-.48,0,4.9,4.9,0,0,1-.86.36.49.49,0,0,0-.33.34l-.49,1.66H9.39L8.9,15a.51.51,0,0,0-.33-.34,4.56,4.56,0,0,1-.82-.34.5.5,0,0,0-.24-.06.53.53,0,0,0-.24.06l-1.53.83-.86-.86.83-1.53a.51.51,0,0,0,0-.48,5.77,5.77,0,0,1-.34-.81A.48.48,0,0,0,5,11.11l-1.67-.5V9.38L5,8.89a.49.49,0,0,0,.34-.33,5.31,5.31,0,0,1,.34-.81.51.51,0,0,0,0-.48L4.88,5.74l.86-.86,1.53.83a.51.51,0,0,0,.48,0,4.9,4.9,0,0,1,.86-.36A.5.5,0,0,0,9,5l.47-1.64h1.16L11.05,5a.47.47,0,0,0,.34.34,5.2,5.2,0,0,1,.91.39.51.51,0,0,0,.48,0l1.5-.84.82.82-.84,1.5a.53.53,0,0,0,0,.49,4.07,4.07,0,0,1,.38.91A.51.51,0,0,0,15,9l1.65.47Z"/>
          <path d="M10,7.46A2.54,2.54,0,1,0,12.54,10,2.54,2.54,0,0,0,10,7.46Zm0,4.08A1.54,1.54,0,1,1,11.54,10,1.54,1.54,0,0,1,10,11.54Z"/>
        </g>
      </svg>`;

    this.largeSvgIcon = svg`
      <svg ${iconClass(this)} xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64">
        <g>
          <path d="M32,24.48a7.52,7.52,0,1,0,0,15h0a7.52,7.52,0,1,0,0-15Zm0,13h0A5.52,5.52,0,1,1,37.52,32,5.53,5.53,0,0,1,32,37.52Z"/>
          <path d="M54.7,27.89l-.19-.08-5.33-1.52a18.42,18.42,0,0,0-1-2.38l2.7-4.86a.84.84,0,0,0,.08-.2,1.82,1.82,0,0,0-.38-2l-3.47-3.46a1.8,1.8,0,0,0-2-.38l-.18.08-4.86,2.7a17.29,17.29,0,0,0-2.39-1L36.19,9.49a1.19,1.19,0,0,0-.08-.2,1.83,1.83,0,0,0-1.66-1.12h-4.9A1.81,1.81,0,0,0,27.9,9.28a.67.67,0,0,0-.09.21l-1.52,5.32a17.47,17.47,0,0,0-2.23.93L19.12,13,18.9,13a1.85,1.85,0,0,0-2,.4l-3.58,3.58a1.84,1.84,0,0,0-.4,2,1.42,1.42,0,0,0,.08.18l2.7,4.94a17.57,17.57,0,0,0-.86,2.06L9.48,27.7a1,1,0,0,0-.21.09,1.86,1.86,0,0,0-1.1,1.68v5.05a1.84,1.84,0,0,0,1.1,1.68.67.67,0,0,0,.21.09l5.4,1.6a16.75,16.75,0,0,0,.86,2l-2.69,5a1.42,1.42,0,0,0-.08.18,1.83,1.83,0,0,0,.39,2l3.58,3.58a1.85,1.85,0,0,0,1.95.41.85.85,0,0,0,.22-.09l4.95-2.69a18.85,18.85,0,0,0,2.07.87l1.58,5.38a1,1,0,0,0,.09.21,1.82,1.82,0,0,0,1.66,1.1h5.06a1.8,1.8,0,0,0,1.69-1.1,1,1,0,0,0,.09-.21l1.59-5.38a16.78,16.78,0,0,0,2.23-1L45,50.88a.71.71,0,0,0,.18.08,1.82,1.82,0,0,0,2-.38l3.47-3.46A1.83,1.83,0,0,0,51,45.17a.78.78,0,0,0-.09-.21L48.19,40.1a18.49,18.49,0,0,0,1-2.39l5.33-1.52a1,1,0,0,0,.21-.09,1.81,1.81,0,0,0,1.11-1.65v-4.9A1.81,1.81,0,0,0,54.7,27.89Zm-.87,6.41h0l-5.69,1.63a1,1,0,0,0-.68.67,17.05,17.05,0,0,1-1.27,3,1,1,0,0,0,0,1l2.87,5.18v0l-3.25,3.26-5.19-2.89a1,1,0,0,0-1,0,15.76,15.76,0,0,1-2.87,1.22,1,1,0,0,0-.66.67L34.43,53.8l0,0H29.59s0,0,0,0L27.9,48.07a1,1,0,0,0-.66-.67,15.9,15.9,0,0,1-2.73-1.14,1,1,0,0,0-1,0L18.3,49.12l0,0-3.39-3.41h0l2.88-5.28a1,1,0,0,0,0-.95,16,16,0,0,1-1.13-2.71,1,1,0,0,0-.67-.66l-5.75-1.7h0V29.59l0,0,5.74-1.69a1,1,0,0,0,.67-.66,16.53,16.53,0,0,1,1.14-2.71,1,1,0,0,0,0-1l-2.87-5.28h0l3.4-3.41,0,0,5.27,2.87a1,1,0,0,0,.95,0,15.49,15.49,0,0,1,2.88-1.2,1,1,0,0,0,.66-.68l1.63-5.68v0h4.6v0l1.63,5.69a1,1,0,0,0,.67.68,15.79,15.79,0,0,1,3,1.27,1,1,0,0,0,1,0l5.21-2.89,3.25,3.26h0l-2.88,5.2a1,1,0,0,0,0,1,15.93,15.93,0,0,1,1.26,3,1,1,0,0,0,.68.68l5.72,1.63h0Z"/>
        </g>
      </svg>`;
  }

  render(): TemplateResult {
    return this.renderIcon();
  }
}
