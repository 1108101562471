import { customElement, property } from 'lit/decorators.js';
import { BaseLitElement } from '../base-lit-element';
import { html, TemplateResult } from 'lit';
import { LitDeleteEvent } from '../../helpers/events';
import styles from './form-group-style.scss';
import '../icons/icon-trash-bin';
import '../link-button/link-button';

// keep in synk global.d.ts
@customElement('md-form-group')
export class FormGroup extends BaseLitElement {

  public static styles = styles;

  @property({ type: String, attribute: true, reflect: true }) deleteText = 'Slett';
  @property({ type: String, attribute: true, reflect: true }) title = '';

  /** Specify if a "Delete" button should be shown. */
  @property({ type: Boolean, attribute: true, reflect: true }) delete = false;

  _onDelete(ev: PointerEvent | KeyboardEvent): void {
    ev.preventDefault();
    ev.stopPropagation();

    this.dispatchEvent(new LitDeleteEvent());
  }

  renderHeader(): TemplateResult | null {
    if(!!this.title || this.delete ) {
      return html`
      <div class="group-header">
        <span>${this.title}</span>
        ${this.delete ?
           html`
              <md-link-button @click="${this._onDelete}">
                <span>${this.deleteText}</span>
                <md-icon-trash-bin class="size-m primary"></md-icon-trash-bin>
              </md-link-button>
            `
         : null}
      </div>
    `;
    } else {
      return null;
    }
  }

  renderGroupContent(): TemplateResult {
    return html`
        <slot></slot>
    `;
  }

  render(): TemplateResult {
    return html`
    <div class="container">
      ${this.renderHeader()}
      ${this.renderGroupContent()}
    </div>
    `;
  }
}
