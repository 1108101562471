
import { LitElement, svg, TemplateResult } from 'lit';
import { customElement } from 'lit/decorators.js';
import { BaseMdElement } from '../base-lit-element';
import { iconClass } from '../../directives/icon-class.directive';
import { BaseIcon } from './base-icon.mixin';

// keep in synk global.d.ts
@customElement('md-icon-search-doc')
export class IconSearchDoc extends BaseIcon(BaseMdElement(LitElement)) {

  constructor() {
    super();

    this.smallSvgIcon = svg`
      <svg ${iconClass(this)} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
        <g>
          <rect x="6.5" y="12.87" width="6.64" height="1"/>
          <path d="M15.54,10.8V2.4H3.87V17.57H15.54V13.49l.63.65a.91.91,0,0,0,1.29,0l0,0a1,1,0,0,0,0-1.35Zm-1-.42-.7-.73a3.42,3.42,0,0,0,.72-1.48v2.19Zm-5.72-3a2.42,2.42,0,1,1,4.83,0,2.42,2.42,0,1,1-4.83,0Zm2.77,3.47a3.28,3.28,0,0,0,1.53-.55l.71.74.35-.34-.64.65,1,1.06v4.12H4.87V3.4h9.67V6.67a3.4,3.4,0,0,0-6.74.75,3.54,3.54,0,0,0,.34,1.49H6.39v1H8.82a3.34,3.34,0,0,0,2,1Z"/>
        </g>
      </svg>`;

    this.largeSvgIcon = svg`
      <svg ${iconClass(this)} xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64">
        <g>
          <rect x="17.49" y="41.84" width="21.25" height="2"/>
          <rect x="17.49" y="35.5" width="21.25" height="2"/>
          <path d="M53,41l-6.81-7V8.32H10.05V55.68H46.19V42.6l2.61,2.71a2.92,2.92,0,0,0,4.13,0l.07-.07A3.12,3.12,0,0,0,53,41ZM44.19,53.68H12.05V10.32H44.19V32.6l-1.1,1.1L40.5,31a10.79,10.79,0,0,0,2.75-7.21A10.49,10.49,0,0,0,32.94,13.15,10.49,10.49,0,0,0,22.62,23.79a10.86,10.86,0,0,0,1.43,5.38H17.49v2h8a10.16,10.16,0,0,0,7.42,3.25A10,10,0,0,0,39,32.35l2.63,2.75.71-.68-2.06,2.07,3.88,4ZM24.62,23.79a8.5,8.5,0,0,1,8.32-8.64,8.49,8.49,0,0,1,8.31,8.64,8.48,8.48,0,0,1-8.31,8.63A8.49,8.49,0,0,1,24.62,23.79Z"/>
        </g>
      </svg>`;
  }

  render(): TemplateResult {
    return this.renderIcon();
  }
}
