<md-page-title value="Table | scss/css helpers"></md-page-title>

<div class="flex direction-column gap-4 p-3">
  <div>OBS.
    <ul>
      <li>Sorting is not implemented yet.</li>
      <li>Column highlighting on column header hover is not yet implemented (as it requires javascript).</li>
      <li>Table must use <em>thead</em> and <em>tbody</em> group header tags.</li>
      <li>Tag <em>caption</em> is optional.</li>
    </ul>
  </div>

  <ng-container *ngFor="let variant of variants">
    <h3>{{variant.name}}</h3>

    <table [class]="variant.classes">
      <caption>x treff</caption>
      <thead>
        <tr>
          <th>Col 1</th>
          <th>Col 2</th>
          <th>Col 3</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td><a href="https://www.miljodirektoratet.no/">A link</a></td>
          <td>Row 1 | Cell 2</td>
          <td>Row 1 | Cell 3</td>
        </tr>
        <tr>
          <td>Row 2 | Cell 1</td>
          <td>Row 2 | Cell 2</td>
          <td>Row 2 | Cell 3</td>
        </tr>
        <tr disabled>
          <td class="align-center" colspan="3">Row 3 | Cell colspan=3 | align-center | disabled</td>
        </tr>
      </tbody>
    </table>
  </ng-container>

</div>
