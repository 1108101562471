<!-- NB. in this file we are using unsafe typing just to make simpler code as it's just a kitchen sink -->

<md-page-title value="Web components | Angular examples"></md-page-title>

<div #grid class="grid my-2 mr-2">
  <div class="col-4 component">
    <h4 class="title">Accordion</h4>

    <md-accordion-list>
      <md-accordion-card header="Default"> Title </md-accordion-card>
    </md-accordion-list>
  </div>

  <div class="col-4 component">
    <h4 class="title">Alert message</h4>

    <md-alert-message class="warning" value="Text"></md-alert-message>
  </div>

  <div class="col-4 component">
    <h4 class="title">Button</h4>

    <md-button>Text</md-button>
  </div>

  <div class="col-4 component">
    <h4 class="title">Checkbox</h4>

    <md-checkbox>Text</md-checkbox>
  </div>

  <div class="col-4 component">
    <h4 class="title">Checkbox group</h4>

    <md-checkbox-group [items]="[
      { key: '1', text: 'Text 1' },
      { key: '2', text: 'Text 2' },
    ]" invalid="invalid"></md-checkbox-group>
  </div>

  <div class="col-4 component">
    <h4 class="title">Context menu</h4>

    <md-context-menu>
      <md-context-menu-item icon="md-icon-trash-bin" text="Delete"> </md-context-menu-item>
      <md-context-menu-item text="Open document"> </md-context-menu-item>
    </md-context-menu>
  </div>

  <div class="col-4 component">
    <h4 class="title">Download File</h4>

    <md-download-file text="Vedlegg (secondary)" value="C:\\abc.pdf" filename="abc.pdf" invalid="invalid">
    </md-download-file>
  </div>

  <div class="col-4 component">
    <h4 class="title">Footer</h4>

    <md-footer logoLink="#"></md-footer>
  </div>

  <div class="col-4 component">
    <h4 class="title">Form field</h4>

    <md-form-field label="Label"></md-form-field>
  </div>

  <div class="col-4 component">
    <h4 class="title">Form group</h4>

    <md-form-group title="Tittel/gruppenavn" [delete]="true">
      <md-form-field for="number" label="Custom tooltip">
        <md-text-field id="number" placeholder="Test tooltip">
        </md-text-field>
      </md-form-field>
    </md-form-group>
  </div>

  <div class="col-4 component">
    <h4 class="title">Icons</h4>

    <div class="flex flex-wrap">
      <md-icon *ngFor="let icon of allAvailableIcons" [icon]="icon" [title]="icon" class="size-m pointer primary">
      </md-icon>
    </div>
  </div>

  <div class="col-4 component">
    <h4 class="title">Images</h4>

    <img style="max-width: 250px; width: 100%;" src="assets/images/picture_1.svg" />
  </div>

  <div class="col-4 component">
    <h4 class="title">Info box</h4>

    <md-info-box value="Text"></md-info-box>
  </div>

  <div class="col-4 component">
    <h4 class="title">Left menu</h4>

    <ng-container *ngIf="[{
      icon: 'md-icon-cal',
      text: 'Menu 1',
      url: '/account',
      active: true,
      subItems: [
        { text: 'Submenu 1.1', url: '' },
      ]
      }] as items">
      <md-left-menu [items]="items"></md-left-menu>
    </ng-container>
  </div>

  <div class="col-4 component">
    <h4 class="title">Link button</h4>

    <md-link-button> <span>Text</span>
      <md-icon-trash-bin class="size-m primary"></md-icon-trash-bin>
    </md-link-button>
  </div>

  <div class="col-4 component">
    <h4 class="title">Map</h4>

    <md-map style="height: 150px;"></md-map>
  </div>

  <div class="col-4 component">
    <h4 class="title">Modal</h4>

    <md-button #modal (click)="$any(modal).value = 1">Open modal</md-button>

    <md-modal [open]="$any(modal).value === 1">
      <md-modal-title>Title</md-modal-title>
      <md-modal-buttons>
        <md-button (click)="$any(modal).value = 0">Action</md-button>
      </md-modal-buttons>
    </md-modal>
  </div>

  <div class="col-4 component">
    <h4 class="title">Navigation header</h4>
    <md-navigation-header header="Title"></md-navigation-header>
  </div>

  <div class="col-4 component">
    <h4 class="title">Numeric input field</h4>
    <md-numeric-field header="Title" invalid="invalid"></md-numeric-field>
  </div>

  <div class="col-4 component">
    <h4 class="title">Page Title</h4>
    <md-page-title value="Title"></md-page-title>
  </div>

  <div class="col-4 component">
    <h4 class="title">Paragraph</h4>
    <md-paragraph>Text</md-paragraph>
  </div>

  <div class="col-4 component">
    <h4 class="title">Popover</h4>
    Deleted
  </div>

  <div class="col-4 component">
    <h4 class="title">Radio button group</h4>

    <md-radio-button-group name="radio" invalid="invalid">
      <md-radio-button name="radio" value="1">Value 1</md-radio-button>
      <md-radio-button name="radio" value="2">Value 2</md-radio-button>
    </md-radio-button-group>
  </div>

  <div class="col-4 component">
    <h4 class="title">Section Bar</h4>

    <ng-container *ngIf="[
      { key: '1', text: 'First 1' },
      { key: '2', text: 'Item 2, completed', complete: true }] as items">
      <md-section-bar [items]="items">
      </md-section-bar>
    </ng-container>
  </div>

  <div class="col-4 component">
    <h4 class="title">Select (multiple)</h4>

    <ng-container *ngIf="[
      { key: '1', text: 'Item 1' },
      { key: '2', text: 'Item 2' }] as items">
      <md-select-multiple [items]="items" placeholder="Placeholder" invalid="invalid">
      </md-select-multiple>
    </ng-container>
  </div>

  <div class="col-4 component">
    <h4 class="title">Select (single)</h4>

    <ng-container *ngIf="[
      { key: '1', text: 'Item 1' },
      { key: '2', text: 'Item 2' }
      ] as items">
      <md-select-single [items]="items" placeholder="Velg grønnsak" singleLine invalid="invalid">
      </md-select-single>
    </ng-container>
  </div>

  <div class="col-4 component">
    <h4 class="title">Tabs</h4>

    <ng-container *ngIf="[
      { title: 'First', route: 'route1', active: false },
      { title: 'Second', route: 'route2', active: false },
      { title: 'Third', route: 'route3', active: false },
      ] as tabs">
      <md-tabs [tabs]="tabs"></md-tabs>
    </ng-container>
  </div>

  <div class="col-4 component">
    <h4 class="title">Text area</h4>

    <md-text-area placeholder="Placeholder" rows="4" invalid="invalid"></md-text-area>
  </div>

  <div class="col-4 component">
    <h4 class="title">Text field</h4>

    <md-text-field placeholder="Placeholder" invalid="invalid"></md-text-field>
  </div>

  <div class="col-4 component">
    <h4 class="title">Text field with icon</h4>

    <md-text-field-icon icon="md-icon-zoom" placeholder="Placeholder" invalid="invalid">
    </md-text-field-icon>
  </div>

  <div class="col-4 component">
    <h4 class="title">Tile button</h4>

    <md-tile-button title="Title" text="Text">
      <md-icon-trash-bin slot="icon" class="success size-xl"></md-icon-trash-bin>
    </md-tile-button>
  </div>

  <div class="col-4 component">
    <h4 class="title">Toggle</h4>

    <md-toggle></md-toggle>
  </div>

  <div class="col-4 component">
    <h4 class="title">Tooltip</h4>

    <md-icon-warning class="size-m success" id="defaultTooltip" aria-describedby="tooltip"></md-icon-warning>
    <md-tooltip anchor="#defaultTooltip">Text</md-tooltip>
  </div>

  <div class="col-4 component">
    <h4 class="title">Upload</h4>

    <md-upload text="Legg ved fil (.pdf, .docx)" uploadedBytes="2048" accept=".pdf,.docx">
    </md-upload>
  </div>
</div>
