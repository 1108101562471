import { Component } from '@angular/core';
import data from './exported-scss-array.json';

type ScssVariable = {
  name: string,
  value: string,
  compiledValue: string
};

@Component({
  selector: 'app-scss-variables',
  templateUrl: './scss-variables.component.html',
  styleUrls: ['./scss-variables.component.scss']
})
export class ScssVariablesComponent {

  data: ScssVariable[];

  constructor() {
    this.data = data.sort((v1, v2) => v1.name.localeCompare(v2.name));
  }

  isValidColor(value: string) {
    var s = new Option().style;
    s.color = value;
    return s.color !== '';
  }
}
