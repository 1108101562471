
import { LitElement, svg, TemplateResult } from 'lit';
import { customElement } from 'lit/decorators.js';
import { BaseMdElement } from '../base-lit-element';
import { iconClass } from '../../directives/icon-class.directive';
import { BaseIcon } from './base-icon.mixin';

// keep in synk global.d.ts
@customElement('md-icon-download')
export class IconDownload extends BaseIcon(BaseMdElement(LitElement)) {

  constructor() {
    super();

    this.smallSvgIcon = svg`
      <svg ${iconClass(this)} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
        <g>
          <polygon points="15.37 9.45 14.67 8.74 10.5 12.91 10.5 3.32 9.5 3.32 9.5 12.91 5.33 8.74 4.63 9.45 10 14.82 15.37 9.45"/>
          <rect x="2.57" y="15.68" width="14.86" height="1"/>
        </g>
      </svg>`;

    this.largeSvgIcon = svg`
      <svg ${iconClass(this)} xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64">
        <g>
          <polygon points="48.77 30.15 47.36 28.73 33 43.09 33 10.96 31 10.96 31 43.09 16.64 28.73 15.23 30.15 32 46.92 48.77 30.15"/>
          <rect x="8.23" y="51.13" width="47.54" height="2"/>
        </g>
      </svg>`;
  }

  render(): TemplateResult {
    return this.renderIcon();
  }
}
