
import { LitElement, svg, TemplateResult } from 'lit';
import { customElement } from 'lit/decorators.js';
import { BaseMdElement } from '../base-lit-element';
import { iconClass } from '../../directives/icon-class.directive';
import { BaseIcon } from './base-icon.mixin';

// TODO: there is no 20px icon

// keep in synk global.d.ts
@customElement('md-icon-arrow-link')
export class IconArrowLink extends BaseIcon(BaseMdElement(LitElement)) {

  constructor() {
    super();

    // src\assets\ikoner\20px\icon_20px_link.svg
    this.smallSvgIcon = svg`
      <svg ${iconClass(this)} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
        <polygon points="11.29 5.47 15.32 9.5 2.76 9.5 2.76 10.5 15.32 10.5 11.29 14.53 12 15.24 17.24 10 12 4.76 11.29 5.47"/>
      </svg>`;

    // src\assets\ikoner\64px\64px_arrow_link.svg
    this.largeSvgIcon = svg`
      <svg ${iconClass(this)} xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64">
        <polygon points="38.39 15.66 36.98 17.07 50.91 31 9.27 31 9.27 33 50.91 33 36.98 46.93 38.39 48.34 54.73 32 38.39 15.66"/>
      </svg>`;
  }

  render(): TemplateResult {
    return this.renderIcon();
  }
}
