import { LitElement, svg, TemplateResult } from 'lit';
import { customElement } from 'lit/decorators.js';
import { BaseMdElement } from '../base-lit-element';
import { iconClass } from '../../directives/icon-class.directive';
import { BaseIcon } from './base-icon.mixin';

// keep in synk global.d.ts
@customElement('md-icon-redirect')
export class IconRedirect extends BaseIcon(BaseMdElement(LitElement)) {

  constructor() {
    super();

    // OSB. if you change this SVG keep also in sync `a[target="_blank"]::after` in template.scss, needed from UU requirements 
    this.smallSvgIcon = svg`
      <svg ${iconClass(this)} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
        <g>
          <polygon points="16.85 16.52 3.15 16.52 3.15 3.52 10.45 3.52 10.45 2.52 2.15 2.52 2.15 17.52 17.85 17.52 17.85 9.86 16.85 9.86 16.85 16.52"/>
          <polygon points="13 3.48 16.21 3.48 10.85 8.84 11.56 9.55 16.85 4.26 16.85 7.34 17.85 7.34 17.85 2.48 13 2.48 13 3.48"/>
        </g>
      </svg>`;

    this.largeSvgIcon = svg`
      <svg ${iconClass(this)} xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64">
        <g>
          <polygon points="56.52 55.4 7.48 55.4 7.48 8.6 33.44 8.6 33.44 10.6 9.48 10.6 9.48 53.4 54.52 53.4 54.52 31.51 56.52 31.51 56.52 55.4"/>
          <polygon points="56.52 23.53 54.52 23.53 54.52 10.6 41.6 10.6 41.6 8.6 56.52 8.6 56.52 23.53"/>
          <rect x="31.79" y="18.68" width="27.75" height="2" transform="translate(-0.54 38.06) rotate(-45)"/>
        </g>
      </svg>`;
  }

  render(): TemplateResult {
    return this.renderIcon();
  }
}
