import { Component } from '@angular/core';
import { MenuItem } from 'miljodir-wc/dist/src/components/left-menu/left-menu';

@Component({
  selector: 'app-left-menu',
  templateUrl: './left-menu.component.html',
  styleUrls: ['./left-menu.component.scss']
})
export class LeftMenuComponent {
  isCollapsed = false;

  menuItems: MenuItem[] = [{
    icon: 'md-icon-home',
    text: 'Hjem',
    url: '/home',
    active: false,
    disabled: false,
  }, {
    icon: 'md-icon-avatar',
    text: 'Min profil (disabled)',
    url: '/account',
    active: false,
    disabled: true,
  }, {
    icon: 'md-icon-cal',
    text: 'Årsoversikt og frister',
    url: '/account',
    active: true,
    disabled: false,
    subItems: [
      { text: 'Planlegg tilsyn', url: '', disabled: false },
      { text: 'Legg inn en aksjon (disabled)', url: '', disabled: true },
      { text: 'Se FTD sin årsplan', url: '', disabled: false },
    ]
  }];

  onOpen() {
    this.isCollapsed = false;
  }

  onClose() {
    this.isCollapsed = true;
  }

  onNavigate($event: Event) {
    const e = $event as CustomEvent<{ value: string }>;
    console.log('navigate to', e.detail.value);
  }
}
