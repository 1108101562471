<md-page-title value="md-text-field-icon | Input text field with icon"></md-page-title>

<div class="flex direction-column gap-4 p-3">

  <h3>Input text</h3>
  <md-text-field-icon icon="md-icon-zoom" [(ngModel)]="searchText" placeholder="Default" ngDefaultControl>
  </md-text-field-icon>

  <h3>Input text - fixed width</h3>
  <md-text-field-icon icon="md-icon-zoom" placeholder="Default" style="width: 250px">
  </md-text-field-icon>

  <h3>Invalid</h3>
  <md-text-field-icon icon="md-icon-zoom" style="width: 250px" invalid="invalid">
  </md-text-field-icon>

  <h3>Readonly (validation error is hidden)</h3>
  <md-text-field-icon icon="md-icon-zoom" value="read only" placeholder="readOnly = true" ngDefaultControl
    invalid="invalid" [readOnly]="true">
  </md-text-field-icon>

  <h3>NoBorder | true</h3>
  <md-text-field-icon icon="md-icon-zoom" value="" placeholder="noBorder = true" ngDefaultControl [noBorder]="true">
  </md-text-field-icon>
</div>
