export interface ILitFocusable {
  focus(_options?: FocusOptions): void;
}

/** Returns an elements is disabled. */
const isDisabled = (element: Element) => {
  const disabled = element.hasAttribute('disabled') || !!(element as any).disabled;
  return disabled;
};

/** Returns if an element is child of a disabled element (so is itself disabled). */
const isInDisabledTree = (element: Element) => {
  let parent: Element | null = element;
  let disabled = false;
  while (parent && !disabled) {
    disabled = isDisabled(parent);
    parent = parent.parentElement;
  }
  return disabled;
};

/** 
 * Check an element (ELEMENT_NODE) can get focus. 
 * Based on: 
 * - the element name is in a list of `standard focusable elements`,
 * - the element name is in a list of focusable `web components`, 
 * - the element (or his parents) has no `disabled` attribute,
 * - has a `focus()` implementation
 * - has tabindex that allows focus(), rule valid only for some elements
 */
export const canFocus = (element: Element) => {
  if (element.nodeType !== Node.ELEMENT_NODE) {
    return false;
  }

  const hasFocusFunction = typeof (element as any).focus === 'function';
  if (!hasFocusFunction) {
    return false;
  }

  if (element instanceof HTMLElement && element.offsetParent === null) {
    // see: https://developer.mozilla.org/en-US/docs/Web/API/HTMLElement/offsetParent
    // The element or its parent element has the display property set to none.
    return false;
  }

  const disabled = isInDisabledTree(element);
  if (disabled) {
    return false;
  }

  const tabindex = element.getAttribute('tabindex');

  const name = element.nodeName.toLowerCase();
  if (name === 'a') {
    // by w3c standard, A is focusable only if has an href value, or a tabindex >= 0
    const href = element.getAttribute('href');
    return (href || (tabindex || -1) >= 0);
  }

  // can focus if it is a w3c standard focusable element
  if (standardFocusableElements.includes(name)) {
    return (tabindex || 0) >= 0;
  }

  // or a supported web-component with tabindex >= 0
  return focusableElements.includes(name) || (tabindex || -1) >= 0;
};

/** 
 * A list of webcomponents that implement the method `focus` and than could get focus.
 * You should still check they are not disabled or do not have a negative `tabindex`.
 */
export const focusableElements = [
  'md-accordion-card',
  'md-button',
  'md-checkbox',
  'md-link-button',
  'md-numeric-field',
  'md-radio-button',
  'md-section-bar',
  'md-section-bar-item',
  'md-select-multiple',
  'md-select-single',
  'md-tabs',
  'md-text-area',
  'md-text-field',
  'md-text-field-icon',
  'md-tile-button',
  'md-toggle',
  'md-upload',
];

/** 
 * A list of standard elements that can get focus. 
 * The element `a` has special rules: it can get focus only if it has an href or a tabindex >= 0.
 * */
export const standardFocusableElements = [
  'a',
  'input',
  'select',
  'textarea',
  'button',
  'object',
];
