import { html, TemplateResult } from 'lit';
import { customElement, property, query } from 'lit/decorators.js';
import { BaseLitElement } from '../base-lit-element';
import { LitClickEvent } from '../../helpers/events';
import { ILitFocusable } from '../focusable';
import styles from './link-button-style.scss';

// keep in synk global.d.ts
@customElement('md-link-button')
export class LinkButton extends BaseLitElement implements ILitFocusable {

  public static styles = styles;

  @property({ type: Boolean, attribute: true, reflect: true })
  disabled = false;

  @query('#button', true)
  _button!: HTMLInputElement;

  click(): void {
    if (this.disabled) {
      return;
    }

    this.dispatchEvent(new LitClickEvent());
  }

  _onClick(ev: PointerEvent | KeyboardEvent): void {
    ev.preventDefault();
    ev.stopPropagation();

    this.click();
  }

  focus(options?: FocusOptions): void {
    this._button.focus(options);
  }

  render(): TemplateResult {
    return html`
    <button id="button" @click="${this._onClick}" @keydown="${(e: KeyboardEvent) => e.key === 'Enter' && this._onClick(e)}"
      ?disabled="${this.disabled}">
      <div class="container" @click="${this._onClick}">
        <slot></slot>
      </div>
    </button>
    `;
  }
}
