
import { LitElement, svg, TemplateResult } from 'lit';
import { customElement } from 'lit/decorators.js';
import { BaseMdElement } from '../base-lit-element';
import { iconClass } from '../../directives/icon-class.directive';
import { BaseIcon } from './base-icon.mixin';

// keep in synk global.d.ts
@customElement('md-icon-plus')
export class IconPlus extends BaseIcon(BaseMdElement(LitElement)) {

  constructor() {
    super();

    this.smallSvgIcon = svg`
      <svg ${iconClass(this)} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
        <polygon points="15.19 9.5 10.5 9.5 10.5 4.81 9.5 4.81 9.5 9.5 4.81 9.5 4.81 10.5 9.5 10.5 9.5 15.19 10.5 15.19 10.5 10.5 15.19 10.5 15.19 9.5"/>
      </svg>`;

    this.largeSvgIcon = svg`
      <svg ${iconClass(this)} xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64">
        <polygon points="48.6 31 33 31 33 15.4 31 15.4 31 31 15.4 31 15.4 33 31 33 31 48.6 33 48.6 33 33 48.6 33 48.6 31"/>
      </svg>`;
  }

  render(): TemplateResult {
    return this.renderIcon();
  }
}
