<md-page-title value="md-alert-message | Alert message"></md-page-title>

<div class="flex direction-column gap-2 p-3">

  <ng-container *ngFor="let type of types">
    <h5>Class: {{type}}</h5>
    <md-alert-message [id]="type" class="{{type}}" value="Help text | {{type || 'undefined'}}"
      (close)="onClose($event)">
    </md-alert-message>
  </ng-container>

  <md-alert-message value="Help text | unspecified" (close)="onClose($event)"></md-alert-message>
</div>
