<md-page-title value="md-left-menu | Left menu"></md-page-title>

<div class="flex direction-column gap-4 p-3">

  <div class="grid mt-2">
    <div class="menu" [class]="{ 'col-4': !isCollapsed, 'col-1': isCollapsed}">
      <md-left-menu [collapsed]="isCollapsed" [items]="menuItems" *ngIf="menuItems.length > 0" (open)="onOpen()"
        (close)="onClose()" (change)="onNavigate($event)"></md-left-menu>
    </div>
    <div [class]="{ 'col-8': !isCollapsed, 'col-11': isCollapsed}">
      Page content
    </div>
  </div>
</div>
