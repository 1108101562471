import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';

import 'miljodir-wc/dist/src/index';
import 'api-viewer-element/lib/api-viewer';

import { AccordionComponent } from './accordion/accordion.component';
import { AlertMessageComponent } from './alert-message/alert-message.component';
import { AppComponent } from './app.component';
import { ButtonComponent } from './button/button.component';
import { CheckboxComponent } from './checkbox/checkbox.component';
import { CheckboxGroupComponent } from './checkbox-group/checkbox-group.component';
import { ContextMenuComponent } from './context-menu/context-menu.component';
import { DocsComponent } from './docs/docs.component';
import { DownloadFileComponent } from './download-file/download-file.component';
import { FooterComponent } from './footer/footer.component';
import { FormComponent } from './form/form.component';
import { FormFieldComponent } from './form-field/form-field.component';
import { FormGroupComponent } from './form-group/form-group.component';
import { GridLayoutComponent } from './grid-layout/grid-layout.component';
import { IconsComponent } from './icons/icons.component';
import { InfoBoxComponent } from './info-box/info-box.component';
import { LeftMenuComponent } from './left-menu/left-menu.component';
import { LinkButtonComponent } from './link-button/link-button.component';
import { MapComponent } from './map/map.component';
import { ModalComponent } from './modal/modal.component';
import { NavigationHeaderComponent } from './navigation-header/navigation-header.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { PageTitleComponent } from './page-title/page-title.component';
import { ParagraphComponent } from './paragraph/paragraph.component';
import { ScssVariablesComponent } from './scss-variables/scss-variables.component';
import { SectionBarComponent } from './section-bar/section-bar.component';
import { SelectMultipleComponent } from './select-multiple/select-multiple.component';
import { SelectSingleComponent } from './select-single/select-single.component';
import { TableComponent } from './table/table.component';
import { TabsComponent } from './tabs/tabs.component';
import { TextAreaComponent } from './text-area/text-area.component';
import { TextFieldComponent } from './text-field/text-field.component';
import { TextFieldIconComponent } from './text-field-icon/text-field-icon.component';
import { TileButtonComponent } from './tile-button/tile-button.component';
import { ToggleComponent } from './toggle/toggle.component';
import { TooltipComponent } from './tooltip/tooltip.component';
import { UploadComponent } from './upload/upload.component';
import { RadioButtonGroupComponent } from './radio-button-group/radio-button-group.component';
import { HomeComponent } from './home/home.component';
import { NumericFieldComponent } from './numeric-field/numeric-field.component';
import { ImagesComponent } from './images/images.component';
import { AutofocusDirective } from './shared/directives/autofocus.directive';
import { TemplateComponent } from './template/template.component';

@NgModule({
  declarations: [
    AccordionComponent,
    AlertMessageComponent,
    AppComponent,
    AutofocusDirective,
    ButtonComponent,
    CheckboxComponent,
    CheckboxGroupComponent,
    ContextMenuComponent,
    DocsComponent,
    DownloadFileComponent,
    FooterComponent,
    FormComponent,
    FormFieldComponent,
    FormGroupComponent,
    GridLayoutComponent,
    HomeComponent,
    IconsComponent,
    ImagesComponent,
    InfoBoxComponent,
    LeftMenuComponent,
    LinkButtonComponent,
    MapComponent,
    ModalComponent,
    NavigationHeaderComponent,
    NumericFieldComponent,
    PageNotFoundComponent,
    PageTitleComponent,
    ParagraphComponent,
    ParagraphComponent,
    RadioButtonGroupComponent,
    ScssVariablesComponent,
    SectionBarComponent,
    SelectMultipleComponent,
    SelectSingleComponent,
    TableComponent,
    TabsComponent,
    TextAreaComponent,
    TextFieldComponent,
    TextFieldIconComponent,
    TileButtonComponent,
    ToggleComponent,
    TooltipComponent,
    UploadComponent,
    TemplateComponent,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule, // order matters: AppRoutingModule must be the latest module to be imported
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
