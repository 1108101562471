import { AfterViewInit, Directive, ElementRef, Input } from '@angular/core';

@Directive({
  selector: '[appAutofocus]'
})
export class AutofocusDirective implements AfterViewInit {

  @Input() public appAutofocus: boolean = false;

  constructor(
    private host: ElementRef
  ) { }

  ngAfterViewInit() {
    if (!this.appAutofocus) {
      return;
    }

    setTimeout(() => {
      this.host.nativeElement.focus();
    }, 0);
  }

}
