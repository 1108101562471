
import { LitElement, svg, TemplateResult } from 'lit';
import { customElement } from 'lit/decorators.js';
import { BaseMdElement } from '../base-lit-element';
import { iconClass } from '../../directives/icon-class.directive';
import { BaseIcon } from './base-icon.mixin';

// keep in synk global.d.ts
@customElement('md-icon-envelope')
export class IconEnvelope extends BaseIcon(BaseMdElement(LitElement)) {

  constructor() {
    super();

    this.smallSvgIcon = svg`
      <svg ${iconClass(this)} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
        <defs>
          <style>
            .cls-1 {
              fill: none;
              stroke: #000;
              stroke-linecap: round;
              stroke-linejoin: round;
            }
          </style>
        </defs>
        <g id="Abonnement">
          <path id="Path_1889" data-name="Path 1889" class="cls-1" d="M16.87,17.18V8.79L10,2.82l-6.89,6v8.35Z"/>
          <path id="Path_1890" data-name="Path 1890" class="cls-1" d="M16.87,17l-6.85-6-6.89,6"/>
          <line id="Line_47" data-name="Line 47" class="cls-1" x1="7.42" y1="13.16" x2="3.13" y2="8.83"/>
          <line id="Line_48" data-name="Line 48" class="cls-1" x1="12.38" y1="13.16" x2="16.67" y2="8.83"/>
        </g>
      </svg>`;

    this.largeSvgIcon = svg`
      <svg ${iconClass(this)} xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64">
        <path d="M54.64,27.38,32.71,8.26a1,1,0,0,0-1.31,0L9.36,27.5l0,0,0,0a1.77,1.77,0,0,0-.12.17.77.77,0,0,0-.17.54H9V55a1,1,0,0,0,.3.7,1,1,0,0,0,.7.3l44,0a1,1,0,0,0,1-1V28.13A1,1,0,0,0,54.64,27.38ZM11,30.69,22.56,42.35,11,52.43Zm21.05,6,6.65,5.8a.9.9,0,0,0,.21.31.84.84,0,0,0,.41.23L51.86,54,12.28,54ZM41.15,42,53,30V52.31ZM32.06,10.34l20.19,17.6L39.64,40.67l-6.93-6a1,1,0,0,0-1.31,0L24.07,41,11.47,28.31Z"/>
      </svg>`;
  }

  render(): TemplateResult {
    return this.renderIcon();
  }
}
