
import { html, LitElement, TemplateResult } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { BaseMdElement } from '../base-lit-element';
import { propagateClasses } from '../../directives/propagate-classes.directive';
import { propagateAttributes } from '../../directives/propagate-attributes.directive';
import { nameofFactory } from '../../helpers/nameof';
import './icon-sorting-active';
import './icon-sorting-none';

const nameof = nameofFactory<IconSorting>();

export type IconSortingDirection = 'none' | 'asc' | 'desc';

// keep in synk global.d.ts
@customElement('md-icon-sorting')
export class IconSorting extends BaseMdElement(LitElement) {

  @property({ type: String, attribute: true, reflect: true })
  direction: IconSortingDirection = 'none';

  constructor() {
    super();
  }

  render(): TemplateResult {
    if (this.direction === 'asc') {
      return html`<md-icon-sorting-active ${propagateClasses(this, [])} ${propagateAttributes(this, [nameof('direction')])}>
</md-icon-sorting-active>`;
    } else if (this.direction === 'desc') {
      return html`<md-icon-sorting-active ${propagateClasses(this, ['rotate-180'])} ${propagateAttributes(this, [nameof('direction')])}>
</md-icon-sorting-active>`;
    } else {
      return html`<md-icon-sorting-none ${propagateClasses(this, [])} ${propagateAttributes(this, [])}></md-icon-sorting-none>`;
    }
  }
}
