import { html, TemplateResult } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { BaseLitElement } from '../base-lit-element';
import '../icons/icon-close';

// keep in synk global.d.ts
@customElement('md-modal-content')
export class ModalContent extends BaseLitElement {

  @property({ type: String, attribute: true, reflect: true }) slot = 'modalContent';

  render(): TemplateResult {
    return html`
      <slot></slot>
          `;
  }
}
