
import { LitElement, svg, TemplateResult } from 'lit';
import { customElement } from 'lit/decorators.js';
import { BaseMdElement } from '../base-lit-element';
import { iconClass } from '../../directives/icon-class.directive';
import { BaseIcon } from './base-icon.mixin';

// keep in synk global.d.ts
@customElement('md-icon-expand')
export class IconExpand extends BaseIcon(BaseMdElement(LitElement)) {

  constructor() {
    super();

    this.smallSvgIcon = svg`
      <svg ${iconClass(this)} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
        <g>
          <polygon points="12.28 3.83 15.46 3.83 10.49 8.8 11.2 9.51 16.16 4.54 16.16 7.72 17.16 7.72 17.16 2.83 12.28 2.83 12.28 3.83"/>
          <polygon points="8.8 10.49 3.83 15.46 3.83 12.28 2.83 12.28 2.83 17.16 7.72 17.16 7.72 16.16 4.54 16.16 9.51 11.2 8.8 10.49"/>
        </g>
      </svg>`;

    this.largeSvgIcon = svg`
      <svg ${iconClass(this)} xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64">
        <g>
          <polygon points="39.28 9.67 39.28 11.67 50.91 11.67 34 28.59 35.41 30 52.33 13.09 52.33 24.72 54.33 24.72 54.33 9.67 39.28 9.67"/>
          <polygon points="28.59 34 11.67 50.91 11.67 39.28 9.67 39.28 9.67 54.33 24.72 54.33 24.72 52.33 13.09 52.33 30 35.41 28.59 34"/>
        </g>
      </svg>`;
  }

  render(): TemplateResult {
    return this.renderIcon();
  }
}
