<md-page-title value="md-numeric-field | Numeric input field"></md-page-title>

<div class="flex direction-column gap-4 p-3" [formGroup]="form">

  <details>
    <summary>The component supports <em>locale</em> and <em>localeOptions</em> configuration, based on web standard
      <em>Intl.NumberFormat()</em>.
    </summary>
    <ul>
      <li>
        <a
          href="https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat/NumberFormat">Intl.NumberFormat()</a>
      </li>
      <li>
        <a
          href="https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/Locale/Locale">Locale</a>
      </li>
      <li>
        <a
          href="https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat/NumberFormat">Intl.NumberFormatOptions
          options</a>
      </li>
    </ul>
  </details>

  <details>
    <summary>The attribute/property <em>value</em> is a numeric rapresentation of the value (but still a `string`).
    </summary>
    <p>
      If it's a valid number, a javascript number is used (ex. "123456.78"),
      otherwise it's used the "raw" string (ex. "123456.78x").
      It can differ from what the user see (it uses the browser-locale to format a valid number).
      The component exposes the attribute/property <em>NaN</em> if it's not a valid number.
    </p>
  </details>

  <h3>Locale | Browser default settings</h3>
  <div class="flex flex-wrap">
    <div>
      <em>decimal value</em>
      <md-numeric-field [value]="123456.78"></md-numeric-field>
    </div>

    <div>
      <em>negative decimal value</em>
      <md-numeric-field [value]="-123456.78"></md-numeric-field>
    </div>

    <div>
      <em>0</em>
      <md-numeric-field [value]="0"></md-numeric-field>
    </div>

    <div>
      <em>empty string</em>
      <md-numeric-field [value]=""></md-numeric-field>
    </div>

    <div>
      <h3>Locale | Custom locale </h3>
      <span>Locale <em>IT</em>: comma as decimal separator, dot as thousand separator</span>
      <md-numeric-field [value]="123456.78" [locale]="'it'"></md-numeric-field>
    </div>

    <div>
      <h3>Locale | Browser locale with localeOptions </h3>
      <span><em>useGrouping: false</em></span>
      <md-numeric-field [value]="123456.78" [localeOptions]="{useGrouping: false}"></md-numeric-field>
    </div>

    <div>
      <h3>Locale | Custom locale and localeOptions</h3>
      <span>Locale <em>IT</em>, <em>useGrouping: false</em></span>
      <md-numeric-field [value]="123456.78" [locale]="'it'" [localeOptions]="{useGrouping: false}"></md-numeric-field>
    </div>
  </div>

  <h3>Validation</h3>
  <md-numeric-field formControlName="value1" placeholder="Value1" ngDefaultControl style="width: 250px" [invalid]="
              form.controls.value1.errors?.max
                ? 'Verdi kan ikke vare > 100'
                : null
            " (blur)="onBlur()"></md-numeric-field>

  <h3>Readonly</h3>
  <md-numeric-field value="Read-only" style="width: 250px" readOnly required></md-numeric-field>

  <h3>Border | false</h3>
  <md-numeric-field formControlName="value2" ngDefaultControl [noBorder]="true" placeholder="noBorder = true">
  </md-numeric-field>

  {{ form.value | json }}

  <h3>Invalid</h3>
  <md-numeric-field formControlName="value1" placeholder="Value1" ngDefaultControl invalid="invalid" (blur)="onBlur()">
  </md-numeric-field>
</div>

<div class="flex direction-column p-3">
  <p>Outside Angular formGroup</p>

  <md-numeric-field ngDefaultControl placeholder="" [ngModel]="value3" (ngModelChange)="valueChanged($event)"
    (blur)="onBlur()"></md-numeric-field>
  {{ value3 | json }}

  <md-numeric-field ngDefaultControl placeholder="noBorder = true" [noBorder]="true"></md-numeric-field>
</div>
