import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Routes } from '../app-routing.module';

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss']
})
export class PageNotFoundComponent {

  constructor(
    private router: Router,
  ) { }

  gotoHome() {
    this.router.navigateByUrl(Routes.home);
  }

}
