import { ElementPart, noChange } from 'lit';
import { Directive, directive, DirectiveParameters, PartInfo, PartType } from 'lit/directive.js';
import { BaseIconInterface } from '../components/icons/base-icon.mixin';

/*
* Directive to apply the right classes to the svg element
*/
class IconClassDirective extends Directive {

  constructor(partInfo: PartInfo) {
    super(partInfo);
    if (
      partInfo.type !== PartType.ELEMENT ||
      (partInfo as any)?.element?.tagName !== 'svg'
    ) {
      throw new Error(`The directive ${IconClassDirective.name} must be used in a svg element.`);
    }
  }

  update(svgElement: ElementPart, [params, additionalClasses]: DirectiveParameters<this>) {
    if (additionalClasses === undefined) {
      additionalClasses = ['color'];
    }
    additionalClasses ??= [];
    additionalClasses.forEach(c => {
      svgElement.element.classList.add(c);
    });

    if (params.inactive) {
      svgElement.element.classList.add('disabled');
    }

    // we have manually manipulated the DOM, so no built-in render is needed
    return noChange;
  }

  render(params: BaseIconInterface, additionalClasses: string[] = ['color']) {
    return noChange;
  }
}

export const iconClass = directive(IconClassDirective);
