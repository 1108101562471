
import { LitElement, svg, TemplateResult } from 'lit';
import { customElement } from 'lit/decorators.js';
import { BaseMdElement } from '../base-lit-element';
import { iconClass } from '../../directives/icon-class.directive';
import { BaseIcon } from './base-icon.mixin';

// keep in synk global.d.ts
@customElement('md-icon-chevron')
export class IconChevron extends BaseIcon(BaseMdElement(LitElement)) {

  constructor() {
    super();

    this.smallSvgIcon = svg`
      <svg ${iconClass(this)} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
        <polygon points="5.75 2.92 12.83 10 5.75 17.08 6.46 17.79 14.25 10 6.46 2.21 5.75 2.92"/>
      </svg>`;

    this.largeSvgIcon = svg`
      <svg ${iconClass(this)} xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64">
        <polygon points="20.46 56.49 19.05 55.08 42.12 32 19.05 8.92 20.46 7.51 44.95 32 20.46 56.49"/>
      </svg>`;
  }

  render(): TemplateResult {
    return this.renderIcon();
  }
}
