
import '../icons/icon-close-no-border'; import { html, PropertyValues, TemplateResult } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { unsafeHTML } from 'lit/directives/unsafe-html.js';
import { BaseLitElement } from '../base-lit-element';
import { ILitMandatoryFields } from '../../helpers/lit-mandatory-fields';
import { nameofFactory } from '../../helpers/nameof';
import { PropertyRequiredError } from '../../helpers/property-required-error';
import styles from './alert-message-style.scss';
import '../icons/icon-checkmark';
import '../icons/icon-info';
import '../icons/icon-warning';
import { LitCloseEvent } from '../../helpers/events';

const nameof = nameofFactory<AlertMessage>();

export const alertType = [
  'success',
  'error',
  'info',
  'warning',
] as const;

export type AlertType = typeof alertType[number];

// keep in synk global.d.ts
@customElement('md-alert-message')
export class AlertMessage extends BaseLitElement implements ILitMandatoryFields {

  public static styles = styles;

  @property({ type: String, attribute: true, reflect: true }) value: string | undefined;

  update(changedProperties: PropertyValues): void {
    this.checkProperties();

    super.update(changedProperties);
  }

  checkProperties(): void {
    if (!this.value) {
      throw new PropertyRequiredError(this, nameof('value'), this.value);
    }
  }

  _onClose = (ev: Event) => {
    ev.preventDefault();
    ev.stopPropagation();

    this.dispatchEvent(new LitCloseEvent());
  };

  render(): TemplateResult {
    return html`
      <md-icon-info id="infoIcon" class="icon size-m"></md-icon-info>
      <md-icon-warning id="errorIcon" class="icon white size-m"></md-icon-warning>
      <md-icon-checkmark id="checkmarkIcon" class="icon size-m"></md-icon-checkmark>
      <md-icon-warning id="warningIcon" class="icon size-m"></md-icon-warning>
      <div id="textContainer">
        ${unsafeHTML(this.value)}
      </div>
      <md-icon-close-no-border @click="${this._onClose}"
        @keydown="${(e: KeyboardEvent) => e.key === 'Enter' && this._onClose(e)}" class="size-s"></md-icon-close-no-border>
    `;
  }
}
