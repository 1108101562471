import { Component } from '@angular/core';

@Component({
  selector: 'app-images',
  templateUrl: './images.component.html',
  styleUrls: ['./images.component.scss']
})
export class ImagesComponent {

  // TODO: get all images from the folder
  paths = [
    'assets/images/error_404.svg',

    'assets/images/picture_1.svg',
    'assets/images/picture_2.svg',
    'assets/images/picture_3.svg',
    'assets/images/picture_4.svg',
    'assets/images/picture_5.svg',
    'assets/images/picture_6.svg',
    'assets/images/picture_7.svg',
    'assets/images/picture_8.svg',
    'assets/images/picture_9.svg',
    'assets/images/picture_10.svg',
    'assets/images/picture_11.svg',
    'assets/images/picture_12.svg',
    'assets/images/picture_13.svg',
    'assets/images/picture_14.svg',
    'assets/images/picture_15.svg',
    'assets/images/picture_16.svg',
    'assets/images/picture_17.svg',

    'assets/images/symbol_landbasert-industri.svg',
    'assets/images/symbol_offshore.svg',
  ];

}
