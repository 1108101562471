/** List of available icons. UX will provide icons that are not listed here. */
export const allAvailableIcons = [
  'md-icon-arrow-link',
  'md-icon-avatar',
  'md-icon-cal',
  'md-icon-cal-day',
  'md-icon-cancel',
  'md-icon-checkmark',
  'md-icon-checkmark-success',
  'md-icon-checkmark-success-circle',
  'md-icon-chevron',
  'md-icon-close',
  'md-icon-close-no-border',
  'md-icon-comment',
  'md-icon-comment-fill',
  'md-icon-doc',
  'md-icon-download',
  'md-icon-dropdown-arrow',
  'md-icon-edit',
  'md-icon-expand',
  'md-icon-graph',
  'md-icon-help',
  'md-icon-help-fill',
  'md-icon-home',
  'md-icon-images',
  'md-icon-info',
  'md-icon-logo',
  'md-icon-minus',
  'md-icon-pan',
  'md-icon-person',
  'md-icon-pin',
  'md-icon-pin-alt',
  'md-icon-plus',
  'md-icon-print',
  'md-icon-redirect',
  'md-icon-search-doc',
  'md-icon-setting',
  'md-icon-sorting',
  'md-icon-sorting-active',
  'md-icon-sorting-none',
  'md-icon-spinner',
  'md-icon-sub-menu',
  'md-icon-time',
  'md-icon-trash-bin',
  'md-icon-upload',
  'md-icon-warning',
  'md-icon-zoom',
  'md-icon-zoom-minus',
  'md-icon-zoom-plus',
] as const;
export type AvailableIcons = typeof allAvailableIcons[number];

export const iconSize = [
  'size-xs',
  'size-s',
  'size-m',
  'size-l',
  'size-xl',
  'size-xxl',
] as const;
export type IconSize = typeof iconSize[number];
