<md-page-title value="md-link-button | Link button"></md-page-title>

<div class="flex direction-column gap-4 p-3">

  <md-link-button [appAutofocus]="true">Has initial focus</md-link-button>

  <md-link-button class="align-baseline" (click)="onClick($event)">
    <span>Delete (align base)</span>
    <md-icon-trash-bin class="size-m primary"></md-icon-trash-bin>
  </md-link-button>

  <md-link-button (click)="onClick($event)">
    <span>Delete (align center)</span>
    <md-icon-trash-bin class="size-m primary"></md-icon-trash-bin>
  </md-link-button>

  <md-link-button disabled (click)="onClick($event)">Disabled</md-link-button>

  <md-link-button disabled (click)="onClick($event)">
    <span>Disabled | Delete (align center)</span>
    <md-icon-trash-bin class="size-m primary"></md-icon-trash-bin>
  </md-link-button>

</div>
