import { LitElement, svg, TemplateResult } from 'lit';
import { customElement } from 'lit/decorators.js';
import { BaseMdElement } from '../base-lit-element';
import { iconClass } from '../../directives/icon-class.directive';
import { BaseIcon } from './base-icon.mixin';

// keep in synk global.d.ts
@customElement('md-icon-spinner')
export class IconSpinner extends BaseIcon(BaseMdElement(LitElement)) {

  constructor() {
    super();

    this.smallSvgIcon = svg`
      <svg ${iconClass(this, [])} xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 93.5 94.5">
        <defs>
            <style>.a{fill:#00615c;}.b{fill:#d2e5e5;}</style>
        </defs>
        <g transform="translate(-3 -3)">
            <circle class="a" cx="9.75" cy="9.75" r="9.75" transform="translate(40 3)"/>
            <ellipse class="b" cx="10.292" cy="9.75" rx="10.292" ry="9.75" transform="translate(13 14)"/>
            <circle class="b" cx="9.75" cy="9.75" r="9.75" transform="translate(3 40)"/>
            <circle class="b" cx="9.75" cy="9.75" r="9.75" transform="translate(67 14)"/>
            <circle class="b" cx="9.75" cy="9.75" r="9.75" transform="translate(77 40)"/>
            <circle class="b" cx="9.75" cy="9.75" r="9.75" transform="translate(66 67)"/>
            <circle class="b" cx="9.75" cy="9.75" r="9.75" transform="translate(14 67)"/>
            <circle class="b" cx="9.75" cy="9.75" r="9.75" transform="translate(40 78)"/>
        </g>
      </svg>`;

    this.largeSvgIcon = this.smallSvgIcon;
  }

  render(): TemplateResult {
    return this.renderIcon();
  }
}
