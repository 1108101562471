<md-page-title value="md-checkbox | Checkbox"></md-page-title>

<div [formGroup]="form" class="flex direction-column gap-4 p-3">

  <md-checkbox>Default | unbounded</md-checkbox>

  <md-checkbox formControlName="checkInitialTrue" ngDefaultControl>Initial value | true</md-checkbox>
  <md-checkbox formControlName="checkInitialFalse" ngDefaultControl>Initial value | false</md-checkbox>

  <md-checkbox formControlName="checkDisabledTrue" ngDefaultControl>Disabled | true</md-checkbox>
  <md-checkbox formControlName="checkDisabledFalse" ngDefaultControl>Disabled | false</md-checkbox>

  <md-checkbox formControlName="checkString" ngDefaultControl>TODO: Other value than boolean should give error
  </md-checkbox>

  <md-checkbox formControlName="checkNull" ngDefaultControl>Null/Empty init value</md-checkbox>

  {{ form.value | json }}
</div>
