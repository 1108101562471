import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject } from 'rxjs';
import { debounceTime, delay, map, tap } from 'rxjs/operators';

import { SelectItemsProp } from 'miljodir-wc/dist/src/index';

const initItems: SelectItemsProp[] = [
  { key: '', text: 'All' },
  { key: '1', text: 'Peas' },
  { key: '2', text: 'Carrots' },
  { key: '3', text: 'Tomatoes' },
  { key: '4', text: 'Brussel sprouts' },
  { key: '5', text: 'Cabbages' },
  { key: '6', text: 'Cauliflower' },
  { key: '7', text: 'Cucumber' },
  { key: '8', text: 'Chili' },
  { key: '9', text: 'Garlic' },
  { key: '10', text: 'A pretty long and big Eggplant' },
  { key: '11', text: 'Leeks' },
  { key: '12', text: 'Onions' }
];

type SelectTagItem = SelectItemsProp<{ type?: 'type1' | 'type2' }>;
const itemsWithTag: SelectTagItem[] = [
  { key: '', text: 'None', tag: { type: undefined } },
  { key: '1', text: 'Type 1', tag: { type: 'type1' } },
  { key: '2', text: 'Type 2', tag: { type: 'type2' } },
];

@UntilDestroy()
@Component({
  selector: 'app-select-multiple',
  templateUrl: './select-multiple.component.html',
  styleUrls: ['./select-multiple.component.scss']
})
export class SelectMultipleComponent implements OnInit {
  itemsWithTag = itemsWithTag;
  selectedTagTypes: string[] = [];

  form = new FormGroup({
    noSearch: new FormControl(['2']),
    componentSearch: new FormControl([]),
    searchOnStaticData: new FormControl(['2']),
    searchCustom: new FormControl([]),
    singleLine: new FormControl([])
  });

  ngOnInit() {
    /** Example of custom search with debounce and api-simulation **/

    // Search on search input behavior next
    this.statusSearchCustomInput.asObservable().pipe(
      untilDestroyed(this),
      // Debounce of search, it is decided and handled by the application
      debounceTime(300),
      tap(_ => this.statusSearchCustom = 'Loading'),
      // Used as an ilustration of an API-call
      delay(300),
      map(text => this.filterData(text)),
    )
      .subscribe(x => {
        this.searchCustom = x;
        this.statusSearchCustom = 'Idle';
      });
  }

  // Example 1. Single line (container doesn't expand view)  
  itemsSingleLine = initItems;

  // Example 2. No search, no status attribute is needed 
  itemsNoSearch = initItems;

  // Example 3. Simple search in component, no status attribute is needed 
  itemsComponentSearch = initItems;

  // Example 4. Search on static data, no status attribute is needed 
  searchOnStaticData = initItems;
  searchStaticData(ev: Event) {
    const e = ev as CustomEvent;
    this.searchOnStaticData = this.filterData(e.detail.value);
  }

  // Example 5. Search on application data, f.eks api, status is needed 
  searchCustom: SelectItemsProp[] = []; // empty since fetched from api (simulation);
  statusSearchCustom = 'Loading';
  statusSearchCustomInput = new BehaviorSubject('');

  searchApiCall(ev: Event) {
    const e = ev as CustomEvent;
    this.statusSearchCustomInput.next(e.detail.value);
  }

  // Example 6. Item with extra info via tag
  onTagItemChanged($event: Event) {
    const values = ($event as CustomEvent).detail?.value as SelectTagItem[];
    this.selectedTagTypes = values.map(value => `key ${value.key} => ${value.tag?.type}`);
    console.log('Selected item changed', { values });
  }

  /** Help class to filter init items */
  filterData(text: string) {
    return initItems.filter(x => x.text.trim().toLowerCase().includes(text.trim().toLowerCase()));
  }

}
