<md-page-title value="md-checkbox-group | Checkbox group"></md-page-title>

<div class="flex direction-column gap-4 p-3">

  <h3>Default</h3>
  <div [formGroup]="form">
    <md-checkbox-group formControlName="checkList" [items]="items" ngDefaultControl></md-checkbox-group>
  </div>

  <h3>Horizontal</h3>
  <div [formGroup]="form2">
    <md-checkbox-group formControlName="checkList" class="horizontal" [items]="items2" ngDefaultControl>
    </md-checkbox-group>
  </div>

  {{ form.value | json }}
  {{ form2.value | json }}

  <h3>Invalid</h3>
  <div [formGroup]="form">
    <md-checkbox-group formControlName="checkList" [items]="items" ngDefaultControl invalid="invalid">
    </md-checkbox-group>
  </div>
</div>
