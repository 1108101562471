import { Component } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { CheckBoxItemsProp } from 'miljodir-wc/dist/src/index';

const initTestCheckboxData: CheckBoxItemsProp[] = [
  { key: '1', text: 'Peas' },
  { key: '2', text: 'Carrots' },
  { key: '3', text: 'Tomatoes' },
  { key: '4', text: 'Brussel sprouts' },
];

const initTestCheckboxData2: CheckBoxItemsProp[] = [
  { key: '1', text: 'Peas' },
  { key: '2', text: 'Carrots' },
  { key: '3', text: 'Tomatoes' },
  { key: '4', text: 'Brussel sprouts' },
];

@Component({
  selector: 'app-checkbox-group',
  templateUrl: './checkbox-group.component.html',
  styleUrls: ['./checkbox-group.component.scss']
})
export class CheckboxGroupComponent {

  form = new FormGroup({
    checkList: new FormControl([]),
  });

  form2 = new FormGroup({
    checkList: new FormControl(['3']),
  });

  items = initTestCheckboxData;
  items2 = initTestCheckboxData2;

}
