
import { LitElement, svg, TemplateResult } from 'lit';
import { customElement } from 'lit/decorators.js';
import { BaseMdElement } from '../base-lit-element';
import { iconClass } from '../../directives/icon-class.directive';
import { BaseIcon } from './base-icon.mixin';

// keep in synk global.d.ts
@customElement('md-icon-checkmark')
export class IconCheckmark extends BaseIcon(BaseMdElement(LitElement)) {

  constructor() {
    super();

    this.smallSvgIcon = svg`
      <svg ${iconClass(this)} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
        <polygon points="16.67 3.46 7.39 14.9 3.36 9.45 2.56 10.05 7.36 16.54 17.45 4.09 16.67 3.46"/>
      </svg>`;

    this.largeSvgIcon = svg`
      <svg ${iconClass(this)} xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64">
        <polygon points="23.52 52.99 8.61 32.86 10.22 31.67 23.59 49.73 53.76 12.53 55.31 13.79 23.52 52.99"/>
      </svg>`;
  }

  render(): TemplateResult {
    return this.renderIcon();
  }
}
