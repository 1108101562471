
import { LitElement, svg, TemplateResult } from 'lit';
import { customElement } from 'lit/decorators.js';
import { BaseMdElement } from '../base-lit-element';
import { iconClass } from '../../directives/icon-class.directive';
import { BaseIcon } from './base-icon.mixin';

// keep in synk global.d.ts
@customElement('md-icon-checkmark-success-circle')
export class IconCheckmarkSuccessCircle extends BaseIcon(BaseMdElement(LitElement)) {

  constructor() {
    super();

    this.smallSvgIcon = svg`
      <svg ${iconClass(this)} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
        <g>
          <polygon points="8.69 11.94 6.85 9.45 6.04 10.05 8.66 13.57 13.94 7.05 13.17 6.42 8.69 11.94"/>
          <path d="M10,2.16A7.84,7.84,0,1,0,17.84,10,7.85,7.85,0,0,0,10,2.16Zm0,14.68A6.84,6.84,0,1,1,16.84,10,6.85,6.85,0,0,1,10,16.84Z"/>
        </g>
      </svg>`;

    this.largeSvgIcon = svg`
      <svg ${iconClass(this)} xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64">
        <g>
          <polygon points="27.72 45.49 19.82 34.83 21.43 33.64 27.79 42.23 42.6 23.97 44.15 25.23 27.72 45.49"/>
          <path d="M32,56.47A24.47,24.47,0,1,1,56.47,32,24.49,24.49,0,0,1,32,56.47ZM32,9.53A22.47,22.47,0,1,0,54.47,32,22.5,22.5,0,0,0,32,9.53Z"/>
        </g>
      </svg>`;
  }

  render(): TemplateResult {
    return this.renderIcon();
  }
}
