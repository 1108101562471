import { html, PropertyValues, TemplateResult } from 'lit';
import { customElement, property, query } from 'lit/decorators.js';
import { BaseLitElement } from '../base-lit-element';
import { createPopper, Instance, Placement, StrictModifiers } from '@popperjs/core';
import { getAnchor } from '../../helpers/dom-query';
import styles from './tooltip-style.scss';

// keep in synk global.d.ts
@customElement('md-tooltip')
export class Tooltip extends BaseLitElement {
  static styles = [styles];

  _popperInstance: Instance | null = null;

  @query('.dialog', true) _dialog!: HTMLElement;

  @property({ type: String, attribute: true, reflect: true }) anchor = '';
  @property({ type: String, attribute: true, reflect: true }) placement: Placement = 'auto';

  showEvents = ['mouseenter', 'focus'];
  hideEvents = ['mouseleave', 'blur'];

  firstUpdated(_changedProperties: PropertyValues): void {
    super.firstUpdated(_changedProperties);
    const _anchor = getAnchor(this.anchor, this);

    if (_anchor) {
      this._popperInstance = createPopper<StrictModifiers>(_anchor, this._dialog, {
        placement: this.placement,
      });
      this.attachEventListenersToAnchor(_anchor);
    } else {
      throw `Can't find anchor ${this.anchor}`;
    }
  }

  disconnectedCallback(): void {
    const _anchor = getAnchor(this.anchor, this);
    if (_anchor) {
      this.removeEventListenersFromAnchor(_anchor);
    }
    super.disconnectedCallback();
  }

  protected attachEventListenersToAnchor(_anchor: HTMLElement): void {
    this.showEvents.forEach(event => {
      _anchor.addEventListener(event, () => this.show(this._dialog));
    });
    this.hideEvents.forEach(event => {
      _anchor.addEventListener(event, () => this.hide(this._dialog));
    });
  }

  protected removeEventListenersFromAnchor(_anchor: HTMLElement): void {
    this.showEvents.forEach(event => {
      _anchor.removeEventListener(event, () => this.show(this._dialog));
    });
    this.hideEvents.forEach(event => {
      _anchor.removeEventListener(event, () => this.hide(this._dialog));
    });
  }

  show(dialog: HTMLElement): void {
    // Make the tooltip visible
    dialog.setAttribute('data-show', '');
    // Enable the event listeners
    this._popperInstance?.setOptions({
      modifiers: [{ name: 'eventListeners', enabled: true }],
    });

    // Update its position
    this._popperInstance?.update();
  }

  hide(dialog: HTMLElement): void {
    // Hide the tooltip
    dialog.removeAttribute('data-show');

    // Disable the event listeners
    this._popperInstance?.setOptions({
      modifiers: [{ name: 'eventListeners', enabled: false }],
    });
  }

  render(): TemplateResult {
    return html`
    <div id="tooltip" class="dialog" role="tooltip">
      <slot></slot>
      <div id="arrow" data-popper-arrow></div>
    </div>
    `;
  }
}
