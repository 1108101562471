import { Component } from '@angular/core';

@Component({
  selector: 'app-text-field-icon',
  templateUrl: './text-field-icon.component.html',
  styleUrls: ['./text-field-icon.component.scss']
})
export class TextFieldIconComponent {
  searchText = '';
}
