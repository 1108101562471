import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-radio-button-group',
  templateUrl: './radio-button-group.component.html',
  styleUrls: ['./radio-button-group.component.scss']
})
export class RadioButtonGroupComponent {

  form = new FormGroup({
    gender: new FormControl('male', [Validators.required]),
    club: new FormControl('pool', [Validators.required]),
    table1: new FormControl('table1-1', [Validators.required]),
    table2: new FormControl('table2-2', [Validators.required]),
  });

}
