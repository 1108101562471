
import { html, TemplateResult } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { ILitMandatoryFields } from '../../helpers/lit-mandatory-fields';
import { LitClickEvent } from '../../helpers/events';
import { BaseLitElement } from '../base-lit-element';
import { nameofFactory } from '../../helpers/nameof';
import { PropertyRequiredError } from '../../helpers/property-required-error';
import { PropertyInvalidError } from '../../helpers/property-invalid-error';
import { ILitFocusable } from '../focusable';
import styles from './tabs-style.scss';
import '../icons/icon-comment';

export type Tab = {
  title: string;
  active: boolean;
  route: string;
  disabled?: boolean;
}

const nameof = nameofFactory<Tabs>();

// keep in synk global.d.ts
@customElement('md-tabs')
export class Tabs extends BaseLitElement implements ILitMandatoryFields, ILitFocusable {
  static styles = styles;

  @property({ type: Array, attribute: true, reflect: true })
  tabs: Tab[] = [];

  @property({ type: String, attribute: true, reflect: true })
  value: string | null = null;

  update(changedProperties: Map<string | number | symbol, unknown>): void {
    this.checkProperties();

    super.update(changedProperties);
  }

  checkProperties(): void {
    if (!this.tabs?.length) {
      throw new PropertyRequiredError(this, nameof('tabs'), this.tabs);
    }

    if (this.value && !this.tabs.find(x => x.route === this.value)) {
      throw new PropertyInvalidError(this, nameof('value'), this.value);
    }
  }

  focus(options?: FocusOptions): void {
    this.renderRoot?.querySelector('a')?.focus(options);
  }

  selectTab(tab: Tab): void {
    if (tab.disabled) {
      return;
    }

    this.tabs.forEach(x => x.active = false);
    tab.active = true;
    this.value = tab.route;
  }

  onClick(event: Event, tab: Tab): void {
    event.stopPropagation();
    event.preventDefault();
    if (tab.disabled) {
      return;
    }

    this.selectTab(tab);
    this.dispatchEvent(new LitClickEvent());
  }

  onPreventClick(event: Event): void {
    event.stopPropagation();
    event.preventDefault();
  }

  renderTabs(tabs: Tab[]) {
    return tabs.map(tab => html`
      <a role="tab" tabindex=${tab.disabled ? -1 : 0} @click="${(e: Event) => this.onClick(e, tab)}"
        @keydown="${(e: KeyboardEvent) => e.key === 'Enter' && this.onClick(e, tab)}" ?active="${tab.active && !tab.disabled}"
        ?disabled="${tab.disabled}">
        ${tab.title}
      </a>
   `);
  }

  render(): TemplateResult {
    return html`
      <ul role="tablist" class="tab" tabindex="-1" @click="${(e: Event) => this.onPreventClick(e)}">
        ${this.renderTabs(this.tabs)}
      </ul>
    `;
  }
}
