
import { LitElement, svg, TemplateResult } from 'lit';
import { customElement } from 'lit/decorators.js';
import { BaseMdElement } from '../base-lit-element';
import { iconClass } from '../../directives/icon-class.directive';
import { BaseIcon } from './base-icon.mixin';

// keep in synk global.d.ts
@customElement('md-icon-upload')
export class IconUpload extends BaseIcon(BaseMdElement(LitElement)) {

  constructor() {
    super();

    this.smallSvgIcon = svg`
      <svg ${iconClass(this)} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
        <g>
          <polygon points="9.5 5.23 9.5 15.24 10.5 15.24 10.5 5.23 14.86 9.59 15.57 8.88 10 3.32 4.43 8.88 5.14 9.59 9.5 5.23"/>
          <rect x="2.29" y="15.68" width="15.43" height="1"/>
        </g>
      </svg>`;

    this.largeSvgIcon = svg`
      <svg ${iconClass(this)} xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64">
        <g>
          <polygon points="31 13.23 31 46.69 33 46.69 33 13.23 47.98 28.2 49.39 26.79 32 9.4 14.61 26.79 16.02 28.2 31 13.23"/>
          <rect x="7.31" y="52.56" width="49.37" height="2"/>
        </g>
      </svg>`;
  }

  render(): TemplateResult {
    return this.renderIcon();
  }
}
