<md-page-title value="md-modal | Modal"></md-page-title>

<div class="flex direction-column gap-4 p-3">

  <md-button id="default" (click)="onOpen('default')">Open modal</md-button>
  <md-button id="default-noautofocus" (click)="onOpen('default-noautofocus')">Open modal - no autofocus</md-button>
  <md-button id="secondary" class="secondary" (click)="onOpen('secondary')">Open secondary modal</md-button>
  <md-button id="error" class="secondary" (click)="onOpen('error')">Open error modal</md-button>
  <md-button id="uncloseable" (click)="onOpen('uncloseable')">Open modal where choice must be taken (no close button)
  </md-button>

  <h3>Available sizes</h3>
  <div class="flex">
    <md-button *ngFor="let size of modalSizes" (click)="onOpenSize(size)">{{size}}</md-button>
  </div>
</div>

<md-modal class="size-m" (close)="onClosed('default')" [open]="default">
  <md-modal-title>Title</md-modal-title>
  <md-modal-content>
    <md-paragraph>
      Description of the error, what went wrong and what choices do I have - what happens next?
    </md-paragraph>
    <md-form-field for="testSelect" label="Example 3. No search, no status attribute is needed ">
      <md-select-single id="testSelect" [items]="selectData" [ngModel]="selectedData" placeholder="Velg grønnsak"
        ngDefaultControl>
      </md-select-single>
    </md-form-field>
  </md-modal-content>
  <md-modal-buttons>
    <md-button>Confirm</md-button>
    <md-button (click)="onClosed('default')" class="secondary">Cancel</md-button>
  </md-modal-buttons>
</md-modal>

<md-modal class="size-m" (close)="onClosed('default-noautofocus')" [open]="defaultNoAutofocus" [noAutoFocus]="true">
  <md-modal-title>Title - no autofocus</md-modal-title>
  <md-modal-content>
    <md-paragraph> Text </md-paragraph>
  </md-modal-content>
  <md-modal-buttons>
    <md-button (click)="onClosed('default-noautofocus')" class="secondary">Cancel</md-button>
  </md-modal-buttons>
</md-modal>

<md-modal class="size-m secondary" (close)="onClosed('secondary')" [open]="secondary">
  <md-modal-title>Secondary</md-modal-title>
  <md-modal-content>
    <md-paragraph>
      Description of the error, what went wrong and what choices do I have - what happens next?
    </md-paragraph>
    <md-form-field for="testSelect" label="Example 3. No search, no status attribute is needed ">
      <md-select-single id="testSelect" [items]="selectData" [ngModel]="selectedData" placeholder="Velg grønnsak"
        ngDefaultControl>
      </md-select-single>
    </md-form-field>
  </md-modal-content>
  <md-modal-buttons>
    <md-button>Confirm</md-button>
    <md-button (click)="onClosed('default')" class="secondary">Cancel</md-button>
  </md-modal-buttons>
</md-modal>

<md-modal class="size-m" (close)="onClosed('error')" [open]="error" error="true">
  <md-modal-title>Title</md-modal-title>
  <md-modal-content>
    <md-paragraph>
      Description of the error, what went wrong and what choices do I have - what happens next?
    </md-paragraph>
  </md-modal-content>
  <md-modal-buttons>
    <md-button>Confirm</md-button>
    <md-button (click)="onClosed('error')" class="secondary">Cancel</md-button>
  </md-modal-buttons>
</md-modal>

<md-modal class="size-m" (close)="onClosed('uncloseable')" [open]="uncloseable" [hideCloseBtn]="true">
  <md-modal-title>Title</md-modal-title>
  <md-modal-content>
    <md-paragraph>
      Check this checkbox to confirm and close!
    </md-paragraph>
    <md-checkbox [(ngModel)]="confirm" ngDefaultControl>Check it!</md-checkbox>
  </md-modal-content>
  <md-modal-buttons>
    <md-button [disabled]="!confirm" (click)="onClosed('uncloseable')">Confirm</md-button>
  </md-modal-buttons>
</md-modal>

<md-modal *ngFor="let size of modalSizes" class="{{size}}" (close)="onClosedSize(size)" [open]="modalSizeShow[size]">
  <md-modal-title>{{size}}</md-modal-title>
  <md-modal-buttons>
    <md-button (click)="onClosedSize(size)">Confirm</md-button>
  </md-modal-buttons>
</md-modal>
