<md-page-title value="md-button | Button"></md-page-title>

<div class="flex direction-column gap-4 p-4">

  <md-button [appAutofocus]="true">Has initial focus</md-button>

  <md-button (click)="handleClick($event)">Button | default</md-button>

  <md-button (click)="handleClick($event)" class="primary">Button | primary</md-button>

  <md-button (click)="handleClick($event)">
    <md-icon-plus class="size-xs white"></md-icon-plus> with white icon
  </md-button>

  <md-button (click)="handleClick($event)" disabled>Button | disabled</md-button>

  <md-button (click)="handleClick($event)" disabled>
    <md-icon-plus class="size-xs white"></md-icon-plus> Button | disabled with white icon
  </md-button>

  <button (click)="handleClick($event)">Button | disabled | native button for reference</button>

  <md-button (click)="handleClick($event)" class="secondary">Button | secondary</md-button>

  <md-button (click)="handleClick($event)" class="secondary fit-container">Button | secondary | fit-container
  </md-button>

  <md-button (click)="handleClick($event)" class="secondary" disabled>Button | secondary | disabled</md-button>

  <md-button (click)="handleClick($event)" class="add-field">
    <md-icon-plus class="size-xs"></md-icon-plus> Button | add-field
  </md-button>

  <md-button (click)="handleClick($event)" class="add-field fit-container">
    <md-icon-plus class="size-xs"></md-icon-plus> Button | add-field | fit-container
  </md-button>
</div>
