<md-page-title value="md-text-area | Textarea"></md-page-title>

<div class="flex direction-column gap-4 p-3" [formGroup]="form">

  <h3>Input with validator</h3>
  <md-text-area formControlName="description" ngDefaultControl placeholder="Try it out" rows="10" style="width: 350px"
    [invalid]="
              form.controls.description.errors?.forbiddenName
                ? 'Navn kan ikke vare bob'
                : null
            "></md-text-area>

  <h3>Invalid</h3>
  <md-text-area placeholder="Try it out" style="width: 350px" invalid="invalid">
  </md-text-area>

  <h3>Read only</h3>
  <md-text-area formControlName="readonly" ngDefaultControl placeholder="Try it out" style="width: 350px" readOnly>
  </md-text-area>
  {{ form.value | json }}

</div>
