import { html, TemplateResult } from 'lit';
import { customElement, property, query } from 'lit/decorators.js';
import { LitChangeEvent, LitInputEvent } from '../../helpers/events';
import { BaseLitElement } from '../base-lit-element';
import { IntlNumberFormatter } from '../../helpers/number-formatter.helper';
import { live } from 'lit/directives/live.js';
import { ILitFocusable } from '../focusable';

/* Here we are re-using the same scss just because the component is <really identical>.
 * Make it's own scss if the component should differ, do not try to add some magic to the base scss.
*/
import styles from '../text-field/text-field-style.scss';

/** 
 * A input `type=text` element, but with value strongly-typed to `number`. 
 * It formats the value using browser-locale settings.
 * What the user see is not the real value, but a locale-formatted version.
 */
// keep in synk global.d.ts
@customElement('md-numeric-field')
export class NumericField extends BaseLitElement implements ILitFocusable {
  static styles = [styles];

  private _formatter = new IntlNumberFormatter();
  private _value?: number;
  private _string = '';
  private _formattedValue = '';
  private _locale?: string = undefined;
  private _localeOptions?: Intl.NumberFormatOptions | undefined = undefined;

  @property({ type: String, attribute: true, reflect: true }) invalid?: string;
  @property({ type: String, attribute: true, reflect: true }) placeholder = '';
  @property({ type: Boolean, attribute: true, reflect: true }) readOnly = false;
  @property({ type: Boolean, attribute: true, reflect: true }) noBorder = false;

  /** The numeric rapresentation of the value (but still a `string`).
   * If it's a valid number, a javascript number is used (ex. "123456.78"), 
   * otherwise it's used the "raw" string (ex. "123456.78x").
   * It can differ from what the user see (it uses the browser-locale to format a valid number).
   * We don't dispatch a change/input event if only the displayed version is changed after a blur. 
   * The change/input event is dispatched only when the internal value has changed.
   */
  @property({ type: String, attribute: true, reflect: true })
  get value(): string {
    return this._string;
  }

  set value(text: string | null) {
    text = text === null || text === undefined ? '' : text;
    text = typeof text !== 'string' ? text + '' : text;
    this.normalizeValue(text);
    this._applyFormatter();
  }

  /** Indicate if it's not a valid number (same as javascript `NaN`) */
  @property({ type: Boolean, attribute: true, reflect: true }) NaN = false;

  /** Set the `BCP 47` locale used to format the number (ex. `no-NB`, `en-GB`, etc).
   * If not set, the browser locale (`Intl.NumberFormat().resolvedOptions().locale`) is used. 
   */
  @property({ type: String, attribute: true, reflect: true })
  get locale(): string | undefined {
    return this._locale;
  }

  set locale(value: string | undefined) {
    this._updateLocale(value);
    this._applyFormatter();
  }

  /** Set the locale options. If not set, the locale default settings are used. */
  @property({ type: Object, attribute: true, reflect: true })
  get localeOptions(): Intl.NumberFormatOptions | undefined {
    return this._localeOptions;
  }

  set localeOptions(value: Intl.NumberFormatOptions | undefined) {
    this._updateLocaleOptions(value);
    this._applyFormatter();
  }

  @query('input', true) _inputNode!: HTMLInputElement;

  constructor() {
    super();

    this._updateLocale();
    this._updateLocaleOptions();
  }

  private _updateLocale(locale: string | undefined = undefined) {
    this._locale = locale ?? IntlNumberFormatter.browserLocale;
    this._formatter = new IntlNumberFormatter(this._locale, this._localeOptions);
  }

  private _updateLocaleOptions(options: Intl.NumberFormatOptions | undefined = undefined) {
    // 17 is the maximum number of decimals based on Javascript 64-bit floating point specs.
    this._localeOptions = options ?? { maximumFractionDigits: 17 };
    this._formatter = new IntlNumberFormatter(this._locale, this._localeOptions);
  }

  normalizeValue(text: string) {
    this._value = this._formatter.toNumber(text);
    this.NaN = isNaN(this._value) === true;
    this._string = this.NaN ? text : String(this._value);
    this._formattedValue = text;
  }

  focus(options?: FocusOptions): void {
    this._inputNode.focus(options);
  }

  _dispatchChange(): void {
    this.dispatchEvent(new LitInputEvent());
    this.dispatchEvent(new LitChangeEvent());
  }

  _onChange(event: Event): void {
    event.stopPropagation();
    const inputValue = (event.target as HTMLInputElement).value;

    this.normalizeValue(inputValue);

    this._dispatchChange();
    this.requestUpdate();
  }

  _onBlur(): void {
    this._applyFormatter();
  }

  _applyFormatter() {
    if (this._value === null || this._value === undefined) {
      // just cover an undefined state
      this._formattedValue = '';
    } else if (isNaN(this._value)) {
      // if the number is invalid, we want to keep as it is, so the user can fix it
      this._formattedValue = this._string;
    } else {
      // format based on browser-locale setting
      this._formattedValue = this._formatter.toString(this._value);
    }
    this.requestUpdate();
  }

  asStringValue(value?: number) {
    if (value === undefined || value === null || isNaN(value)) {
      return '';
    }
    const str = this._formatter.toString(value);
    return str;
  }

  renderComponent(): TemplateResult {
    const isInvalid = this.invalid || (this.NaN && this._string);

    return this.readOnly
      ? html`<span title="${this._formattedValue}" class="read-only">${this._formattedValue}</span>`
      : html`
        <input aria-label="md-numeric-field" type="text" .value="${live(this._formattedValue)}"
          placeholder="${this.placeholder ?? ''}" @input="${this._onChange}" @blur="${this._onBlur}"
          class="${isInvalid ? 'alert-border' : ''}">
      `;
  }

  render(): TemplateResult {
    return html`
        ${this.renderComponent()}
        ${this.invalid && html`<span class="alert-text">${this.invalid}</span>`}
      `;
  }

}
