<md-page-title value="md-context-menu | Context menu"></md-page-title>

<div class="flex direction-column gap-4 p-3">

  <div class="flex p-2 gap-4 align-center">
    <span>Add context-menu to a html element</span>
    <md-context-menu>
      <md-context-menu-item icon="md-icon-trash-bin" text="Delete" (click)="onDelete($event)"></md-context-menu-item>
      <md-context-menu-item text="Open document"></md-context-menu-item>
    </md-context-menu>
  </div>

  <div class="flex p-2 gap-4 align-center">
    <span>With icons</span>
    <md-context-menu>
      <md-context-menu-item icon="md-icon-trash-bin" text="Delete" (click)="onDelete($event)"></md-context-menu-item>
      <md-context-menu-item icon="md-icon-doc" text="Open document"></md-context-menu-item>
      <md-context-menu-item text="Other"></md-context-menu-item>
    </md-context-menu>
  </div>

  <div class="flex p-2 gap-4 align-center">
    <span>It can contain disabled items</span>
    <md-context-menu>
      <md-context-menu-item icon="md-icon-trash-bin" text="Delete" (click)="onDelete($event)"></md-context-menu-item>
      <md-context-menu-item icon="md-icon-doc" text="Open document"></md-context-menu-item>
      <md-context-menu-item icon="md-icon-doc" text="Open document" disabled></md-context-menu-item>
    </md-context-menu>
  </div>

  <div class="flex p-2 gap-4 align-center">
    <span>Hidden because contains only disabled items</span>
    <div style="border: 1px dashed gray; width:60px; height:60px;">
      <md-context-menu>
        <md-context-menu-item icon="md-icon-trash-bin" text="Delete" (click)="onDelete($event)" disabled>
        </md-context-menu-item>
        <md-context-menu-item icon="md-icon-doc" text="Open document" disabled></md-context-menu-item>
        <md-context-menu-item icon="md-icon-doc" text="Open document" disabled></md-context-menu-item>
      </md-context-menu>
    </div>
  </div>

</div>
