import { LitElement, svg, TemplateResult } from 'lit';
import { customElement } from 'lit/decorators.js';
import { BaseMdElement } from '../base-lit-element';
import { iconClass } from '../../directives/icon-class.directive';
import { BaseIcon } from './base-icon.mixin';

// keep in synk global.d.ts
@customElement('md-icon-avatar')
export class IconAvatar extends BaseIcon(BaseMdElement(LitElement)) {

  constructor() {
    super();

    // src\assets\ikoner\20px\icon_20px_avatar.svg
    this.smallSvgIcon = svg`
      <svg ${iconClass(this)} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
        <path d="M10,2.27A7.73,7.73,0,1,0,17.73,10,7.74,7.74,0,0,0,10,2.27Zm-4.16,13,0-.07a4.06,4.06,0,0,1,4.13-3,4.06,4.06,0,0,1,4.13,3,.43.43,0,0,1,0,.07A6.76,6.76,0,0,1,10,16.73,6.65,6.65,0,0,1,5.84,15.28ZM8.19,8.62A1.81,1.81,0,1,1,10,10.42,1.8,1.8,0,0,1,8.19,8.62ZM15,14.54a5,5,0,0,0-4.13-3.26,2.81,2.81,0,1,0-1.66,0A5.07,5.07,0,0,0,5,14.54a6.73,6.73,0,1,1,9.92,0Z"/>
      </svg>`;

    // src\assets\ikoner\64px\64px_avatar.svg
    this.largeSvgIcon = svg`
      <svg ${iconClass(this)} xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64">
        <g>
          <path d="M32,19.21a8.37,8.37,0,1,0,8.41,8.37A8.39,8.39,0,0,0,32,19.21ZM32,34a6.37,6.37,0,1,1,6.41-6.37A6.4,6.4,0,0,1,32,34Z"/>
          <path d="M32,7.86A24.14,24.14,0,1,0,56.14,32,24.17,24.17,0,0,0,32,7.86ZM17.87,49a1,1,0,0,0,.32-.51A13.78,13.78,0,0,1,32,38.53c11.3,0,13.71,9.58,13.81,10a1,1,0,0,0,.32.51,22.09,22.09,0,0,1-28.26,0Zm29.77-1.38a16.41,16.41,0,0,0-2.87-5.37c-2.1-2.62-6-5.75-12.77-5.75A15.76,15.76,0,0,0,16.36,47.66a22.15,22.15,0,1,1,31.28,0Z"/>
        </g>
      </svg>`;
  }

  render(): TemplateResult {
    return this.renderIcon();
  }
}
