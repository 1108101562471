
import { LitElement, svg, TemplateResult } from 'lit';
import { customElement } from 'lit/decorators.js';
import { BaseMdElement } from '../base-lit-element';
import { iconClass } from '../../directives/icon-class.directive';
import { BaseIcon } from './base-icon.mixin';

// keep in synk global.d.ts
@customElement('md-icon-warning')
export class IconWarning extends BaseIcon(BaseMdElement(LitElement)) {

  constructor() {
    super();

    // src\assets\ikoner\20px\icon_20px_warning.svg
    this.smallSvgIcon = svg`
      <svg ${iconClass(this)} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
        <g>
          <path d="M10.78,3.51a.92.92,0,0,0-.8-.46h0a.92.92,0,0,0-.79.46L2.44,15.6a.9.9,0,0,0,0,.9.89.89,0,0,0,.79.45H16.76a.89.89,0,0,0,.79-.45.92.92,0,0,0,0-.91ZM3.39,16,10,4.14,16.61,16Z"/>
          <path d="M10,12.47a.44.44,0,1,0,.46.44A.45.45,0,0,0,10,12.47Z"/>
          <rect x="9.7" y="8.09" width="0.66" height="3.52"/>
        </g>
      </svg>`;

    this.largeSvgIcon = svg`
      <svg ${iconClass(this)} xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64">
        <g>
          <path d="M53.64,49.69H10.36a2.31,2.31,0,0,1-2-1.16,2.35,2.35,0,0,1,0-2.35L29.9,7.51A2.33,2.33,0,0,1,32,6.31h0A2.33,2.33,0,0,1,34,7.51L55.7,46.17a2.37,2.37,0,0,1,0,2.36A2.31,2.31,0,0,1,53.64,49.69ZM32,8.39h0a.26.26,0,0,0-.23.14L10.13,47.2a.25.25,0,0,0,0,.27.25.25,0,0,0,.23.14H53.64a.25.25,0,0,0,.23-.14.25.25,0,0,0,0-.27L32.18,8.53A.26.26,0,0,0,32,8.39Z"/>
          <path d="M33.57,37.3a1.49,1.49,0,1,1-1.47-1.4A1.44,1.44,0,0,1,33.57,37.3Zm-.44-15.4V33.15H31V21.9Z"/>
        </g>
      </svg>`;
  }

  render(): TemplateResult {
    return this.renderIcon();
  }
}
