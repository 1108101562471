import { Component, ElementRef, ViewChild } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DownloadFile } from 'miljodir-wc/dist/src/components/download-file/download-file';
import { Upload } from 'miljodir-wc/dist/src/components/upload/upload';
import { Subject, timer, combineLatest } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'app-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.scss']
})
export class UploadComponent {

  uploaderText = 'Last opp vedlegg';

  files$ = new Subject<File[]>();
  delete$ = new Subject<string | null>();
  uploading$ = new Subject<boolean>();
  totalBytes = 0;
  uploadedBytes = 0;

  constructor() {
    combineLatest([this.files$, this.delete$])
      .pipe(untilDestroyed(this))
      .subscribe(([prev, del]) => {
        if (!del) {
          return;
        }

        const files = prev.filter(f => f.name !== del);
        if (prev.length !== files.length) {
          this.files$.next(files);
        }
        this.delete$.next(null);
      });
  }

  @ViewChild('upload')
  fileUpload!: ElementRef;

  uploadChanged() {
    const ctrl = this.fileUpload.nativeElement as Upload;
    console.log('File changed to', ctrl.files);

    if (!ctrl.files.length) {
      return;
    }

    this.files$.next(ctrl.files);
    this.uploading$.next(true);
    this.totalBytes = ctrl.files.reduce((prev, curr) => prev + curr.size, 0);
    this.uploadedBytes = 0;

    const source$ = timer(500, 1000);
    source$.pipe(takeUntil(this.uploading$)).subscribe(_ => {
      let bytes = this.uploadedBytes += Math.random() * this.totalBytes;
      bytes = bytes > this.totalBytes ? this.totalBytes : bytes;
      this.uploadedBytes = bytes;
    });
  }

  uploadAborted() {
    console.log('Upload aborted');
    this.uploading$.next(false);
    this.totalBytes = 0;
    this.uploadedBytes = 0;
  }

  downloadClick(e: Event) {
    const ctrl = e.currentTarget as DownloadFile;
    console.log('File downloaded', ctrl.value);
  }

  deleteClick(e: Event) {
    const ctrl = e.currentTarget as DownloadFile;
    console.log('File deleted', ctrl.value);

    this.delete$.next(ctrl.value);
  }
}
