<div id="menu-container">
  <nav class="p-4">
    <div calss="">
      <a [routerLink]="Routes.home" routerLinkActive="active">
        <md-icon-logo></md-icon-logo>
      </a>
    </div>
    <hr>
    <ul class="mb-2">
      <li><a [routerLink]="Routes.docs" routerLinkActive="active">Docs</a></li>
    </ul>
    <hr>
    <ul>
      <li><a [routerLink]="Routes.accordion" routerLinkActive="active">Accordion</a></li>
      <li><a [routerLink]="Routes.alertMessage" routerLinkActive="active">Alert message</a></li>
      <li><a [routerLink]="Routes.button" routerLinkActive="active">Button</a></li>
      <li><a [routerLink]="Routes.checkbox" routerLinkActive="active">Checkbox</a></li>
      <li><a [routerLink]="Routes.checkboxGroup" routerLinkActive="active">Checkbox group</a></li>
      <li><a [routerLink]="Routes.contextMenu" routerLinkActive="active">Context menu</a></li>
      <li><a [routerLink]="Routes.downloadFile" routerLinkActive="active">Download File</a></li>
      <li><a [routerLink]="Routes.footer" routerLinkActive="active">Footer</a></li>
      <li><a [routerLink]="Routes.form" routerLinkActive="active">Form</a></li>
      <li><a [routerLink]="Routes.formField" routerLinkActive="active">Form field</a></li>
      <li><a [routerLink]="Routes.formGroup" routerLinkActive="active">Form group</a></li>
      <li><a [routerLink]="Routes.gridLayout" routerLinkActive="active">Grid Layout</a></li>
      <li><a [routerLink]="Routes.images" routerLinkActive="active">Images</a></li>
      <li><a [routerLink]="Routes.icons" routerLinkActive="active">Icons</a></li>
      <li><a [routerLink]="Routes.infoBox" routerLinkActive="active">Info box</a></li>
      <li><a [routerLink]="Routes.leftMenu" routerLinkActive="active">Left menu</a></li>
      <li><a [routerLink]="Routes.linkButton" routerLinkActive="active">Link button</a></li>
      <li><a [routerLink]="Routes.map" routerLinkActive="active">Map</a></li>
      <li><a [routerLink]="Routes.modal" routerLinkActive="active">Modal</a></li>
      <li><a [routerLink]="Routes.navigationHeader" routerLinkActive="active">Navigation header</a></li>
      <li><a [routerLink]="Routes.numericField" routerLinkActive="active">Numeric field</a></li>
      <li><a [routerLink]="Routes.pageNotFound" routerLinkActive="active">Page not found | 404</a></li>
      <li><a [routerLink]="Routes.pageTitle" routerLinkActive="active">Page Title</a></li>
      <li><a [routerLink]="Routes.paragraph" routerLinkActive="active">Paragraph</a></li>
      <li><a [routerLink]="Routes.radioButtonGroup" routerLinkActive="active">Radio button group</a></li>
      <li><a [routerLink]="Routes.scss" routerLinkActive="active">Scss variables</a></li>
      <li><a [routerLink]="Routes.sectionBar" routerLinkActive="active">Section Bar</a></li>
      <li><a [routerLink]="Routes.selectMultiple" routerLinkActive="active">Select (multiple)</a></li>
      <li><a [routerLink]="Routes.selectSingle" routerLinkActive="active">Select (single)</a></li>
      <li><a [routerLink]="Routes.table" routerLinkActive="active">Table</a></li>
      <li><a [routerLink]="Routes.tabs" routerLinkActive="active">Tabs</a></li>
      <li><a [routerLink]="Routes.template" routerLinkActive="active">Template</a></li>
      <li><a [routerLink]="Routes.textArea" routerLinkActive="active">Text area</a></li>
      <li><a [routerLink]="Routes.textField" routerLinkActive="active">Text field</a></li>
      <li><a [routerLink]="Routes.textFieldIcon" routerLinkActive="active">Text field with icon</a></li>
      <li><a [routerLink]="Routes.tileButton" routerLinkActive="active">Tile button</a></li>
      <li><a [routerLink]="Routes.toggle" routerLinkActive="active">Toggle</a></li>
      <li><a [routerLink]="Routes.tooltip" routerLinkActive="active">Tooltip</a></li>
      <li><a [routerLink]="Routes.upload" routerLinkActive="active">Upload</a></li>
    </ul>
  </nav>
  <div style="display: block; height: fit-content;">
    <router-outlet></router-outlet>
  </div>
</div>
