import { html, TemplateResult } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { LitChangeEvent, LitInputEvent } from '../../helpers/events';
import { ILitMandatoryFields } from '../../helpers/lit-mandatory-fields';
import { nameofFactory } from '../../helpers/nameof';
import { PropertyInvalidError } from '../../helpers/property-invalid-error';
import { PropertyRequiredError } from '../../helpers/property-required-error';
import { BaseLitElement } from '../base-lit-element';
import { Item, SectionBarItem } from './section-bar-item';
import { ILitFocusable } from '../focusable';
import styles from './section-bar-style.scss';
import './section-bar-item';

const nameof = nameofFactory<SectionBar>();

export type SectionBarMessageClickDetail = {
  key: string,
  message: boolean
};

export class LitMessageClickCustomEvent extends CustomEvent<SectionBarMessageClickDetail> {
  public constructor(detail: SectionBarMessageClickDetail) {
    super('messageClick', {
      detail,
      bubbles: true,
      composed: true
    });
  }
}

// keep in synk global.d.ts
@customElement('md-section-bar')
export class SectionBar extends BaseLitElement implements ILitMandatoryFields, ILitFocusable {
  static styles = styles;

  @property({ type: Array, attribute: true, reflect: true })
  items: Array<Item> = [];

  @property({ type: String, attribute: true, reflect: true })
  value: string | null = null;

  @property({ type: Boolean, attribute: true, reflect: true })
  disabled = false;

  update(changedProperties: Map<string | number | symbol, unknown>): void {
    this.checkProperties();

    super.update(changedProperties);
  }

  checkProperties(): void {
    if (!this.items?.length) {
      throw new PropertyRequiredError(this, nameof('items'), this.items);
    }

    if (this.value && !this.items.find(x => x.key === this.value)) {
      throw new PropertyInvalidError(this, nameof('value'), this.value);
    }
  }

  focus(options?: FocusOptions): void {
    const items = Array.from(this.shadowRoot?.querySelectorAll('#container > md-section-bar-item') || []).map(x => x as SectionBarItem);
    if (!items || items.length === 0) {
      return;
    }
    items[0].focus(options);
  }

  _onChange(): void {
    this.dispatchEvent(new LitInputEvent());
  }

  private onItemClick(e: Event, item: Item): void {
    if (this.disabled) {
      return;
    }

    if (item.key === this.value) {
      return;
    }

    this.value = item.key;
    this.dispatchEvent(new LitInputEvent());
    this.dispatchEvent(new LitChangeEvent());
    this.dispatchEvent(new LitMessageClickCustomEvent({ key: item.key, message: false }));
  }

  private onItemMessageClick(e: Event, item: Item): void {
    if (this.disabled) {
      return;
    }

    this.dispatchEvent(new LitMessageClickCustomEvent({ key: item.key, message: true }));
  }

  render(): TemplateResult {
    return html`
      <div id="container">
        ${this.items.map((item, index) => html`
        <md-section-bar-item .item="${item}" ?first="${index === 0}" ?active="${item.key === this.value}"
          ?disabled="${this.disabled}" @click=${(e: Event) => this.onItemClick(e, item)}
          @keydown="${(e: KeyboardEvent) => e.key === 'Enter' && this.onItemClick(e, item)}"
          @messageClick=${(e: Event) => this.onItemMessageClick(e, item)}
          >
        </md-section-bar-item>
        `)}
      </div>
    `;
  }
}
