<md-page-title value="md-radio-button-group | Radio button group"></md-page-title>

<div class="flex direction-column gap-4 p-3" [formGroup]="form">

  <md-form-field for="gender" label="Gender">
    <md-radio-button-group id="gender" name="gender" formControlName="gender" test="group 1" ngDefaultControl>
      <md-radio-button name="gender" value="male">Male</md-radio-button>
      <md-radio-button name="gender" value="female">Female</md-radio-button>
      <md-radio-button name="gender" value="other">Other</md-radio-button>
    </md-radio-button-group>
  </md-form-field>

  <md-form-field for="club" label="Horizontal">
    <md-radio-button-group id="club" name="club" class="row-direction" test="group 2" formControlName="club"
      ngDefaultControl>
      <md-radio-button name="club" value="pool">Liverpool</md-radio-button>
      <md-radio-button name="club" value="united" disabled>Man Utd</md-radio-button>
      <md-radio-button name="club" value="orebro">Örebro SK</md-radio-button>
    </md-radio-button-group>
  </md-form-field>

  <md-form-field for="club" label="Disabled">
    <md-radio-button-group id="ctrl-disabled" name="ctrl-disabled" class="row-direction">
      <md-radio-button name="ctrl-disabled" value="pool" disabled>Value 1</md-radio-button>
      <md-radio-button name="ctrl-disabled" value="united" disabled>Value 2</md-radio-button>
    </md-radio-button-group>
  </md-form-field>

  <md-form-field for="club" label="Invalid">
    <md-radio-button-group id="ctrl-invalid" name="ctrl-invalid" class="row-direction" invalid="invalid">
      <md-radio-button name="ctrl-invalid" value="1">Value 1</md-radio-button>
      <md-radio-button name="ctrl-invalid" value="2">Value 2</md-radio-button>
    </md-radio-button-group>
  </md-form-field>

  <h3>Radio group advanced (shadow-root open)</h3>

  <md-radio-button-group id="table1" name="table1" shadowRootOpen class="row-direction" formControlName="table1"
    ngDefaultControl>
    <md-radio-button-group id="table2" name="table2" shadowRootOpen class="row-direction" formControlName="table2"
      ngDefaultControl>
      <table class="table">
        <thead>
          <tr>
            <th>Col. 1</th>
            <th>Col. 2</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <md-radio-button name="table1" value="table1-1">table1-1</md-radio-button>
            </td>
            <td>
              <md-radio-button name="table2" value="table2-1">table2-1</md-radio-button>
            </td>
          </tr>

          <tr>
            <td>
              <md-radio-button name="table1" value="table1-2">table1-2</md-radio-button>
            </td>
            <td>
              <md-radio-button name="table2" value="table2-2">table2-2</md-radio-button>
            </td>
          </tr>

          <tr>
            <td>
              <md-radio-button name="table1" value="table1-3"></md-radio-button>
            </td>
            <td>
              <md-radio-button name="table2" value="table2-3"></md-radio-button>
            </td>
          </tr>
        </tbody>
      </table>
    </md-radio-button-group>
  </md-radio-button-group>

  <span>
    {{ form.value | json }}
  </span>
</div>
