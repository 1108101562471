
import { LitElement, svg, TemplateResult } from 'lit';
import { customElement } from 'lit/decorators.js';
import { BaseMdElement } from '../base-lit-element';
import { iconClass } from '../../directives/icon-class.directive';
import { BaseIcon } from './base-icon.mixin';

// keep in synk global.d.ts
@customElement('md-icon-cal-day')
export class IconCalDay extends BaseIcon(BaseMdElement(LitElement)) {

  constructor() {
    super();

    // src\assets\ikoner\20px\icon_20px_cal_day.svg
    this.smallSvgIcon = svg`
      <svg ${iconClass(this)} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
        <g>
          <path d="M17.54,4.31H15.42V3.45h-1v.86H5.58V3.45h-1v.86H2.41V16.55H17.59V4.31ZM3.41,15.55V7.33H16.59v8.22Z"/>
          <polygon points="9.01 12.44 7.7 10.67 6.9 11.27 8.98 14.07 13.09 9 12.31 8.37 9.01 12.44"/>
        </g>
      </svg>`;

    // src\assets\ikoner\64px\64px_cal_day.svg
    this.largeSvgIcon = svg`
      <svg ${iconClass(this)} xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64">
        <g>
          <path d="M48.74,14.74V11.38h-2v3.36H17.26V11.38h-2v3.36H8.32v38H55.68v-38Zm-38.42,36V23.2H53.68V50.71Z"/>
          <polygon points="28.81 41.13 24.16 34.85 22.56 36.04 28.74 44.39 41.41 28.76 39.86 27.5 28.81 41.13"/>
        </g>
      </svg>`;
  }

  render(): TemplateResult {
    return this.renderIcon();
  }
}
